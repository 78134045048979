

/* mobile */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
@media all and (max-width:767px) {
    
.arrowTopIcon{ display:inline-block; width:40px; height:38px; background:url('../../components/image/arrow_icon.png')no-repeat; padding:5px; }

.bIntroTitle{ display:block; width:100%; height:280px; text-align:center;  background:url(../../BusinessIntro/image/businessBG22.png)no-repeat; 
              padding:0px 20px;  background-size: 120% 60%; }
.bIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 24px; font-weight: 600; padding-top:56px; padding-bottom: 6px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#ffffff; }
.bIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:12px; color: #F9F9F9; 
                                   height: 28px; line-height: 18px; /* padding: 0px 48px; */ }


/* 기술 알아보기 */
.spatialInforContentsSect{ display:block; /* padding-top: 120px; */  margin:0 auto; margin-bottom:180px; } 
.spatialTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 20px; text-align: center; margin-bottom: 40px; line-height: 44px; }
.spatialTextBoxTop{ display:block; line-height:18px; text-align:center; margin:0 auto; margin-bottom:30px; padding:0px 40px; }
.spatialTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; margin-bottom:20px; }
.spatialTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.spatialTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.businessTextBold{ /* display: inline-block; */  font-family:'SUIT-Medium'; font-weight: 500; font-size:12px; color:#4D8DE8; margin-left:4px; /* margin-right:4px; */ }
.businessTextBoldE{ font-family:'SUIT-Medium'; font-weight: 500; font-size:12px; color:#4D8DE8; margin-left:4px;  margin-right:4px; }

.spatialFlexBox{ display:flex; flex-direction: column; padding:0px 20px; }
.spatialImgBox .spatialInforImg{ display:block; height:200px; background:url(../../BusinessIntro/image/디지털트윈_111.png)no-repeat; background-size: cover;
                                 border-radius:8px; margin-bottom: 22px; }

.spatialTextBox{ display:block;  box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px;  padding-right: 30px; }
.spatialTextBox .spTarget1{ display:block; width: 130px; height:32px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 24px 0px 24px; margin-top:30px; line-height:32px;
                             font-family:'SUIT-Regular'; font-size:12px; color: #4D8DE8; }
.spatialTextBox .spSubFlex1{ display:flex; margin-left: 20px; margin-top: 20px; }
.spSubFlex1 .spCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 70%; }
.spSubFlex1 .spSubTitle1{ display:block; line-height: 18px; width: 280px; }
.spSubFlex1 .spSubTitle1 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333; }  
.spSubFlex1 .spSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.spatialTextBox .spSubFlex2{  display:flex; margin-left: 20px; margin-top: 10px; }
.spSubFlex2 .spCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 70%; }
.spSubFlex2 .spSubTitle2{ display:block; line-height: 18px; width: 280px; }
.spSubFlex2 .spSubTitle2 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333; }
.spSubFlex2 .spSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.spatialTextBox .spSubFlex3{  display:flex; margin-left: 20px; margin-top: 10px; margin-bottom: 30px; }
.spSubFlex3 .spCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 70%; }
.spSubFlex3 .spSubTitle3{ display:block; line-height: 18px; width: 280px; }
.spSubFlex3 .spSubTitle3 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333;  }
.spSubFlex3 .spSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97; }

.spatialBottomText{ display:block;  height:25px; font-family:'SUIT-Light'; font-size:12px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align: center; }


.eSopContentsSect{ display:block;  margin:0 auto; margin-bottom:180px; } 
.eSopTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 20px; text-align: center; margin-bottom: 72px; line-height: 44px; }
.eSopTextBoxTop{ display:block; /* height:136px; */ line-height:18px; text-align:center; margin:0 auto; margin-bottom:30px; padding:0px 40px; }
.eSopTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; margin-bottom:20px; }
.eSopTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.eSopTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }


.eSopFlexBox{ display:flex; flex-direction: column; padding:0px 20px;  }
.eSopImgBox .eSopImg{ display:block; height:200px; background:url(../../BusinessIntro/image/bn_2.png)no-repeat; background-size: cover;
                      border-radius:8px; margin-bottom: 22px;  }

.eSopTextBox{ display:block; /* height:370px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; padding-right: 30px;  }
.eSopTextBox .eSopTarget1{ display:block; width: 130px; height:32px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 24px 0px 24px; margin-top:30px; line-height:32px;
                             font-family:'SUIT-Regular'; font-size:12px; color: #4D8DE8; }
.eSopTextBox .eSopSubFlex1{ display:flex; margin-left: 20px; margin-top: 20px; }
.eSopSubFlex1 .eSopCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 70%; }
.eSopSubFlex1 .eSopSubTitle1{ display:block; line-height: 18px; width: 280px; }
.eSopSubFlex1 .eSopSubTitle1 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333; }  
.eSopSubFlex1 .eSopSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.eSopTextBox .eSopSubFlex2{  display:flex; margin-left: 20px; margin-top: 10px; }
.eSopSubFlex2 .eSopCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 70%; }
.eSopSubFlex2 .eSopSubTitle2{ display:block; line-height: 18px; width: 280px; }
.eSopSubFlex2 .eSopSubTitle2 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333; }
.eSopSubFlex2 .eSopSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.eSopTextBox .eSopSubFlex3{  display:flex; margin-left: 20px; margin-top: 10px; margin-bottom: 30px; }
.eSopSubFlex3 .eSopCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 70%; }
.eSopSubFlex3 .eSopSubTitle3{ display:block; line-height: 18px; width: 280px; }
.eSopSubFlex3 .eSopSubTitle3 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333;  }
.eSopSubFlex3 .eSopSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97; }

.eSopBottomText{ display:block;  height:25px; font-family:'SUIT-Light'; font-size:12px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align:center; }


.dataVisualContentsSect{ display:block;  margin:0 auto; margin-bottom:180px; } 
.dataTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 20px; text-align: center; margin-bottom: 40px; line-height: 44px; }
.dataTextBoxTop{ display:block; line-height:18px; text-align:center; margin:0 auto; margin-bottom:30px; padding:0px 36px; font-size:12px; }
.dataTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; margin-bottom:20px; }
.dataTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.dataTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.dataTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }

.dataFlexBox{ display:flex; flex-direction:column; padding:0px 20px;  }
.dataImgBox .dataImg{ display:block;  height:200px; background:url(../../BusinessIntro/image/bn_333.png)no-repeat; background-size: cover;
                                 border-radius:8px; margin-bottom: 22px; }

.dataTextBox{ display:block; /* height:370px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; padding-right: 30px;  }
.dataTextBox .dataTarget1{ display:block; width: 130px; height:32px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 24px 0px 24px; margin-top:30px; line-height:32px;
                             font-family:'SUIT-Regular'; font-size:12px; color: #4D8DE8;  }
.dataTextBox .dataSubFlex1{ display:flex; margin-left: 20px; margin-top: 20px; }
.dataSubFlex1 .dataCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 70%; }
.dataSubFlex1 .dataSubTitle1{ display:block; line-height: 18px; width: 280px; }
.dataSubFlex1 .dataSubTitle1 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333; }  
.dataSubFlex1 .dataSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.dataTextBox .dataSubFlex2{  display:flex; margin-left: 20px; margin-top: 10px; }
.dataSubFlex2 .dataCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 70%; }
.dataSubFlex2 .dataSubTitle2{ display:block; line-height: 18px; width: 280px; }
.dataSubFlex2 .dataSubTitle2 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333; }
.dataSubFlex2 .dataSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.dataTextBox .dataSubFlex3{  display:flex; margin-left: 20px; margin-top: 10px;  margin-bottom: 30px; }
.dataSubFlex3 .dataCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 70%; }
.dataSubFlex3 .dataSubTitle3{ display:block; line-height: 18px; width: 280px; }
.dataSubFlex3 .dataSubTitle3 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333;  }
.dataSubFlex3 .dataSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97; }

.dataBottomText{ display:block; height:25px; font-family:'SUIT-Light'; font-size:12px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align:center; }


.systemLinkContentsSect{ display:block;  margin:0 auto; /* margin-bottom:180px; */ }
.systemTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 20px; text-align: center; margin-bottom: 40px; line-height: 44px; }
.systemTextBoxTop{ display:block; line-height:18px; text-align:center; margin:0 auto; margin-bottom:30px; padding:0px 40px; font-size: 12px; }
.systemTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; margin-bottom:20px; }
.systemTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.systemTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.systemTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }


.systemFlexBox{ display:flex; justify-content:center; flex-direction:column; padding:0px 20px; }
.systemImgBox .systemImg{ display:block; height:200px; background:url(../../BusinessIntro/image/bn_444.png)no-repeat; background-size: cover;
                                 border-radius:8px; margin-bottom: 22px; }

.systemTextBox{ display:block; /* height:370px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; padding-right: 30px;  }
.systemTextBox .systemTarget1{ display:block; width: 130px; height:32px; background: #F4F8FD; border-top-right-radius:20px;
                               border-bottom-right-radius:20px; padding:0px 24px 0px 24px; margin-top:30px; line-height:32px;
                               font-family:'SUIT-Regular'; font-size:12px; color: #4D8DE8; }
.systemTextBox .systemSubFlex1{ display:flex; margin-left: 20px; margin-top: 20px; }
.systemSubFlex1 .systemCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 70%; }
.systemSubFlex1 .systemSubTitle1{ display:block; line-height: 18px; width: 280px; }
.systemSubFlex1 .systemSubTitle1 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333; }  
.systemSubFlex1 .systemSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.systemTextBox .systemSubFlex2{  display:flex; margin-left: 20px; margin-top: 10px; }
.systemSubFlex2 .systemCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 70%; }
.systemSubFlex2 .systemSubTitle2{ display:block; line-height: 18px; width: 280px; }
.systemSubFlex2 .systemSubTitle2 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333; }
.systemSubFlex2 .systemSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.systemTextBox .systemSubFlex3{  display:flex; margin-left: 20px; margin-top: 10px; margin-bottom: 30px; }
.systemSubFlex3 .systemCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 70%; }
.systemSubFlex3 .systemSubTitle3{ display:block; line-height: 18px; width: 280px; }
.systemSubFlex3 .systemSubTitle3 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333;  }
.systemSubFlex3 .systemSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97; }

.systemBottomText{ display:block;  height:25px; font-family:'SUIT-Light'; font-size:12px; color: #555555; 
                   margin: 0 auto; margin-top: 28px; text-align:center; }




/* 특허 */
.comPatBox{ display:block; width:100%; height:480px; text-align:center; padding:50px; background:url(../../CompanyIntro/image/menu_1_image.png)no-repeat;
            margin-bottom:176px; background-size:cover; }
.comPatConTItle2 { font-family: 'SUIT-SemiBold'; font-size: 20px;  display:block; width: 100%; text-align: center; margin-bottom:88px; padding-top:200px; }
.comPatCircle{ display:block; width: 12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom: 16px; }

.comPatContent{ display:block; margin-bottom:80px; }
.comPatConTItle{ font-family: 'SUIT-Medium'; font-size: 16px; display: block; width: 100%;  text-align: center; margin-bottom: 48px; }

.comPatentBox{ display: block; padding-bottom: 120px; padding: 0px 60px; margin-bottom: 80px; }
.comPatentBox .ParFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParFirst .ParFirstBox1{ display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_24.png)no-repeat; 
                                                    width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText2{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText3{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }

.comPatentBox .ParFirst .ParFirstBox2{ display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_25.png)no-repeat; 
                                                     width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; } 


.comPatentBox .ParSecond{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParSecond .ParSecondBox1{ display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_26.png)no-repeat; 
                                                        width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText1{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText2{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText3{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }


.comPatentBox .ParSecond .ParSecondBox2{ display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_27.png)no-repeat; 
                                                        width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText4 {display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText5{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText6{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }



.comPatentBox .ParThird{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParThird .ParThirdBox1{ display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_28.png)no-repeat; 
                                                        width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText1{display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText2{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText3{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }


.comPatentBox .ParThird .ParThirdBox2{ display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_29.png)no-repeat; 
                                                     width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText4{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText5{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText6{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }



.comPatentBox .ParFourth{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParFourth .ParFourthBox1{ display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_30.png)no-repeat; 
                                                        width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText1{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText2{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText3{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }

.comPatentBox .ParFourth .ParFourthBox2{ display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParFourth .ParFourthBox2 .ParFourthImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_31.png)no-repeat; 
                                                        width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFourth .ParFourthBox2 .ParFourthText4{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox2 .ParFourthText5{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox2 .ParFourthText6{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }



.comPatentBox .ParFifth{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParFifth .ParFifthBox1{ display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comPatentBox .ParFifth .ParFifthBox1 .ParFifthImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_32.png)no-repeat; 
                                                     width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFifth .ParFifthBox1 .ParFifthText1{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParFifth .ParFifthBox1 .ParFifthText2{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParFifth .ParFifthBox1 .ParFifthText3{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }


.comPatentBox .ParFifth .ParFifthBox2{ display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.comPatentBox .ParFifth .ParFifthBox2 .ParFifthImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_33.png)no-repeat; 
                                                    width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFifth .ParFifthBox2 .ParFifthText4{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParFifth .ParFifthBox2 .ParFifthText5{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParFifth .ParFifthBox2 .ParFifthText6{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }



.comPatentBox .ParSixth{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParSixth .ParSixthBox1{ display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comPatentBox .ParSixth .ParSixthBox1 .ParSixthImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_34.png)no-repeat; 
                                                     width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSixth .ParSixthBox1 .ParSixthText1{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParSixth .ParSixthBox1 .ParSixthText2{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParSixth .ParSixthBox1 .ParSixthText3{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }


.comPatentBox .ParSixth .ParSixthBox2{ display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.comPatentBox .ParSixth .ParSixthBox2 .ParSixthImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_35.png)no-repeat; 
                                                     width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSixth .ParSixthBox2 .ParSixthText4{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParSixth .ParSixthBox2 .ParSixthText5{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParSixth .ParSixthBox2 .ParSixthText6{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }


.comPatentBox .ParSeventh{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParSeventh .ParSeventhBox1{ display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comPatentBox .ParSeventh .ParSeventhBox1 .ParSeventhImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_36.png)no-repeat; 
                                                           width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSeventh .ParSeventhBox1 .ParSeventhText1{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParSeventh .ParSeventhBox1 .ParSeventhText2{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParSeventh .ParSeventhBox1 .ParSeventhText3{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }

.comPatentBox .ParSeventh .ParSeventhBox2{ display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParSeventh .ParSeventhBox2 .ParSeventhImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_37.png)no-repeat; 
                                                           width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSeventh .ParSeventhBox2 .ParSeventhText4{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParSeventh .ParSeventhBox2 .ParSeventhText5{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParSeventh .ParSeventhBox2 .ParSeventhText6{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }


.comPatentBox .ParEighth{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParEighth .ParEighthBox1{ display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comPatentBox .ParEighth .ParEighthBox1 .ParEighthImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_38.png)no-repeat; 
                                                       width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; } 
.comPatentBox .ParEighth .ParEighthBox1 .ParEighthText1{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParEighth .ParEighthBox1 .ParEighthText2{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParEighth .ParEighthBox1 .ParEighthText3{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }

.comPatentBox .ParEighth .ParEighthBox2{ display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParEighth .ParEighthBox2 .ParEighthImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_38.png)no-repeat; 
                                                        width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; } 
.comPatentBox .ParEighth .ParEighthBox2 .ParEighthText4{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParEighth .ParEighthBox2 .ParEighthText5{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPatentBox .ParEighth .ParEighthBox2 .ParEighthText6{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }





/* 인증 */
.comCertifiedTItle{  font-family: 'SUIT-Medium'; font-size: 20px; display:block; width: 100%; text-align: center; margin-bottom:68px; color: #333333; }
.comCertifiedBox{ display: block;  padding-bottom: 120px; padding:0px 60px; margin-bottom: 80px; }
.comCertifiedBox .CerFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:24px;}
.comCertifiedBox .CerFirst .CerFirstBox{  display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/GS1.png)no-repeat; background-size: 96%; background-position-x: 5px;
                                                      width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText{  display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666;  }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText2{  display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height:14px; color: #666666;  }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText3{  display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666;  }

.comCertifiedBox .CerFirst .CerFirstBox2{ display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_41.png)no-repeat; 
                                                        width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }


.comCertifiedBox .CerSecond{ display: flex; width: 100%; justify-content:center; }
.comCertifiedBox .CerSecond .CerSecondBox1{ display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comCertifiedBox .CerSecond .CerSecondBox1 .CerSecondImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_42.png)no-repeat; 
                                                            width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox1 .CerSecondText1{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox1 .CerSecondText2{  display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox1 .CerSecondText3{  display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }

.comCertifiedBox .CerSecond .CerSecondBox2{ display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_43.png)no-repeat; 
                                                            width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText4{  display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText5{  display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText6{  display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }


.comCertifiedBox .CerThird{ display: flex; width: 100%; justify-content:center; }
.comCertifiedBox .CerThird .CerThirdBox1{ display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; } 
.comCertifiedBox .CerThird .CerThirdBox1 .CerThirdImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_44.png)no-repeat; 
                                                         width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerThird .CerThirdBox1 .CerThirdText1{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerThird .CerThirdBox1 .CerThirdText2{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerThird .CerThirdBox1 .CerThirdText3{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }


.comCertifiedBox .CerThird .CerThirdBox2{  display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comCertifiedBox .CerThird .CerThirdBox2 .CerThirdImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_45.png)no-repeat; 
                                                         width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerThird .CerThirdBox2 .CerThirdText4{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerThird .CerThirdBox2 .CerThirdText5{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerThird .CerThirdBox2 .CerThirdText6{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }


.comCertifiedBox .CerFourth{ display: flex; width: 100%; justify-content:center; }
.comCertifiedBox .CerFourth .CerFourthBox1{ display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comCertifiedBox .CerFourth .CerFourthBox1 .CerFourthImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_46.png)no-repeat; 
                                                           width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFourth .CerFourthBox1 .CerFourthText1{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerFourth .CerFourthBox1 .CerFourthText2{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerFourth .CerFourthBox1 .CerFourthText3{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }

.comCertifiedBox .CerFourth .CerFourthBox2{  display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comCertifiedBox .CerFourth .CerFourthBox2 .CerFourthImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_47.png)no-repeat; 
                                                           width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFourth .CerFourthBox2 .CerFourthText4{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerFourth .CerFourthBox2 .CerFourthText5{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comCertifiedBox .CerFourth .CerFourthBox2 .CerFourthText6{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }



/* .comCertifiedBox .CerSecond .CerSecondBox4{  display: block; width: 252px; padding: 20px 24px; border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox5{  display: block; width: 252px; padding: 20px 24px; border-radius: 8px; } */



/* 수상 */
.comPrizeTItle{ font-family: 'SUIT-Medium'; font-size: 20px; display:block; width: 100%; text-align: center; margin-bottom:48px; }
.comPrizeBox{ display: block; padding: 0px 60px; }
.comPrizeBox .PrizeFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox{  display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_52_2.png)no-repeat; 
                                                        width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText2{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText3{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666;}


.comPrizeBox .PrizeFirst .PrizeFirstBox2{  display: block;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_48.png)no-repeat; 
                                                         width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }

.comPrizeBox .PrizeFirst2{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3{  display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3 .PrizeFirstImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_49.png)no-repeat; 
                                                           width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3 .PrizeFirstText7{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3 .PrizeFirstText8{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3 .PrizeFirstText9{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666; }


.comPrizeBox .PrizeFirst2 .PrizeFirstBox4{  display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox4 .PrizeFirstImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_50.png)no-repeat; 
                                                           width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst2 .PrizeFirstBox4 .PrizeFirstText10{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666;}
.comPrizeBox .PrizeFirst2 .PrizeFirstBox4 .PrizeFirstText11{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666;}
.comPrizeBox .PrizeFirst2 .PrizeFirstBox4 .PrizeFirstText12{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666;}


.comPrizeBox .PrizeFirst3{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5{  display: block; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 8px; }
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5 .PrizeFirstImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_51.png)no-repeat; 
                                                           width:124px; height:160px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5 .PrizeFirstText13{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666;}
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5 .PrizeFirstText14{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666;}
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5 .PrizeFirstText15{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; color: #666666;}

.comPrizeBox .PrizeFirst3 .PrizeFirstBox6{  display: block; padding: 16px 20px; /* box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); */ border-radius: 8px; }
.comPrizeBox .PrizeFirst3 .PrizeFirstBox6 .PrizeFirstImg6{ display:inline-block; /* background:url(../../BusinessIntro/image/Patent_48.png)no-repeat; */ 
                                                           width:124px; height:160px; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst3 .PrizeFirstBox6 .PrizeFirstText16{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; }
.comPrizeBox .PrizeFirst3 .PrizeFirstBox6 .PrizeFirstText17{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 14px; }




.eIntroTitle{ display:block; width:100%; height:280px; text-align:center;  background:url(../../BusinessIntro/image/businessBG33.png)no-repeat; /* background-size:cover; */
              background-size: 120% 60%; }
.eIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 24px; font-weight: 600; padding-top:56px; padding-bottom:6px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#ffffff; }
.eIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:12px; color: #F9F9F9;
                                   height: 28px; line-height: 28px; }



/* 사업 알아보기 */
.safetyInforContentsSect2{ display:block; width:100%; /* padding-top:120px; */ margin:0 auto; margin-bottom:180px;  } 
.safetyTextBox{ display:block; height:100%; margin:0 auto; }
.safetyManageTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 20px; text-align: center; margin-bottom: 50px; }

.safetyManageContents{ display:block; }
.safetyMText1{ display:block; text-align:center; font-size: 12px; line-height: 18px; }
.safetyMText1E{ display:block; text-align:center; font-size: 12px; line-height: 18px; padding: 0px 40px; }
.safetyTextConts{ display:block; line-height: 18px; }

.safetyTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.safetyTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; margin-bottom:26px; }
.safetyTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.safetyTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.safetyTexts5{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.safetyTexts6{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; margin-bottom:26px; }
.safetyTexts7{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.safetyTexts8{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.safetyTexts9{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }

.safetyTexts1E{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; margin-bottom: 26px; }


.businessTextBlack{ font-family:'SUIT-Medium'; font-weight: 500; font-size:12px; color:#000000; margin-left: 4px; }
.businessTextBlackE{ font-family:'SUIT-Medium'; font-weight: 500; font-size:12px; color:#000000; margin-left: 4px; }
.businessTextBold2{ font-family:'SUIT-SemiBold'; font-size:14px; font-weight: 600; color: #134A97; }
.businessTextBold3{ font-family:'SUIT-SemiBold'; font-size:14px; font-weight: 600; color: #134A97; }


.safetyMText2{ display:flex; flex-direction: column; padding: 26px; }
.safetyTextConts2{ display:block; /* height:320px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; margin-bottom: 12px; }
.safetyTextConts2 .sTarget1{ display:inline-block;  height:32px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 30px; margin-top:34px; line-height:36px; 
                             font-family:'SUIT-Regular'; font-size:12px; color: #4D8DE8;  }
.safetyTextConts2 .sSubFlex1{ display:flex; margin-left: 30px; margin-top: 34px; margin-right:16px; }
.sSubFlex1 .sCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.sSubFlex1 .sSubTitle1{ display:block;  line-height: 18px; }
.sSubFlex1 .sSubTitle1 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333; }
.sSubCont1{ line-height:18px; margin-left:30px; margin-top:20px; margin-bottom: 30px; }
.sSubCont1 p{ display:block; font-family:'SUIT-Regular'; font-size:12px; color: #888888; }

.safetyTextConts3{ display:block; /* height:320px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; margin-bottom: 12px; }
.safetyTextConts3 .sTarget2{ display:inline-block; height:32px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 30px; margin-top:34px; line-height:36px; 
                             font-family:'SUIT-Regular'; font-size:12px; color: #4D8DE8; }
.safetyTextConts3 .sSubFlex2{ display:flex; margin-left: 30px; margin-top: 34px; }
.sSubFlex2 .sCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.sSubFlex2 .sSubTitle2{ display:block;  /* height: 71px; */ line-height: 20px; }
.sSubFlex2 .sSubTitle2 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333;  }
.sSubCont2{ line-height:18px; margin-left:30px; margin-top:20px; margin-bottom: 30px; }
.sSubCont2 p{ display:block; font-family:'SUIT-Regular'; font-size:12px; color: #888888; }


.majorCases{ text-align:center; margin-top:120px;  }
.majorCircle{ display:block; width:12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom:16px; }
.majorTitle{ display:block; font-family:'SUIT-Medium'; font-size:16px; color: #333333; margin-bottom:80px; }
.majorFlexPic{ display:flex; width:100%; justify-content:center; margin-bottom: 36px; }
.majorFlexPic2{ display:flex; width: 100%; justify-content:center; }

.majorFlexPic .case1{ display:block; margin-right: 20px; }
.majorFlexPic .case1 .caseIcon1{ display:block; width:150px; height:150px; background: url(../../BusinessIntro/image/case1.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px;
                                 background-size:100%; background-position: center; }
.majorFlexPic .case1 .caseText1{ text-align:center; line-height:14px; }
.majorFlexPic .case1 .caseText1 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555;  }

.majorFlexPic .case2{ display:block; }
.majorFlexPic .case2 .caseIcon2{ display:block; width:150px; height:150px; background: url(../../BusinessIntro/image/case2.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                 background-position: center; background-size:100%; }
.majorFlexPic .case2 .caseText2{ text-align:center; line-height:14px; }
.majorFlexPic .case2 .caseText2 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555; }


.majorFlexPic2 .case3{ display:block; margin-right: 20px; }
.majorFlexPic2 .case3 .caseIcon3{ display:block; width:150px; height:150px; background: url(../../BusinessIntro/image/case3.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexPic2 .case3 .caseText3{ text-align:center; line-height:14px; }
.majorFlexPic2 .case3 .caseText3 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555; }

.majorFlexPic2 .case4{ display:block; }
.majorFlexPic2 .case4 .caseIcon4{ display:block; width:150px; height:150px; margin-bottom:28px; }
.majorFlexPic2 .case4 .caseText4{ text-align:center; line-height:14px; }
.majorFlexPic2 .case4 .caseText4 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555; }



/* 디지털 트윈 */
.digitalInforContentsSect2{ display:block; width:100%; /* padding-top: 200px; */  margin:0 auto; margin-bottom:180px; }
.digitalTextBox{ display:block;  margin:0 auto;  }
.digitalManageTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 20px; text-align: center; margin-bottom: 50px;  }

.digitalManageContents{ display:block; }
.digitalMText1{ display:block; text-align:center; margin-bottom:48px; padding: 0px 20px; }
.digitalTextConts{ display:block; line-height:18px; }
/* .digitalTextConts span{ display:block; font-family: 'SUIT-Regular'; font-size: 22px; line-height:34px; color: #333333; }
.digitalTextConts span:nth-child(1){ margin-bottom:34px; }
.digitalTextConts span:nth-child(3){ margin-bottom:34px; }  */

.digitalTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; /* margin-bottom:26px; */ }
.digitalTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; margin-bottom: 26px; }
.digitalTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; /* margin-bottom:26px; */ }
.digitalTexts4{ display: inline-block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.digitalTexts5{ display: inline-block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.digitalTexts6{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; margin-bottom:26px; }
.digitalTexts7{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.digitalTexts8{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.digitalTexts9{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }
.digitalTexts10{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; }

.digitalTexts1E{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:12px; color:#333333; margin-bottom:26px; }

.digitalMText2{ display:flex; justify-content:center; flex-direction: column; padding:26px; }
    /* .dSubFlex3 .dSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.digitalTextConts2{ display:inline-block;  /* height:290px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-bottom: 12px; }
.digitalTextConts2 .dTarget1{ display:inline-block; height:32px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 30px; margin-top:34px; line-height:32px; 
                             font-family:'SUIT-Regular'; font-size:12px; color: #4D8DE8; }
.digitalTextConts2 .dSubFlex1{ display:flex; margin-left: 30px; margin-top: 34px; margin-right:16px; }
.dSubFlex1 .dCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex1 .dSubTitle1{ display:block; width: 336px;  line-height: 20px; }
.dSubFlex1 .dSubTitle1 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333; }
.dSubCont1{ line-height:18px; margin-left:30px; margin-top:20px; margin-bottom:30px; }
.dSubCont1 p{ display:block; font-family:'SUIT-Regular'; font-size:12px; color: #888888; }
.dSubCont1 p:nth-child(2){ margin-left:14px; }
.dSubCont1 p:nth-child(4){ }

.digitalTextConts3{ display:inline-block; /* height:290px; */  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-bottom: 12px; }
.digitalTextConts3 .dTarget2{ display:inline-block;  height:32px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 30px; margin-top:34px; line-height:32px; 
                             font-family:'SUIT-Regular'; font-size:12px; color:#4D8DE8; }
.digitalTextConts3 .dSubFlex2{ display:flex; margin-left: 30px; margin-top: 34px; }
.dSubFlex2 .dCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex2 .dSubTitle2{ display:block; line-height: 20px; }
.dSubFlex2 .dSubTitle2 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333;  }
.dSubCont2{ line-height:18px; margin-left:30px; margin-top:20px; margin-bottom:30px; }
.dSubCont2 p{ display:block; font-family:'SUIT-Regular'; font-size:12px; color: #888888; }
.dSubCont2 p:nth-child(2){ margin-left:14px; }
.dSubCont2 p:nth-child(3){ margin-left:14px; }


.digitalTextConts4{ display:inline-block; /* height:290px; */  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-bottom: 12px; }
.digitalTextConts4 .dTarget3{ display:inline-block;  height:32px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 30px; margin-top:34px; line-height:32px; 
                             font-family:'SUIT-Regular'; font-size:12px; color: #4D8DE8;  }
.digitalTextConts4 .dSubFlex3{ display:flex; margin-left: 30px; margin-top: 34px; }
.dSubFlex3 .dCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex3 .dSubTitle3{ display:block; width: 336px; line-height: 20px; }
.dSubFlex3 .dSubTitle3 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333;  }
.dSubCont3{ line-height:18px; margin-top:20px;  margin-bottom:30px; margin-left:30px; }
.dSubCont3 p{ display:block; font-family:'SUIT-Regular'; font-size:12px; color: #888888; }
.dSubCont3 p:nth-child(2){ }


.majorCasesD{ text-align:center; margin-top:120px;  }
.majorCircleD{ display:block; width:12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom:16px; }
.majorTitleD{ display:block; font-family:'SUIT-Medium'; font-size:16px; color: #333333; margin-bottom:80px; }
.majorFlexBoxD{ display:flex; width:100%; justify-content:center; margin-bottom: 36px; }
.majorFlexBoxD2{ display:flex; width:100%; justify-content:center; }

.majorFlexBoxD .case3{ display:block; margin-right: 20px; }
.majorFlexBoxD .case3 .caseIcon3{ display:block; width:150px; height:150px; background: url(../../BusinessIntro/image/case4.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexBoxD .case3 .caseText3{ text-align:center; line-height:20px; }
.majorFlexBoxD .case3 .caseText3 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555;  }

.majorFlexBoxD .case4{ display:block; /* margin-right: 10px; */ }
.majorFlexBoxD .case4 .caseIcon4{ display:block; width:150px; height:150px; background: url(../../BusinessIntro/image/case5.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                   background-position: center; background-size:100%; }
.majorFlexBoxD .case4 .caseText4{ text-align:center; line-height:20px; }
.majorFlexBoxD .case4 .caseText4 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555; }c


.majorFlexBoxD2 .case5{ display:block; margin-right: 20px; }
.majorFlexBoxD2 .case5 .caseIcon5{ display:block; width:150px; height:150px; background: url(../../BusinessIntro/image/case6.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                   background-position: center; background-size:100%; margin-right: 20px; }
.majorFlexBoxD2 .case5 .caseText5{ text-align:center; line-height:20px; display:block; margin-right: 20px; }
.majorFlexBoxD2 .case5 .caseText5 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555; }

.majorFlexBoxD2 .case6{ display:block; }
.majorFlexBoxD2 .case6 .caseIcon6{ display:block; width:150px; height:150px; margin-bottom:28px; }
.majorFlexBoxD2 .case6 .caseText6{ text-align:center; line-height:20px; }
.majorFlexBoxD2 .case6 .caseText6 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555; }




/* 주요 실적 */
.majorPerformanceContentsS{ display:block; width:100%; /* padding-top: 200px; */  margin: 0 auto; /* margin-bottom:180px; */ }
.majorPerformanceTitleS{ display:block;  height:43px; font-family: 'SUIT-SemiBold'; font-size:20px; text-align:center; margin-bottom: 79px; margin:0 auto; margin-bottom: 24px; }
/* .majorPerformanceSelect{ display:block; width:1360px; margin:0 auto; } */

.majorListBox{ display:block; /* margin-top:96px; */ padding: 0px 20px;  }
.majorBox{ display:block; /* height:93px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin:0 auto; padding: 20px 40px;
           margin-bottom:16px; }
.majorBox .majorClass{ display:block; font-family:'SUIT-Medium'; font-size:10px; color: #C0C0C0; margin-bottom:8px;  }
.majorBox .majorFlexBoxM{ /* height:25px; */ }
.majorBox .majorListTitle{ display:inline-block; font-family:'SUIT-Regular'; font-size:13px; line-height: 16px; color: #333333; height:25px; }
.majorFlexBoxY{ display:flex; margin-top: 16px;  }
.majorFlexBoxY .majorListYear{ display:block; width:52px; font-family:'SUIT-Light'; font-size:12px; color: #888888; width:51px; /* margin-right: 32px; */ }
.majorFlexBoxY .majorListOrder{ display:block; font-family:'SUIT-Light'; font-size:12px; color: #888888; width:126px; }


/* 파트너사 */
.partnerContents{ display:block; width:100%; padding-top: 200px; margin:0 auto; }
.partnerTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 20px; text-align: center; margin-bottom: 88px; }
.clientBox{ display:block; width:100%; height:480px; text-align:center; background:url(../../CustomerSupport/image/menu_4_image.png)no-repeat;
            margin-bottom:184px; background-size:cover;  }
.clientTitle{ display:block; }
.clientTitle span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.clientTitle span:nth-child(2){ display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8); }

.clientArea{ display:block; width:100%; text-align:center; }
.clientFirst{ display:flex; justify-content:center; margin-bottom:24px; }
.clientSecond{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientThird{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientFourth { display:flex; justify-content:center; margin-bottom:24px; }
.clientFifth{ display:flex; justify-content:center; margin-bottom:24px; }
.clientSixth{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientSeventh{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientEighth { display:flex; justify-content:center; }


.clientContents { display:block; width: 100px; height:100px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:12px; }
.clientContents span:nth-child(1){ display:block; width:100px; height:100px; }

.clientContentsB{ display:block; width: 100px; height:100px; margin-right:12px; }
.clientContentsB span:nth-child(1){ display:block; width:100px; height:100px; }

.clientName{ display:block; width:100px; height:26px; line-height:26px; background: #f0f0f0; font-family: 'SUIT-Regular'; font-size:10px; color: #aaaaaa; border-radius: 0px 0px 6px 6px;
             overflow:hidden; text-overflow: ellipsis; white-space: nowrap; padding: 0px 4px; }

.clientFirst .clientLogo1{ display:block; background:url(../../BusinessIntro/image/partnerImg1.png)no-repeat; background-position:center; background-size: 80%; }
.clientFirst .clientLogo2{ display:block; background:url(../../BusinessIntro/image/partnerImg2.png)no-repeat; background-position:center; background-size: 80%; }
.clientFirst .clientLogo3{ display:block; background:url(../../BusinessIntro/image/partnerImg3.png)no-repeat; background-position:center; background-size: 80%; }

.clientSecond .clientLogo4{ display:block; background:url(../../BusinessIntro/image/partnerImg4.png)no-repeat; background-position:center; background-size: 80%; }
.clientSecond .clientLogo5{ display:block; background:url(../../BusinessIntro/image/partnerImg5.png)no-repeat; background-position:center; background-size: 80%; }
.clientSecond .clientLogo6{ display:block; background:url(../../BusinessIntro/image/partnerImg6.png)no-repeat; background-position:center; background-size: 80%; }

.clientThird .clientLogo7{ display:block; background:url(../../BusinessIntro/image/partnerImg7.png)no-repeat; background-position:center; background-size: 80%; }
.clientThird .clientLogo8{ display:block; background:url(../../BusinessIntro/image/partnerImg8.png)no-repeat; background-position:center; background-size: 80%; }
.clientThird .clientLogo9{ display:block; background:url(../../BusinessIntro/image/partnerImg9.png)no-repeat; background-position:center; background-size: 80%; }

.clientFourth .clientLogo10{ display:block; background:url(../../BusinessIntro/image/partnerImg10.png)no-repeat; background-position:center; background-size: 80%; }
.clientFourth .clientLogo11{ display:block; background:url(../../BusinessIntro/image/partnerImg11.png)no-repeat; background-position:center; background-size: 80%; }
.clientFourth .clientLogo12{ display:block; background:url(../../BusinessIntro/image/partnerImg12.png)no-repeat; background-position:center; background-size: 80%; }

.clientFifth .clientLogo13{ display:block; background:url(../../BusinessIntro/image/partnerImg13.png)no-repeat; background-position:center; background-size: 80%; }
.clientFifth .clientLogo14{ display:block; background:url(../../BusinessIntro/image/partnerImg14.png)no-repeat; background-position:center; background-size: 80%; }
.clientFifth .clientLogo15{ display:block; background:url(../../BusinessIntro/image/partnerImg15.png)no-repeat; background-position:center; background-size: 80%; }

.clientSixth .clientLogo16{ display:block; background:url(../../BusinessIntro/image/partnerImg16.png)no-repeat; background-position:center; background-size: 80%; }
.clientSixth .clientLogo17{ display:block; background:url(../../BusinessIntro/image/partnerImg17.png)no-repeat; background-position:center; background-size: 80%; }
.clientSixth .clientLogo18{ display:block; background:url(../../BusinessIntro/image/partnerImg18.png)no-repeat; background-position:center; background-size: 80%; }

.clientSeventh .clientLogo19{ display:block; background:url(../../BusinessIntro/image/partnerImg19.png)no-repeat; background-position:center; background-size: 80%; }
.clientSeventh .clientLogo20{ display:block; background:url(../../BusinessIntro/image/partnerImg20.png)no-repeat; background-position:center; background-size: 80%; }
.clientSeventh .clientLogo21{ display:block; background:url(../../BusinessIntro/image/partnerImg21.png)no-repeat; background-position:center; background-size: 80%; }

.clientEighth .clientLogo22{ display:block; background:url(../../BusinessIntro/image/partnerImg22.png)no-repeat; background-position:center; background-size: 80%; }
.clientEighth .clientLogo23{ display:block; background:url(../../BusinessIntro/image/partnerImg23.png)no-repeat; background-position:center; background-size: 80%; }



/* 보도자료 */
.newsTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 20px; text-align: center; padding-top: 200px; }
.reportBox{ display:block; width:100%; height:480px; text-align:center;  background:url(../../CompanyNews/image/menu_3_image.png)no-repeat;
            margin-bottom:180px; background-size:cover;  }
.reportTitle{ display:block; }
.reportTitle span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.reportTitle span:nth-child(2){ display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8); }

.reportArea{ display:flex; width:100%; text-align:center; margin-top:72px; justify-content:center; }

/* .postingTopArea{ display:flex; width:1280px; }
.postingTopArea .postingCount{ display:inline-block; width: 146px; height:24px; line-height:24px; font-family: 'SUIT-Light'; font-size:20px; color:#707070; } 
.postingTopArea .postingCount p{ display:inline-block; font-family: 'SUIT-Medium'; font-size:20px; color:#DD3C25; }

.postingTopArea .postingRightBox{ display:flex; width:432px; }
.postingTopArea .postingSelectBox{ display:inline-block; width:140px; height: 44px; border: 0.5px solid #C0C0C0; border-radius: 24px;
                                   font-family: 'SUIT-Light';  font-size:19px; margin-right:12px; padding-left:24px; background:url('../../CompanyNews/image/다각형 2.png')no-repeat;
                                   background-size: 8px;  background-position-y: 20px; background-position-x: 110px; cursor:pointer; }
.postingTopArea .postingSelectBox .postingSelectOption{ display:inline-block; width:140px; height:44px; border:dashed 1px red; } 

.postingTopArea .postingSearch{ display:flex; width:280px; height:44px; border:0.5px solid #C0C0C0; border-radius: 24px;  }
.postingTopArea .postingInput{ display:inline-block; width:232px; height:40px; border:none; border-radius: 24px; padding-left: 30px; }
.postingTopArea .postingInput:focus{outline:none;}

.postingTopArea .postingSearch .searchIcon{ display: inline-block; width: 23px; height:40px; background:url('../../CompanyNews/image/search_icon.png')no-repeat; text-align: end;
                                            background-position-y:10px; } */

.postingBottomArea{ display:flex; flex-direction:column; flex-wrap: nowrap;  width:340px; /* height:1200px; */ text-align:center; margin:0 auto; margin-bottom:100px; 
                    position:relative; } 
.postingBottomArea2{ display:flex; width:1360px; height:600px; text-align:center; margin:0 auto; margin-bottom:50px; }


.postingBoxArea{ display:block; height:380px; /* overflow:hidden; */ flex-direction:inherit; flex-wrap: nowrap; margin:0 auto; position:relative; }
/* .postingBoxArea2{ display:block; flex-direction:inherit; width: 1378px; height: 1232px; flex-wrap: nowrap; margin:0 auto; }
.postingBoxAreaBoard{ display:block; flex-direction:inherit; width: 1430px; height: 1232px; flex-wrap: nowrap; margin:0 auto; } */
.postingBox{ display:inline-block; float:left; width: 280px; height: 380px; margin-bottom:32px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06);  border-radius: 8px; }
.postingImg > img{ display:inline-block; width: 280px; height:150px; border-top-left-radius:8px; border-top-right-radius:8px; }
.postingText{ display:block; text-align:left; height:180px; /* overflow:hidden; */ padding:20px 20px; } 
.postingTitle{ display:block; /* width: 372px; */ font-family: 'SUIT-Regular'; font-size:14px; line-height:20px; 
               overflow: hidden; text-overflow: ellipsis; margin-bottom:20px; color:#333333; }
.postingContents{ display:block; /* width:372px; */ height:98px; font-family: 'SUIT-Light'; font-size:12px; line-height:16px; overflow: hidden; text-overflow: ellipsis; /* margin-bottom:20px; */ 
                  color: #555555; }
.postingDate{ display:block; /* width:372px; */  height:20px; font-family: 'SUIT-Light'; font-size:10px; line-height:20px; color:#aaaaaa; text-align:right; }


/* .postingBox22{ display:inline-block; float:left; width: 328px; height: 434px; margin-right:16px; margin-bottom:32px; box-shadow: 3px 3px 3px #0000001A; border: 2px solid #F0F0F0; }
.postingImg22{ display:inline-block; width: 328px; height:220px; overflow:hidden; }
.postingImg22 > img{ display:inline-block; width: 328px; height:220px; }
.postingText22{ display:block; padding: 20px; text-align:left; height:214px; overflow:hidden; line-height:26px; color:#505050; } 
.postingTitle22{ display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; height: 60px; font-family: 'SUIT-Regular'; font-size:22px; line-height:32px; 
                 overflow: hidden; text-overflow: ellipsis;  margin-bottom:24px; }
.postingContents22{ display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; height:84px; font-family: 'SUIT-Light'; font-size:16px; line-height:22px; overflow: hidden; text-overflow: ellipsis; margin-bottom:19px; }
.postingDate22{ display:block;  height:20px; font-family: 'SUIT-Light'; font-size:16px; line-height:26px; color:#BBBBBB; } */

.pagination { display: flex; justify-content: center; align-items: center; margin-bottom:240px; }
.pagination a { float: left; width:48px; height:48px; text-decoration: none; }
.arrowLeftFast{ display:inline-block; width:32px; height:32px; background:url('../../CompanyNews/image/arrowLeftFast2.png')no-repeat; background-position: center; cursor:pointer; }
.arrowLeft{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowLeft.png)no-repeat; background-position: center; cursor:pointer; }
.arrowRight{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowRight.png)no-repeat; background-position: center; cursor:pointer; }
.arrowRightFast{ display:inline-block; width:32px; height:32px; background:url('../../CompanyNews/image/arrowRightFast2.png')no-repeat; background-position: center; cursor:pointer; }

.btnActive{ width: 48px; height:48px; background: none; font-family: 'SUIT-Medium'; font-size:12px; color:#999999; }


/* 보도자료 Popup  */
.reportContents{ display:block; width:300px; height:480px; margin:0 auto; position: absolute;
                 top: 50%; left: 50%; transform: translate(-50%, -50%); text-align:left; padding: 20px; }
.reportContentsTop{ display:block; margin-bottom:20px; }
.reportContentsTop .reportContentsTitle{ font-family: 'SUIT-Medium'; font-size:14px; line-height:14px; }

.reportContentsMiddle{ display:block; margin-bottom:12px; }
.reportContentsMiddle .reportImg{ display:block; margin:0 auto; background-position:center; background-size:800px 540px; width:200px; height:120px;
                                  margin-bottom:32px; }
.reportContentsMiddle .reportContsArea{ display:block; height:120px; overflow-y:scroll; overflow-x:hidden; }
.reportContentsMiddle .reportConts{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:left; line-height: 16px; /* width:300px; */ }
.reportContentsMiddle .reportConts .reportTextMar{ margin-bottom:25px; }

.reportContentsBottom{ height:36px; }
.reportContentsBottom .reportSources{ display:inline-block; width: 100%; font-family: 'SUIT-Light'; font-size:8px; line-height:20px; height:20px; color:#aaaaaa;
                                      text-decoration: underline; }
.reportContentsBottom .reportDay{ font-family: 'SUIT-Regular'; font-size:8px; color:#aaaaaa; float:right; }

.reportBtnLeft{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/popupBtnLeft.png)no-repeat; transform: translate(-54px, 200px);
                background-size: 28px; }
.reportBtnLeft:hover{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/popupBtnLeftHover1.png)no-repeat; transform: translate(-54px, 200px);
                      background-size: 28px; }


/**********************************************************************************************************************************************************************************/
/**********************************************************************************************************************************************************************************/
.reportBtnLeftCon{ display:inline-block; width:10px; height:16px; background:url(../../BusinessIntro/image/newsArrowLeft.png)no-repeat; 
                   position:absolute; z-index: 99; background-position: center; left: -30px; top: 180px;  }    
/* .reportBtnLeftCon:hover{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/newsArrowLeft.png)no-repeat; transform: translate(-180px, 200px);
                            position:absolute; z-index: 99; } */
.reportBtnLeftCon:active{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowLeftActive.png)no-repeat; 
                          position:absolute; z-index: 99; background-position: center; left: -50px; top: 164px; /* transform:scale(1.3); */ background-size: 40px;  }

.reportBtnLeftConHidden{ display:none; }


/* .reportBtnLeftCon::after{ content: ""; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
                          width: 100%; height: 100%; border-radius: 20px; border: 1px dashed #ccc; background-color: rgba(255,0,0,0.1); } */

/**********************************************************************************************************************************************************************************/
/**********************************************************************************************************************************************************************************/

.reportBtnRight{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/popupBtnRight.png)no-repeat; transform: translate(258px, -204px);
                 background-size: 28px; }

.reportBtnRight:hover{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/popupBtnLeftHover1.png)no-repeat; transform: translate(258px, -204px) rotate(180deg);
                       background-size: 28px; }

/**********************************************************************************************************************************************************************************/
/**********************************************************************************************************************************************************************************/

.reportBtnRightCon{ display:inline-block; width:10px; height:16px; background:url(../../BusinessIntro/image/newsArrowRight.png)no-repeat; 
                     position:absolute; z-index: 99; background-position: center; top: 180px; right:-30px; }
/* .reportBtnRightCon:hover{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/popupBtnLeftHover1.png)no-repeat; transform: translate(15px, 200px) rotate(180deg);
                             position:absolute; z-index: 99; } */

.reportBtnRightCon:active{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowLeftActive.png)no-repeat; 
                           position:absolute; z-index: 99; background-position: center; top: 164px; right: -50px; /* transform:scale(1.3); */ background-size: 40px; transform: rotate(180deg);  }

/* .reportBtnRightCon::after{ content: ""; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
                              width: 100%; height: 100%; border-radius: 20px; border: 1px dashed #ccc; background-color: rgba(255,0,0,0.1); }  */ 

.reportBtnRightConHidden{ display:none; }

/**********************************************************************************************************************************************************************************/
/**********************************************************************************************************************************************************************************/


.reportBtnClose{ display:inline-block; width: 28px; height:28px; background:url(../../BusinessIntro/image/popupBtnClose.png)no-repeat; transform: translate(286px, -240px);
                 background-size: 28px; }

.reportArrowTab{ display:block; height:60px; line-height:60px; text-align:center; width:1360px; margin:0 auto; margin-bottom:180px; }
.reportArrowTab .reportArrowAreaL{ display:inline-block; float:left; font-family: 'SUIT-Light'; font-size:19px; letter-spacing: 0px; cursor:pointer; }
.reportArrowTab .reArrowIcon{ display:inline-block; font-family: 'SUIT-Light'; font-size:19px; width: 13px; height:19px; margin-right:20px;
                              background:url('../../CompanyNews/image/reArrowLeft.png')no-repeat; background-position:center; }
.reportArrowTab .reportBtn{ display:inline-block; width:260px; height:60px; line-height:60px; background:#000000; border-radius:30px; color: #fff; 
                            font-family: 'SUIT-Medium'; font-size:26px; text-align:center; }
.reportArrowTab .reportBtn a{ color: #fff; text-decoration:none; }
.reportArrowTab .reportBtn a:hover{ color: #fff;}
.reportArrowTab .reportArrowAreaR{ display:inline-block;  float: right; font-family: 'SUIT-Light'; font-size:19px; letter-spacing: 0px; cursor:pointer; }
.reportArrowTab .reArrowIcon2{ display:inline-block; font-family: 'SUIT-Light'; font-size:19px; width: 13px; height:19px; margin-left:20px;
                               background:url('../../CompanyNews/image/reArrowRight.png')no-repeat; background-position:center; }

.searchBtn{ display:block; width: 150px; height:50px; border:dashed 1px red; }

.uNewsArrowArea{ display:flex; width:80px; height:50px; right: 0; top: -50px; margin-left: 1285px;}
.uNewsArrowL{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_L.png')no-repeat; background-position: center; }
.uNewsArrowL:hover{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_L_hover.png')no-repeat; background-position: center; }
.uNewsArrowR{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_R.png')no-repeat; background-position: center; }
.uNewsArrowR:hover{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_R_hover.png')no-repeat; background-position: center; }


.reportContsArea::-webkit-scrollbar { width: 8px; border-radius: 4px; cursor: pointer;}
.reportContsArea::-webkit-scrollbar-track { cursor: pointer;}
.reportContsArea::-webkit-scrollbar-thumb { border-radius:4px; background-color:#f0f0f0; cursor:pointer;}

}


/* 가로 mobile */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/

@media all and (min-width: 640px) and (max-width: 959px) {

.arrowTopIcon{ display:inline-block; width:40px; height:38px; background:url('../../components/image/arrow_icon.png')no-repeat; padding:5px; }

.bIntroTitle{ display:block; width:100%; height:280px; text-align:center;  background:url(../../BusinessIntro/image/businessBG22.png)no-repeat; /* background-size:cover; */
              padding:0px 20px; background-size: 120% 60%; }
.bIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 26px; font-weight: 600; padding-top:50px; padding-bottom: 16px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#ffffff; }
.bIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:12px; color: #F9F9F9; 
                                   height: 28px; line-height: 22px; padding: 0px 48px; }


/* 기술 알아보기 */
.spatialInforContentsSect{ display:block; padding-top: 120px; margin:0 auto; margin-bottom:180px; } 
.spatialTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 22px; text-align: center; margin-bottom: 40px; line-height: 44px; }
.spatialTextBoxTop{ display:block; line-height:22px; text-align:center; margin:0 auto; margin-bottom:48px; padding:0px 50px; }
.spatialTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; margin-bottom:34px; }
.spatialTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.spatialTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.businessTextBold{ /* display: inline-block; */  font-family:'SUIT-Medium'; font-weight: 500; font-size:14px; color:#4D8DE8; margin-left:4px; }
.businessTextBoldE{ /* display: inline-block; */  font-family:'SUIT-Medium'; font-weight: 500; color:#4D8DE8; margin-left:4px; margin-right:4px; }

.spatialFlexBox{ display:flex; flex-direction: column; padding:0px 60px; }
.spatialImgBox .spatialInforImg{ display:block; height:200px; background:url(../../BusinessIntro/image/디지털트윈_111.png)no-repeat; background-size: cover;
                                 border-radius:8px; margin-bottom: 22px; }

.spatialTextBox{ display:block;  box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px;  padding-right: 30px; }
.spatialTextBox .spTarget1{ display:block; width: 130px; height:32px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 24px 0px 24px; margin-top:30px; line-height:32px;
                             font-family:'SUIT-Regular'; font-size:14px; color: #4D8DE8; }
.spatialTextBox .spSubFlex1{ display:flex; margin-left: 20px; margin-top: 20px; }
.spSubFlex1 .spCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 80%; }
.spSubFlex1 .spSubTitle1{ display:block; line-height: 18px; /* width: 280px; */ }
.spSubFlex1 .spSubTitle1 p{ font-family:'SUIT-Regular'; font-size:13px; font-weight: 400; color: #333333; }  
.spSubFlex1 .spSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.spatialTextBox .spSubFlex2{  display:flex; margin-left: 20px; margin-top: 20px; }
.spSubFlex2 .spCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 80%; }
.spSubFlex2 .spSubTitle2{ display:block; line-height: 18px; /* width: 280px; */ }
.spSubFlex2 .spSubTitle2 p{ font-family:'SUIT-Regular'; font-size:13px; font-weight: 400; color: #333333; }
.spSubFlex2 .spSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.spatialTextBox .spSubFlex3{  display:flex; margin-left: 20px; margin-top: 20px; margin-bottom: 30px; }
.spSubFlex3 .spCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 80%; }
.spSubFlex3 .spSubTitle3{ display:block; line-height: 18px; /* width: 280px; */ }
.spSubFlex3 .spSubTitle3 p{ font-family:'SUIT-Regular'; font-size:13px; font-weight: 400; color: #333333;  }
.spSubFlex3 .spSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97; }

.spatialBottomText{ display:block;  height:25px; font-family:'SUIT-Light'; font-size:12px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align: center; }


.eSopContentsSect{ display:block;  margin:0 auto; margin-bottom:180px; } 
.eSopTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 22px; text-align: center; margin-bottom: 72px; line-height: 44px; }
.eSopTextBoxTop{ display:block; /* height:136px; */ line-height:22px; text-align:center; margin:0 auto; margin-bottom:48px; padding:0px 50px; }
.eSopTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; margin-bottom:34px; }
.eSopTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.eSopTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }


.eSopFlexBox{ display:flex; flex-direction: column; padding:0px 60px;  }
.eSopImgBox .eSopImg{ display:block; height:200px; background:url(../../BusinessIntro/image/bn_2.png)no-repeat; background-size: cover;
                      border-radius:8px; margin-bottom: 22px;  }

.eSopTextBox{ display:block; /* height:370px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; padding-right: 30px;  }
.eSopTextBox .eSopTarget1{ display:block; width: 130px; height:32px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 24px 0px 24px; margin-top:30px; line-height:32px;
                             font-family:'SUIT-Regular'; font-size:14px; color: #4D8DE8; }
.eSopTextBox .eSopSubFlex1{ display:flex; margin-left: 20px; margin-top: 20px; }
.eSopSubFlex1 .eSopCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 80%; }
.eSopSubFlex1 .eSopSubTitle1{ display:block; line-height: 18px; /* width: 280px; */  }
.eSopSubFlex1 .eSopSubTitle1 p{ font-family:'SUIT-Regular'; font-size:13px; font-weight: 400; color: #333333; }  
.eSopSubFlex1 .eSopSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.eSopTextBox .eSopSubFlex2{  display:flex; margin-left: 20px; margin-top: 20px; }
.eSopSubFlex2 .eSopCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 80%; }
.eSopSubFlex2 .eSopSubTitle2{ display:block; line-height: 18px; /* width: 280px; */ }
.eSopSubFlex2 .eSopSubTitle2 p{ font-family:'SUIT-Regular'; font-size:13px; font-weight: 400; color: #333333; }
.eSopSubFlex2 .eSopSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.eSopTextBox .eSopSubFlex3{  display:flex; margin-left: 20px; margin-top: 20px; margin-bottom: 30px; }
.eSopSubFlex3 .eSopCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 80%; }
.eSopSubFlex3 .eSopSubTitle3{ display:block; line-height: 18px; /* width: 280px; */ }
.eSopSubFlex3 .eSopSubTitle3 p{ font-family:'SUIT-Regular'; font-size:13px; font-weight: 400; color: #333333;  }
.eSopSubFlex3 .eSopSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97; }

.eSopBottomText{ display:block;  height:25px; font-family:'SUIT-Light'; font-size:12px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align:center; }


.dataVisualContentsSect{ display:block;  margin:0 auto; margin-bottom:180px; } 
.dataTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 22px; text-align: center; margin-bottom: 40px; line-height: 44px; }
.dataTextBoxTop{ display:block; line-height:22px; text-align:center; margin:0 auto; margin-bottom:48px; padding:0px 50px; }
.dataTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; margin-bottom:34px; }
.dataTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.dataTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.dataTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }

.dataFlexBox{ display:flex; flex-direction:column; padding:0px 60px;  }
.dataImgBox .dataImg{ display:block;  height:200px; background:url(../../BusinessIntro/image/bn_333.png)no-repeat; background-size: cover;
                                 border-radius:8px; margin-bottom: 22px; }

.dataTextBox{ display:block; /* height:370px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; padding-right: 30px;  }
.dataTextBox .dataTarget1{ display:block; width: 130px; height:32px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 24px 0px 24px; margin-top:30px; line-height:32px;
                             font-family:'SUIT-Regular'; font-size:14px; color: #4D8DE8;  }
.dataTextBox .dataSubFlex1{ display:flex; margin-left: 20px; margin-top: 20px; }
.dataSubFlex1 .dataCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 80%; }
.dataSubFlex1 .dataSubTitle1{ display:block; line-height: 18px; /* width: 280px; */ }
.dataSubFlex1 .dataSubTitle1 p{ font-family:'SUIT-Regular'; font-size:13px; font-weight: 400; color: #333333; }  
.dataSubFlex1 .dataSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.dataTextBox .dataSubFlex2{  display:flex; margin-left: 20px; margin-top: 20px; }
.dataSubFlex2 .dataCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 80%; }
.dataSubFlex2 .dataSubTitle2{ display:block; line-height: 18px; /* width: 280px; */ }
.dataSubFlex2 .dataSubTitle2 p{ font-family:'SUIT-Regular'; font-size:13px; font-weight: 400; color: #333333; }
.dataSubFlex2 .dataSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.dataTextBox .dataSubFlex3{  display:flex; margin-left: 20px; margin-top: 20px;  margin-bottom: 30px; }
.dataSubFlex3 .dataCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 80%; }
.dataSubFlex3 .dataSubTitle3{ display:block; line-height: 18px; /* width: 280px; */  }
.dataSubFlex3 .dataSubTitle3 p{ font-family:'SUIT-Regular'; font-size:13px; font-weight: 400; color: #333333;  }
.dataSubFlex3 .dataSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97; }

.dataBottomText{ display:block; height:25px; font-family:'SUIT-Light'; font-size:12px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align:center; }


.systemLinkContentsSect{ display:block;  margin:0 auto; /* margin-bottom:180px; */ }
.systemTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 22px; text-align: center; margin-bottom: 40px; line-height: 44px; }
.systemTextBoxTop{ display:block; line-height:22px; text-align:center; margin:0 auto; margin-bottom:48px; padding:0px 50px; }
.systemTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; margin-bottom:34px; }
.systemTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.systemTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.systemTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }


.systemFlexBox{ display:flex; justify-content:center; flex-direction:column; padding:0px 60px; }
.systemImgBox .systemImg{ display:block; height:200px; background:url(../../BusinessIntro/image/bn_444.png)no-repeat; background-size: cover;
                                 border-radius:8px; margin-bottom: 22px; }

.systemTextBox{ display:block; /* height:370px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; padding-right: 30px;  }
.systemTextBox .systemTarget1{ display:block; width: 130px; height:32px; background: #F4F8FD; border-top-right-radius:20px;
                               border-bottom-right-radius:20px; padding:0px 24px 0px 24px; margin-top:30px; line-height:32px;
                               font-family:'SUIT-Regular'; font-size:14px; color: #4D8DE8; }
.systemTextBox .systemSubFlex1{ display:flex; margin-left: 20px; margin-top: 20px; }
.systemSubFlex1 .systemCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 80%; }
.systemSubFlex1 .systemSubTitle1{ display:block; line-height: 18px; /* width: 280px; */ }
.systemSubFlex1 .systemSubTitle1 p{ font-family:'SUIT-Regular'; font-size:13px; font-weight: 400; color: #333333; }  
.systemSubFlex1 .systemSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.systemTextBox .systemSubFlex2{  display:flex; margin-left: 20px; margin-top: 20px; }
.systemSubFlex2 .systemCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 80%; }
.systemSubFlex2 .systemSubTitle2{ display:block; line-height: 18px; /* width: 280px; */ }
.systemSubFlex2 .systemSubTitle2 p{ font-family:'SUIT-Regular'; font-size:12px; font-weight: 400; color: #333333; }
.systemSubFlex2 .systemSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97;  }

.systemTextBox .systemSubFlex3{  display:flex; margin-left: 20px; margin-top: 20px; margin-bottom: 30px; }
.systemSubFlex3 .systemCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:8px; background-size: 80%; }
.systemSubFlex3 .systemSubTitle3{ display:block; line-height: 18px; /* width: 280px; */  }
.systemSubFlex3 .systemSubTitle3 p{ font-family:'SUIT-Regular'; font-size:13px; font-weight: 400; color: #333333;  }
.systemSubFlex3 .systemSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:12px; font-weight: 600; color: #134A97; }

.systemBottomText{ display:block;  height:25px; font-family:'SUIT-Light'; font-size:12px; color: #555555; 
                   margin: 0 auto; margin-top: 28px; text-align:center; }




/* 특허 */
.comPatBox{ display:block; width:100%; height:480px; text-align:center; padding:50px; background:url(../../CompanyIntro/image/menu_1_image.png)no-repeat;
            margin-bottom:176px; background-size:cover; }
.comPatConTItle2 { font-family: 'SUIT-SemiBold'; font-size: 22px;  display:block; width: 100%; text-align: center; margin-bottom:88px; padding-top:200px; }
.comPatCircle{ display:block; width: 12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom: 16px; }

.comPatContent{ display:block; /* margin-bottom:260px; */  }
.comPatConTItle{ font-family: 'SUIT-Medium'; font-size: 20px; display: block; width: 100%;  text-align: center; margin-bottom: 48px; }

.comPatentBox{ display: block; padding-bottom: 120px; padding: 0px 220px; margin-bottom: 80px; }
.comPatentBox .ParFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParFirst .ParFirstBox1{ display: block;  width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_24.png)no-repeat; 
                                                    width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }

.comPatentBox .ParFirst .ParFirstBox2{ display: block;  width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_25.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; } 


.comPatentBox .ParSecond{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParSecond .ParSecondBox1{ display: block;  width:252px;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_26.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText1{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }


.comPatentBox .ParSecond .ParSecondBox2{ display: block;  width:252px;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_27.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText4 {display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }



.comPatentBox .ParThird{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParThird .ParThirdBox1{ display: block;  width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_28.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText1{display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }


.comPatentBox .ParThird .ParThirdBox2{ display: block;  width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_29.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }



.comPatentBox .ParFourth{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParFourth .ParFourthBox1{ display: block;  width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_30.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText1{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }

.comPatentBox .ParFourth .ParFourthBox2{ display: block;  width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParFourth .ParFourthBox2 .ParFourthImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_31.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFourth .ParFourthBox2 .ParFourthText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox2 .ParFourthText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox2 .ParFourthText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }



.comPatentBox .ParFifth{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParFifth .ParFifthBox1{ display: block; width:252px; height: 372px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comPatentBox .ParFifth .ParFifthBox1 .ParFifthImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_32.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFifth .ParFifthBox1 .ParFifthText1{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParFifth .ParFifthBox1 .ParFifthText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParFifth .ParFifthBox1 .ParFifthText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }


.comPatentBox .ParFifth .ParFifthBox2{ display: block; width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.comPatentBox .ParFifth .ParFifthBox2 .ParFifthImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_33.png)no-repeat; 
                                                    width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFifth .ParFifthBox2 .ParFifthText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParFifth .ParFifthBox2 .ParFifthText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParFifth .ParFifthBox2 .ParFifthText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }



.comPatentBox .ParSixth{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParSixth .ParSixthBox1{ display: block; width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comPatentBox .ParSixth .ParSixthBox1 .ParSixthImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_34.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSixth .ParSixthBox1 .ParSixthText1{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParSixth .ParSixthBox1 .ParSixthText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParSixth .ParSixthBox1 .ParSixthText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }


.comPatentBox .ParSixth .ParSixthBox2{ display: block; width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.comPatentBox .ParSixth .ParSixthBox2 .ParSixthImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_35.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSixth .ParSixthBox2 .ParSixthText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParSixth .ParSixthBox2 .ParSixthText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParSixth .ParSixthBox2 .ParSixthText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }


.comPatentBox .ParSeventh{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParSeventh .ParSeventhBox1{ display: block; width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comPatentBox .ParSeventh .ParSeventhBox1 .ParSeventhImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_36.png)no-repeat; 
                                                           width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSeventh .ParSeventhBox1 .ParSeventhText1{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParSeventh .ParSeventhBox1 .ParSeventhText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParSeventh .ParSeventhBox1 .ParSeventhText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }

.comPatentBox .ParSeventh .ParSeventhBox2{ display: block; width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParSeventh .ParSeventhBox2 .ParSeventhImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_37.png)no-repeat; 
                                                           width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSeventh .ParSeventhBox2 .ParSeventhText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParSeventh .ParSeventhBox2 .ParSeventhText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParSeventh .ParSeventhBox2 .ParSeventhText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }


.comPatentBox .ParEighth{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPatentBox .ParEighth .ParEighthBox1{ display: block; width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comPatentBox .ParEighth .ParEighthBox1 .ParEighthImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_38.png)no-repeat; 
                                                       width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; } 
.comPatentBox .ParEighth .ParEighthBox1 .ParEighthText1{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParEighth .ParEighthBox1 .ParEighthText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParEighth .ParEighthBox1 .ParEighthText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }

.comPatentBox .ParEighth .ParEighthBox2{ display: block; width:252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParEighth .ParEighthBox2 .ParEighthImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_38.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; } 
.comPatentBox .ParEighth .ParEighthBox2 .ParEighthText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParEighth .ParEighthBox2 .ParEighthText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPatentBox .ParEighth .ParEighthBox2 .ParEighthText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }






/* 인증 */
.comCertifiedTItle{  font-family: 'SUIT-Medium'; font-size: 20px; display:block; width: 100%; text-align: center; margin-bottom:68px; color: #333333; }
.comCertifiedBox{ display: block; padding-bottom: 120px; padding:0px 220px; margin-bottom: 80px; }
.comCertifiedBox .CerFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:24px;}
.comCertifiedBox .CerFirst .CerFirstBox{  display: block;  width: 252px;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/GS1.png)no-repeat; background-size: 96%; background-position-x: 5px;
                                                      width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666;  }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText2{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height:18px; color: #666666;  }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText3{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666;  }

.comCertifiedBox .CerFirst .CerFirstBox2{ display: block;  width: 252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_41.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }


.comCertifiedBox .CerSecond{ display: flex; width: 100%; justify-content:center; }
.comCertifiedBox .CerSecond .CerSecondBox1{ display: block;  width: 252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comCertifiedBox .CerSecond .CerSecondBox1 .CerSecondImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_42.png)no-repeat; 
                                                            width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox1 .CerSecondText1{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox1 .CerSecondText2{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox1 .CerSecondText3{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }

.comCertifiedBox .CerSecond .CerSecondBox2{ display: block;  width: 252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_43.png)no-repeat; 
                                                            width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText4{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText5{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText6{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }


.comCertifiedBox .CerThird{ display: flex; width: 100%; justify-content:center; }
.comCertifiedBox .CerThird .CerThirdBox1{ display: block;  width: 252px;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; } 
.comCertifiedBox .CerThird .CerThirdBox1 .CerThirdImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_44.png)no-repeat; 
                                                         width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerThird .CerThirdBox1 .CerThirdText1{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerThird .CerThirdBox1 .CerThirdText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerThird .CerThirdBox1 .CerThirdText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }


.comCertifiedBox .CerThird .CerThirdBox2{  display: block;  width: 252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comCertifiedBox .CerThird .CerThirdBox2 .CerThirdImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_45.png)no-repeat; 
                                                         width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerThird .CerThirdBox2 .CerThirdText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerThird .CerThirdBox2 .CerThirdText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerThird .CerThirdBox2 .CerThirdText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }


.comCertifiedBox .CerFourth{ display: flex; width: 100%; justify-content:center; }
.comCertifiedBox .CerFourth .CerFourthBox1{ display: block;   width: 252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comCertifiedBox .CerFourth .CerFourthBox1 .CerFourthImg1{ display:inline-block; background:url(../../BusinessIntro/image/Patent_46.png)no-repeat; 
                                                           width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFourth .CerFourthBox1 .CerFourthText1{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerFourth .CerFourthBox1 .CerFourthText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerFourth .CerFourthBox1 .CerFourthText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }

.comCertifiedBox .CerFourth .CerFourthBox2{  display: block;   width: 252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comCertifiedBox .CerFourth .CerFourthBox2 .CerFourthImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_47.png)no-repeat; 
                                                           width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFourth .CerFourthBox2 .CerFourthText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerFourth .CerFourthBox2 .CerFourthText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comCertifiedBox .CerFourth .CerFourthBox2 .CerFourthText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }



/* .comCertifiedBox .CerSecond .CerSecondBox4{  display: block; width: 252px; padding: 20px 24px; border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox5{  display: block; width: 252px; padding: 20px 24px; border-radius: 8px; } */



/* 수상 */
.comPrizeTItle{ font-family: 'SUIT-Medium'; font-size: 20px; display:block; width: 100%; text-align: center; margin-bottom:48px; }
.comPrizeBox{ display: block; padding: 0px 220px; }
.comPrizeBox .PrizeFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:12px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox{  display: block;  width: 252px;  padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_52_2.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666;}


.comPrizeBox .PrizeFirst .PrizeFirstBox2{  display: block;  width: 252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_48.png)no-repeat; 
                                                         width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }

.comPrizeBox .PrizeFirst2{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3{  display: block;  width: 252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3 .PrizeFirstImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_49.png)no-repeat; 
                                                           width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3 .PrizeFirstText7{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3 .PrizeFirstText8{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3 .PrizeFirstText9{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666; }


.comPrizeBox .PrizeFirst2 .PrizeFirstBox4{  display: block;   width: 252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox4 .PrizeFirstImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_50.png)no-repeat; 
                                                           width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst2 .PrizeFirstBox4 .PrizeFirstText10{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666;}
.comPrizeBox .PrizeFirst2 .PrizeFirstBox4 .PrizeFirstText11{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666;}
.comPrizeBox .PrizeFirst2 .PrizeFirstBox4 .PrizeFirstText12{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 18px; color: #666666;}


.comPrizeBox .PrizeFirst3 .PrizeFirstBox5{  display: block; width: 252px; padding: 16px 20px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right: 24px; }
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5 .PrizeFirstImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_51.png)no-repeat; 
                                                            width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5 .PrizeFirstText13{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 18px; color: #666666;}
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5 .PrizeFirstText14{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 18px; color: #666666;}
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5 .PrizeFirstText15{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 18px; color: #666666;}


.comPrizeBox .PrizeFirst3 .PrizeFirstBox6{  display: block; width: 252px; padding: 16px 20px; }
.comPrizeBox .PrizeFirst3 .PrizeFirstBox6 .PrizeFirstImg6{ display:inline-block; /* background:url(../../BusinessIntro/image/Patent_48.png)no-repeat; */ 
                                                           width:170px; height:220px; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst3 .PrizeFirstBox6 .PrizeFirstText16{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 18px; }
.comPrizeBox .PrizeFirst3 .PrizeFirstBox6 .PrizeFirstText17{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:center; line-height: 18px; }


.eIntroTitle{ display:block; width:100%; height:280px; text-align:center;  background:url(../../BusinessIntro/image/businessBG33.png)no-repeat; background-size: 120% 60%; }
.eIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 24px; font-weight: 600; padding-top:50px; padding-bottom: 16px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#ffffff; }
.eIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:14px; color: #F9F9F9;
                                   height: 28px; line-height: 28px; }



/* 사업 알아보기 */
.safetyInforContentsSect2{ display:block; width:100%;  padding-top:120px; margin:0 auto; margin-bottom:180px;  } 
.safetyTextBox{ display:block; height:100%; margin:0 auto; }
.safetyManageTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 20px; text-align: center; margin-bottom: 72px; }

.safetyManageContents{ display:block; }
.safetyMText1{ display:block; text-align:center; /* margin-bottom:48px; */ padding:0px 60px; }
.safetyTextConts{ display:block; line-height: 22px; }

.safetyTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; /* margin-bottom:34px; */ }
.safetyTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.safetyTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.safetyTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; /* margin-bottom:34px; */ }
.safetyTexts5{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.safetyTexts6{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.safetyTexts7{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.safetyTexts8{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.safetyTexts9{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }


.businessTextBlack{ font-family:'SUIT-Medium'; font-weight: 500; font-size:14px; color:#000000; }
.businessTextBold2{ font-family:'SUIT-SemiBold'; font-size:14px; font-weight: 600; color: #134A97; }
.businessTextBold3{ font-family:'SUIT-SemiBold'; font-size:14px; font-weight: 600; color: #134A97; }

.safetyTexts1E{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; margin-bottom:34px; }

.safetyMText2{ display:flex; flex-direction: column; padding: 0 50px; }
.safetyTextConts2{ display:block; /* height:320px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; margin-bottom: 12px; }
.safetyTextConts2 .sTarget1{ display:inline-block;  height:36px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 30px; margin-top:34px; line-height:36px; 
                             font-family:'SUIT-Regular'; font-size:14px; color: #4D8DE8;  }
.safetyTextConts2 .sSubFlex1{ display:flex; margin-left: 30px; margin-top: 34px; margin-right:16px; }
.sSubFlex1 .sCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.sSubFlex1 .sSubTitle1{ display:block;  line-height: 20px; }
.sSubFlex1 .sSubTitle1 p{ font-family:'SUIT-Regular'; font-size:14px; font-weight: 400; color: #333333; }
.sSubCont1{ line-height:18px; margin-left:30px; margin-top:20px; margin-bottom: 30px; }
.sSubCont1 p{ display:block; font-family:'SUIT-Regular'; font-size:14px; color: #888888; }

.safetyTextConts3{ display:block; /* height:320px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; margin-bottom: 12px; }
.safetyTextConts3 .sTarget2{ display:inline-block; height:36px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 30px; margin-top:34px; line-height:36px; 
                             font-family:'SUIT-Regular'; font-size:14px; color: #4D8DE8; }
.safetyTextConts3 .sSubFlex2{ display:flex; margin-left: 30px; margin-top: 34px; }
.sSubFlex2 .sCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.sSubFlex2 .sSubTitle2{ display:block;  /* height: 71px; */ line-height: 20px; }
.sSubFlex2 .sSubTitle2 p{ font-family:'SUIT-Regular'; font-size:14px; font-weight: 400; color: #333333;  }
.sSubCont2{ line-height:18px; margin-left:30px; margin-top:20px; margin-bottom: 30px; }
.sSubCont2 p{ display:block; font-family:'SUIT-Regular'; font-size:14px; color: #888888; }


.majorCases{ text-align:center; margin-top:120px;  }
.majorCircle{ display:block; width:12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom:16px; }
.majorTitle{ display:block; font-family:'SUIT-Medium'; font-size:16px; color: #333333; margin-bottom:80px; }
.majorFlexPic{ display:flex; width:100%; justify-content:center; margin-bottom: 36px; }
.majorFlexPic2{ display:flex; width: 100%; justify-content:center; }

.majorFlexPic .case1{ display:block; margin-right: 20px; }
.majorFlexPic .case1 .caseIcon1{ display:block; width:150px; height:150px;  background: url(../../BusinessIntro/image/case1.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px;
                                 background-size:100%; background-position: center; }
.majorFlexPic .case1 .caseText1{ text-align:center; line-height:14px; }
.majorFlexPic .case1 .caseText1 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555;  }

.majorFlexPic .case2{ display:block; }
.majorFlexPic .case2 .caseIcon2{ display:block; width:150px; height:150px; background: url(../../BusinessIntro/image/case2.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                background-position: center; background-size:100%; }
.majorFlexPic .case2 .caseText2{ text-align:center; line-height:14px; }
.majorFlexPic .case2 .caseText2 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555; }

.majorFlexPic .case3{ display:block; margin-right: 20px; }
.majorFlexPic .case3 .caseIcon3{ display:block; width:220px; height:220px; background: url(../../BusinessIntro/image/case3.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexPic .case3 .caseText3{ text-align:center; line-height:30px; }
.majorFlexPic .case3 .caseText3 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555; }



/* .majorFlexPic2 .case3{ display:block; margin-right: 20px; }
.majorFlexPic2 .case3 .caseIcon3{ display:block; width:150px; height:150px; background: url(../../BusinessIntro/image/case3.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexPic2 .case3 .caseText3{ text-align:center; line-height:30px; }
.majorFlexPic2 .case3 .caseText3 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555; }

.majorFlexPic2 .case4{ display:block; }
.majorFlexPic2 .case4 .caseIcon4{ display:block; width:150px; height:150px; margin-bottom:28px; }
.majorFlexPic2 .case4 .caseText4{ text-align:center; line-height:30px; }
.majorFlexPic2 .case4 .caseText4 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555; } */






/* 디지털 트윈 */
.digitalInforContentsSect2{ display:block; width:100%; padding-top: 200px; margin:0 auto; margin-bottom:180px; }
.digitalTextBox{ display:block;  margin:0 auto;  }
.digitalManageTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 20px; text-align: center; margin-bottom: 80px;  }

.digitalManageContents{ display:block; }
.digitalMText1{ display:block; text-align:center; margin-bottom:48px; padding: 0px 60px; }
.digitalTextConts{ display:block; line-height:20px; }
/* .digitalTextConts span{ display:block; font-family: 'SUIT-Regular'; font-size: 22px; line-height:34px; color: #333333; }
.digitalTextConts span:nth-child(1){ margin-bottom:34px; }
.digitalTextConts span:nth-child(3){ margin-bottom:34px; }  */

.digitalTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; /* margin-bottom:34px; */ }
.digitalTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.digitalTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; /* margin-bottom:34px; */ }
.digitalTexts4{ display: inline-block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.digitalTexts5{ display: inline-block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.digitalTexts6{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.digitalTexts7{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.digitalTexts8{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.digitalTexts9{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }
.digitalTexts10{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:14px; color:#333333; }


.digitalMText2{ display:flex; justify-content:center; flex-direction: column; padding:26px; }
    /* .dSubFlex3 .dSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.digitalTextConts2{ display:inline-block;  /* height:290px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-bottom: 12px; }
.digitalTextConts2 .dTarget1{ display:inline-block; height:36px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 30px; margin-top:34px; line-height:36px; 
                             font-family:'SUIT-Regular'; font-size:14px; color: #4D8DE8; }
.digitalTextConts2 .dSubFlex1{ display:flex; margin-left: 30px; margin-top: 34px; margin-right:16px; }
.dSubFlex1 .dCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex1 .dSubTitle1{ display:block; width: 336px;  line-height: 20px; }
.dSubFlex1 .dSubTitle1 p{ font-family:'SUIT-Regular'; font-size:14px; font-weight: 400; color: #333333; }
.dSubCont1{ line-height:20px; margin-left:30px; margin-top:28px; margin-bottom:60px; }
.dSubCont1 p{ display:block; font-family:'SUIT-Regular'; font-size:14px; color: #888888; }
.dSubCont1 p:nth-child(2){ margin-left:14px; }
.dSubCont1 p:nth-child(4){ }

.digitalTextConts3{ display:inline-block; /* height:290px; */  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-bottom: 12px; }
.digitalTextConts3 .dTarget2{ display:inline-block;  height:36px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 30px; margin-top:34px; line-height:36px; 
                             font-family:'SUIT-Regular'; font-size:14px; color:#4D8DE8; }
.digitalTextConts3 .dSubFlex2{ display:flex; margin-left: 30px; margin-top: 34px; }
.dSubFlex2 .dCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex2 .dSubTitle2{ display:block; line-height: 20px; }
.dSubFlex2 .dSubTitle2 p{ font-family:'SUIT-Regular'; font-size:14px; font-weight: 400; color: #333333;  }
.dSubCont2{ line-height:20px; margin-left:30px; margin-top:28px; margin-bottom:60px; }
.dSubCont2 p{ display:block; font-family:'SUIT-Regular'; font-size:14px; color: #888888; }
.dSubCont2 p:nth-child(2){ margin-left:14px; }
.dSubCont2 p:nth-child(3){ margin-left:14px; }


.digitalTextConts4{ display:inline-block; /* height:290px; */  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-bottom: 12px; }
.digitalTextConts4 .dTarget3{ display:inline-block;  height:36px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 30px; margin-top:34px; line-height:36px; 
                             font-family:'SUIT-Regular'; font-size:14px; color: #4D8DE8;  }
.digitalTextConts4 .dSubFlex3{ display:flex; margin-left: 30px; margin-top: 34px; }
.dSubFlex3 .dCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex3 .dSubTitle3{ display:block; width: 336px; line-height: 20px; }
.dSubFlex3 .dSubTitle3 p{ font-family:'SUIT-Regular'; font-size:14px; font-weight: 400; color: #333333;  }
.dSubCont3{ line-height:20px; margin-top:28px;  margin-bottom:60px; margin-left:30px; }
.dSubCont3 p{ display:block; font-family:'SUIT-Regular'; font-size:14px; color: #888888; }
.dSubCont3 p:nth-child(2){ }


.majorCasesD{ text-align:center; margin-top:120px;  }
.majorCircleD{ display:block; width:12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom:16px; }
.majorTitleD{ display:block; font-family:'SUIT-Medium'; font-size:16px; color: #333333; margin-bottom:80px; }
.majorFlexBoxD{ display:flex; width:100%; justify-content:center; margin-bottom: 36px; }
.majorFlexBoxD2{ display:flex; width:100%; justify-content:center; }

.majorFlexBoxD .case3{ display:block; margin-right: 20px; }
.majorFlexBoxD .case3 .caseIcon3{ display:block; width:150px; height:150px; background: url(../../BusinessIntro/image/case4.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexBoxD .case3 .caseText3{ text-align:center; line-height:14px; }
.majorFlexBoxD .case3 .caseText3 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555;  }

.majorFlexBoxD .case4{ display:block; /* margin-right: 10px; */ }
.majorFlexBoxD .case4 .caseIcon4{ display:block; width:150px; height:150px; background: url(../../BusinessIntro/image/case5.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                   background-position: center; background-size:100%; }
.majorFlexBoxD .case4 .caseText4{ text-align:center; line-height:14px; }
.majorFlexBoxD .case4 .caseText4 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555; }


.majorFlexBoxD2 .case5{ display:block; margin-right: 20px; }
.majorFlexBoxD2 .case5 .caseIcon5{ display:block; width:150px; height:150px; background: url(../../BusinessIntro/image/case6.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                   background-position: center; background-size:100%; margin-right: 20px; }
.majorFlexBoxD2 .case5 .caseText5{ text-align:center; line-height:14px; margin-right: 50px; }
.majorFlexBoxD2 .case5 .caseText5 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555;  margin-right: 20px; }

.majorFlexBoxD2 .case6{ display:block; }
.majorFlexBoxD2 .case6 .caseIcon6{ display:block; width:150px; height:150px;  margin-bottom:28px; 
                                   background-position: center; background-size:100%; }
.majorFlexBoxD2 .case6 .caseText6{ text-align:center; line-height:14px; }
.majorFlexBoxD2 .case6 .caseText6 p{ display:block; font-family:'SUIT-Regular'; font-size: 12px; font-weight: 400; color: #555555; }




/* 주요 실적 */
.majorPerformanceContentsS{ display:block; width:100%; padding-top: 200px; margin: 0 auto; /* margin-bottom:180px; */ }
.majorPerformanceTitleS{ display:block;  height:43px; font-family: 'SUIT-SemiBold'; font-size:20px; text-align:center; margin-bottom: 79px; margin:0 auto; margin-bottom: 24px; }
/* .majorPerformanceSelect{ display:block; width:1360px; margin:0 auto; } */

.majorListBox{ display:block; /* margin-top:96px; */ padding: 0px 20px;  }
.majorBox{ display:block; /* height:93px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin:0 auto; padding: 20px 40px;
           margin-bottom:16px; }
.majorBox .majorClass{ display:block; font-family:'SUIT-Medium'; font-size:10px; color: #C0C0C0; margin-bottom:8px;  }
.majorBox .majorFlexBoxM{ /* height:25px; */ }
.majorBox .majorListTitle{ display:inline-block; font-family:'SUIT-Regular'; font-size:13px; line-height: 16px; color: #333333; height:25px; }
.majorFlexBoxY{ display:flex; margin-top: 16px;  }
.majorFlexBoxY .majorListYear{ display:block; width:52px; font-family:'SUIT-Light'; font-size:12px; color: #888888; width:51px; /* margin-right: 32px; */ }
.majorFlexBoxY .majorListOrder{ display:block; font-family:'SUIT-Light'; font-size:12px; color: #888888; /* width:126px; */ width: calc(100% - 120px);  }


/* 파트너사 */
.partnerContents{ display:block; width:100%; padding-top: 200px; margin:0 auto; }
.partnerTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 20px; text-align: center; margin-bottom: 88px; }
.clientBox{ display:block; width:100%; height:480px; text-align:center; background:url(../../CustomerSupport/image/menu_4_image.png)no-repeat;
            margin-bottom:184px; background-size:cover;  }
.clientTitle{ display:block; }
.clientTitle span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.clientTitle span:nth-child(2){ display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8); }

.clientArea{ display:block; width:100%; text-align:center; }
.clientFirst{ display:flex; justify-content:center; margin-bottom:24px; }
.clientSecond{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientThird{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientFourth { display:flex; justify-content:center; margin-bottom:24px; }
.clientFifth{ display:flex; justify-content:center; margin-bottom:24px; }
.clientSixth{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientSeventh{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientEighth { display:flex; justify-content:center; }


.clientContents { display:block; width: 100px; height:100px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:12px; }
.clientContents span:nth-child(1){ display:block; width:100px; height:100px; }

.clientContentsB{ display:block; width: 100px; height:100px; margin-right:12px; }
.clientContentsB span:nth-child(1){ display:block; width:100px; height:100px; }

.clientName{ display:block; width:100px; height:26px; line-height:26px; background: #f0f0f0; font-family: 'SUIT-Regular'; font-size:10px; color: #aaaaaa; border-radius: 0px 0px 6px 6px; }

.clientFirst .clientLogo1{ display:block; background:url(../../BusinessIntro/image/partnerImg1.png)no-repeat; background-position:center; background-size: 80%; }
.clientFirst .clientLogo2{ display:block; background:url(../../BusinessIntro/image/partnerImg2.png)no-repeat; background-position:center; background-size: 80%; }
.clientFirst .clientLogo3{ display:block; background:url(../../BusinessIntro/image/partnerImg3.png)no-repeat; background-position:center; background-size: 80%; }

.clientSecond .clientLogo4{ display:block; background:url(../../BusinessIntro/image/partnerImg4.png)no-repeat; background-position:center; background-size: 80%; }
.clientSecond .clientLogo5{ display:block; background:url(../../BusinessIntro/image/partnerImg5.png)no-repeat; background-position:center; background-size: 80%; }
.clientSecond .clientLogo6{ display:block; background:url(../../BusinessIntro/image/partnerImg6.png)no-repeat; background-position:center; background-size: 80%; }

.clientThird .clientLogo7{ display:block; background:url(../../BusinessIntro/image/partnerImg7.png)no-repeat; background-position:center; background-size: 80%; }
.clientThird .clientLogo8{ display:block; background:url(../../BusinessIntro/image/partnerImg8.png)no-repeat; background-position:center; background-size: 80%; }
.clientThird .clientLogo9{ display:block; background:url(../../BusinessIntro/image/partnerImg9.png)no-repeat; background-position:center; background-size: 80%; }

.clientFourth .clientLogo10{ display:block; background:url(../../BusinessIntro/image/partnerImg10.png)no-repeat; background-position:center; background-size: 80%; }
.clientFourth .clientLogo11{ display:block; background:url(../../BusinessIntro/image/partnerImg11.png)no-repeat; background-position:center; background-size: 80%; }
.clientFourth .clientLogo12{ display:block; background:url(../../BusinessIntro/image/partnerImg12.png)no-repeat; background-position:center; background-size: 80%; }

.clientFifth .clientLogo13{ display:block; background:url(../../BusinessIntro/image/partnerImg13.png)no-repeat; background-position:center; background-size: 80%; }
.clientFifth .clientLogo14{ display:block; background:url(../../BusinessIntro/image/partnerImg14.png)no-repeat; background-position:center; background-size: 80%; }
.clientFifth .clientLogo15{ display:block; background:url(../../BusinessIntro/image/partnerImg15.png)no-repeat; background-position:center; background-size: 80%; }

.clientSixth .clientLogo16{ display:block; background:url(../../BusinessIntro/image/partnerImg16.png)no-repeat; background-position:center; background-size: 80%; }
.clientSixth .clientLogo17{ display:block; background:url(../../BusinessIntro/image/partnerImg17.png)no-repeat; background-position:center; background-size: 80%; }
.clientSixth .clientLogo18{ display:block; background:url(../../BusinessIntro/image/partnerImg18.png)no-repeat; background-position:center; background-size: 80%; }

.clientSeventh .clientLogo19{ display:block; background:url(../../BusinessIntro/image/partnerImg19.png)no-repeat; background-position:center; background-size: 80%; }
.clientSeventh .clientLogo20{ display:block; background:url(../../BusinessIntro/image/partnerImg20.png)no-repeat; background-position:center; background-size: 80%; }
.clientSeventh .clientLogo21{ display:block; background:url(../../BusinessIntro/image/partnerImg21.png)no-repeat; background-position:center; background-size: 80%; }

.clientEighth .clientLogo22{ display:block; background:url(../../BusinessIntro/image/partnerImg22.png)no-repeat; background-position:center; background-size: 80%; }
.clientEighth .clientLogo23{ display:block; background:url(../../BusinessIntro/image/partnerImg23.png)no-repeat; background-position:center; background-size: 80%; }



/* 보도자료 */
.newsTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 20px; text-align: center; padding-top: 200px; }
.reportBox{ display:block; width:100%; height:480px; text-align:center;  background:url(../../CompanyNews/image/menu_3_image.png)no-repeat;
            margin-bottom:180px; background-size:cover;  }
.reportTitle{ display:block; }
.reportTitle span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.reportTitle span:nth-child(2){ display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8); }

.reportArea{ display:flex; width:100%; text-align:center; margin-top:72px; justify-content:center; }

/* .postingTopArea{ display:flex; width:1280px; }
.postingTopArea .postingCount{ display:inline-block; width: 146px; height:24px; line-height:24px; font-family: 'SUIT-Light'; font-size:20px; color:#707070; } 
.postingTopArea .postingCount p{ display:inline-block; font-family: 'SUIT-Medium'; font-size:20px; color:#DD3C25; }

.postingTopArea .postingRightBox{ display:flex; width:432px; }
.postingTopArea .postingSelectBox{ display:inline-block; width:140px; height: 44px; border: 0.5px solid #C0C0C0; border-radius: 24px;
                                   font-family: 'SUIT-Light';  font-size:19px; margin-right:12px; padding-left:24px; background:url('../../CompanyNews/image/다각형 2.png')no-repeat;
                                   background-size: 8px;  background-position-y: 20px; background-position-x: 110px; cursor:pointer; }
.postingTopArea .postingSelectBox .postingSelectOption{ display:inline-block; width:140px; height:44px; border:dashed 1px red; } 

.postingTopArea .postingSearch{ display:flex; width:280px; height:44px; border:0.5px solid #C0C0C0; border-radius: 24px;  }
.postingTopArea .postingInput{ display:inline-block; width:232px; height:40px; border:none; border-radius: 24px; padding-left: 30px; }
.postingTopArea .postingInput:focus{outline:none;}

.postingTopArea .postingSearch .searchIcon{ display: inline-block; width: 23px; height:40px; background:url('../../CompanyNews/image/search_icon.png')no-repeat; text-align: end;
                                            background-position-y:10px; } */

.postingBottomArea{ display:flex; flex-direction:column; flex-wrap: nowrap; /* width:340px; */ /* height:1200px; */ text-align:center; margin:0 auto; margin-bottom:24px; } 
.postingBottomArea2{ display:flex; width:1360px; height:600px; text-align:center; margin:0 auto; margin-bottom:50px; }


.postingBoxArea{ display:block; height:400px; /* overflow:hidden; */ flex-direction:inherit; flex-wrap: nowrap; margin:0 auto; }
/* .postingBoxArea2{ display:block; flex-direction:inherit; width: 1378px; height: 1232px; flex-wrap: nowrap; margin:0 auto; }
.postingBoxAreaBoard{ display:block; flex-direction:inherit; width: 1430px; height: 1232px; flex-wrap: nowrap; margin:0 auto; } */
.postingBox{ display:inline-block; float:left; width: 280px; height: 380px; margin-bottom:32px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06);  border-radius: 8px; }
.postingImg > img{ display:inline-block; width: 280px; height:150px; border-top-left-radius:8px; border-top-right-radius:8px; }
.postingText{ display:block; text-align:left; height:180px; /* overflow:hidden; */ padding:20px 20px; } 
.postingTitle{ display:block; /* width: 372px; */ font-family: 'SUIT-Regular'; font-size:14px; line-height:20px; 
               overflow: hidden; text-overflow: ellipsis; margin-bottom:20px; color:#333333; }
.postingContents{ display:block; /* width:372px; */ height:98px; font-family: 'SUIT-Light'; font-size:12px; line-height:16px; /* overflow: hidden; */ text-overflow: ellipsis; /* margin-bottom:20px; */
                  color: #555555; }
.postingDate{ display:block; /* width:372px; */ height:20px; font-family: 'SUIT-Light'; font-size:10px; line-height:20px; color:#aaaaaa; text-align:right; }


/* .postingBox22{ display:inline-block; float:left; width: 328px; height: 434px; margin-right:16px; margin-bottom:32px; box-shadow: 3px 3px 3px #0000001A; border: 2px solid #F0F0F0; }
.postingImg22{ display:inline-block; width: 328px; height:220px; overflow:hidden; }
.postingImg22 > img{ display:inline-block; width: 328px; height:220px; }
.postingText22{ display:block; padding: 20px; text-align:left; height:214px; overflow:hidden; line-height:26px; color:#505050; } 
.postingTitle22{ display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; height: 60px; font-family: 'SUIT-Regular'; font-size:22px; line-height:32px; 
                 overflow: hidden; text-overflow: ellipsis;  margin-bottom:24px; }
.postingContents22{ display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; height:84px; font-family: 'SUIT-Light'; font-size:16px; line-height:22px; overflow: hidden; text-overflow: ellipsis; margin-bottom:19px; }
.postingDate22{ display:block;  height:20px; font-family: 'SUIT-Light'; font-size:16px; line-height:26px; color:#BBBBBB; } */

.pagination { display: flex; justify-content: center; align-items: center; margin-bottom:240px; }
.pagination a { float: left; width:48px; height:48px; text-decoration: none; }
.arrowLeftFast{ display:inline-block; width:32px; height:32px; background:url('../../CompanyNews/image/arrowLeftFast2.png')no-repeat; background-position: center; cursor:pointer; }
.arrowLeft{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowLeft.png)no-repeat; background-position: center; cursor:pointer; }
.arrowRight{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowRight.png)no-repeat; background-position: center; cursor:pointer; }
.arrowRightFast{ display:inline-block; width:32px; height:32px; background:url('../../CompanyNews/image/arrowRightFast2.png')no-repeat; background-position: center; cursor:pointer; }

.btnActive{ width: 48px; height:48px; background: none; font-family: 'SUIT-Medium'; font-size:12px; color:#999999; }


/* 보도자료 Popup  */
.reportContents{ display:block; width:300px; height:480px; margin:0 auto; position: absolute;
                 top: 50%; left: 50%; transform: translate(-50%, -50%); text-align:left; padding: 20px; }
.reportContentsTop{ display:block; margin-bottom:20px; }
.reportContentsTop .reportContentsTitle{ font-family: 'SUIT-Medium'; font-size:14px; line-height:14px; }

.reportContentsMiddle{ display:block; margin-bottom:12px; }
.reportContentsMiddle .reportImg{ display:block; margin:0 auto; background-position:center; background-size:800px 540px; width:200px; height:120px;
                                  margin-bottom:32px; }
.reportContentsMiddle .reportContsArea{ display:block; height:120px; overflow-y:scroll; overflow-x:hidden; }
.reportContentsMiddle .reportConts{ display:block; font-family: 'SUIT-Regular'; font-size:10px; text-align:left; line-height: 16px; width:300px; }
.reportContentsMiddle .reportConts .reportTextMar{ margin-bottom:25px; }

.reportContentsBottom{ height:36px; }
.reportContentsBottom .reportSources{ display:inline-block; width: 50%; font-family: 'SUIT-Light'; font-size:8px; line-height:20px; height:20px; color:#aaaaaa;
                                      text-decoration: underline; }
.reportContentsBottom .reportDay{ font-family: 'SUIT-Regular'; font-size:8px; color:#aaaaaa; float:right; }

.reportBtnLeft{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/popupBtnLeft.png)no-repeat; transform: translate(-54px, 200px);
                background-size: 28px; }
/* .reportBtnLeftCon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/popupBtnLeft.png)no-repeat; transform: translate(10px, 200px); } */
/* .reportBtnLeft:hover{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/popupBtnLeftHover1.png)no-repeat; transform: translate(-130px, 400px); 
                         background-size: 28px;  }  */
/* .reportBtnLeft:active{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover2.png)no-repeat; transform: translate(-130px, 400px); } */

.reportBtnRight{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/popupBtnRight.png)no-repeat; transform: translate(258px, -208px);
                 background-size: 28px; }
/* .reportBtnRightCon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/popupBtnRight.png)no-repeat; transform: translate(332px, -256px); }  */
/* .reportBtnRight:hover{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/popupBtnLeftHover1.png)no-repeat; transform: translate(794px, -465px) rotate(180deg);
                          background-size: 28px; } */
/* .reportBtnRight:active{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover2.png)no-repeat; transform: translate(-130px, 400px) rotate(180deg); } */


.reportBtnLeftCon{ display:inline-block; width:10px; height:16px; background:url(../../BusinessIntro/image/newsArrowLeft.png)no-repeat; 
                   position:absolute; z-index: 99; background-position: center; left: -30px; top: 180px;  }    
.reportBtnLeftCon:active{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowLeftActive.png)no-repeat; 
                          position:absolute; z-index: 99; background-position: center; left: -50px; top: 164px; /* transform:scale(1.3); */ background-size: 40px;  }

.reportBtnRightCon{ display:inline-block; width:10px; height:16px; background:url(../../BusinessIntro/image/newsArrowRight.png)no-repeat; 
                     position:absolute; z-index: 99; background-position: center; top: 180px; right:-30px; }
.reportBtnRightCon:active{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowLeftActive.png)no-repeat; 
                           position:absolute; z-index: 99; background-position: center; top: 164px; right: -50px; /* transform:scale(1.3); */ background-size: 40px; transform: rotate(180deg);  }


.reportBtnClose{ display:inline-block; width: 28px; height:28px; background:url(../../BusinessIntro/image/popupBtnClose.png)no-repeat; transform: translate(286px, -240px);
                 background-size: 28px; }

.reportArrowTab{ display:block; height:60px; line-height:60px; text-align:center; width:1360px; margin:0 auto; margin-bottom:180px; }
.reportArrowTab .reportArrowAreaL{ display:inline-block; float:left; font-family: 'SUIT-Light'; font-size:19px; letter-spacing: 0px; cursor:pointer; }
.reportArrowTab .reArrowIcon{ display:inline-block; font-family: 'SUIT-Light'; font-size:19px; width: 13px; height:19px; margin-right:20px;
                              background:url('../../CompanyNews/image/reArrowLeft.png')no-repeat; background-position:center; }
.reportArrowTab .reportBtn{ display:inline-block; width:260px; height:60px; line-height:60px; background:#000000; border-radius:30px; color: #fff; 
                            font-family: 'SUIT-Medium'; font-size:26px; text-align:center; }
.reportArrowTab .reportBtn a{ color: #fff; text-decoration:none; }
.reportArrowTab .reportBtn a:hover{ color: #fff;}
.reportArrowTab .reportArrowAreaR{ display:inline-block;  float: right; font-family: 'SUIT-Light'; font-size:19px; letter-spacing: 0px; cursor:pointer; }
.reportArrowTab .reArrowIcon2{ display:inline-block; font-family: 'SUIT-Light'; font-size:19px; width: 13px; height:19px; margin-left:20px;
                               background:url('../../CompanyNews/image/reArrowRight.png')no-repeat; background-position:center; }

.searchBtn{ display:block; width: 150px; height:50px; border:dashed 1px red; }

.uNewsArrowArea{ display:flex; width:80px; height:50px; right: 0; top: -50px; margin-left: 1285px;}
.uNewsArrowL{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_L.png')no-repeat; background-position: center; }
.uNewsArrowL:hover{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_L_hover.png')no-repeat; background-position: center; }
.uNewsArrowR{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_R.png')no-repeat; background-position: center; }
.uNewsArrowR:hover{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_R_hover.png')no-repeat; background-position: center; }


.reportContsArea::-webkit-scrollbar { width: 8px; border-radius: 4px; cursor: pointer;}
.reportContsArea::-webkit-scrollbar-track { cursor: pointer;}
.reportContsArea::-webkit-scrollbar-thumb { border-radius:4px; background-color:#f0f0f0; cursor:pointer;}


}


/* tablet */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/

@media all and (min-width:768px) and (max-width:1023px) {

.arrowTopIcon{ display:inline-block; width:40px; height:38px; background:url('../../components/image/arrow_icon.png')no-repeat; padding:5px; }

.bIntroTitle{ display:block; width:100%; height:280px; text-align:center;  background:url(../../BusinessIntro/image/businessBG22.png)no-repeat;
              background-size: 120% 80%; }
.bIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 40px; font-weight: 600; padding-top:70px; padding-bottom: 16px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#ffffff; }
.bIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:18px; color: #F9F9F9; 
                                   height: 28px; line-height: 28px; }

/* 기술 알아보기 */
.spatialInforContentsSect{ display:block;  padding-top: 180px; margin:0 auto; margin-bottom:180px; } 
.spatialTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 71px; line-height: 44px; }
.spatialTextBoxTop{ display:block; line-height:30px; text-align:center; margin:0 auto; margin-bottom:48px; }
.spatialTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; margin-bottom:34px; }
.spatialTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.spatialTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.businessTextBold{ /* display: inline-block; */ font-family:'SUIT-Medium'; font-weight: 500; font-size:20px; color:#4D8DE8; /* margin-left:8px; */ 
                   margin-left: 4px; margin-right: 4px; }


.spatialFlexBox{ display:flex; }
.spatialImgBox .spatialInforImg{ display:block; /* width:936px; */ height:380px; background:url(../../BusinessIntro/image/디지털트윈_111.png)no-repeat; background-size: 100%;
                                 background-position: top center;  border-radius:8px; }

.spatialTextBox{ display:block; /*  margin-left:24px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.spatialTextBox .spTarget1{ display:block; width: 167px; height:40px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 48px 0px 40px; margin-top:52px; line-height:40px;
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.spatialTextBox .spSubFlex1{ display:flex; margin-left: 38px; margin-top: 52px; }
.spSubFlex1 .spCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.spSubFlex1 .spSubTitle1{ display:block; /*  height: 60px; */ line-height: 30px; }
.spSubFlex1 .spSubTitle1 p{ font-family:'SUIT-Regular'; font-size:18px; font-weight: 400; color: #333333; }  
.spSubFlex1 .spSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.spatialTextBox .spSubFlex2{  display:flex; margin-left: 38px; margin-top: 20px; }
.spSubFlex2 .spCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.spSubFlex2 .spSubTitle2{ display:block;  /* height: 90px; */ line-height: 30px; }
.spSubFlex2 .spSubTitle2 p{ font-family:'SUIT-Regular'; font-size:18px; font-weight: 400; color: #333333; }
.spSubFlex2 .spSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.spatialTextBox .spSubFlex3{  display:flex; margin-left: 38px; margin-top: 20px; }
.spSubFlex3 .spCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.spSubFlex3 .spSubTitle3{ display:block; height: 90px; line-height: 30px; }
.spSubFlex3 .spSubTitle3 p{ font-family:'SUIT-Regular'; font-size:18px; font-weight: 400; color: #333333;  }
.spSubFlex3 .spSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.spatialBottomText{ display:block; height:25px; font-family:'SUIT-Light'; font-size:20px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align: center; }

.eSopContentsSect{ display:block; /* width:1360px; */ padding-top: 200px; margin:0 auto; margin-bottom:180px; } 
.eSopTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 72px; line-height: 44px; }
.eSopTextBoxTop{ display:block; /* width: 763px; */ /* height:136px; */ line-height:30px; text-align:center; margin:0 auto; margin-bottom:48px; }
.eSopTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; margin-bottom:34px; }
/* .eSopTexts1 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8;  margin-left:8px; margin-right:8px; } */
.eSopTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.eSopTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
/* .eSopTexts3 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:8px; margin-right:8px; } */

.eSopFlexBox{ display:flex; }
.eSopImgBox .eSopImg{ display:block; /* width:936px; */  height:380px; background:url(../../BusinessIntro/image/bn_2.png)no-repeat; background-size: 100%;
                      background-position:top center; border-radius:8px; }

.eSopTextBox{ display:block; /* margin-left:24px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.eSopTextBox .eSopTarget1{ display:block; width: 167px; height:40px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 48px 0px 40px; margin-top:52px; line-height:40px;
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.eSopTextBox .eSopSubFlex1{ display:flex; margin-left: 38px; margin-top: 52px; }
.eSopSubFlex1 .eSopCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.eSopSubFlex1 .eSopSubTitle1{ display:block; /* width: 260px; height: 60px; */ line-height: 30px; }
.eSopSubFlex1 .eSopSubTitle1 p{ font-family:'SUIT-Regular'; font-size:18px; font-weight: 400; color: #333333; }  
.eSopSubFlex1 .eSopSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.eSopTextBox .eSopSubFlex2{  display:flex; margin-left: 38px; margin-top: 20px; }
.eSopSubFlex2 .eSopCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.eSopSubFlex2 .eSopSubTitle2{ display:block; /* width: 228px; height: 60px; */ line-height: 30px; }
.eSopSubFlex2 .eSopSubTitle2 p{ font-family:'SUIT-Regular'; font-size:18px; font-weight: 400; color: #333333; }
.eSopSubFlex2 .eSopSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.eSopTextBox .eSopSubFlex3{  display:flex; margin-left: 38px; margin-top: 20px; }
.eSopSubFlex3 .eSopCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.eSopSubFlex3 .eSopSubTitle3{ display:block; /* width: 247px; height: 90px; */ line-height: 30px; }
.eSopSubFlex3 .eSopSubTitle3 p{ font-family:'SUIT-Regular'; font-size:18px; font-weight: 400; color: #333333;  }
.eSopSubFlex3 .eSopSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.eSopBottomText{ display:block; /* width:657px; */  height:25px; font-family:'SUIT-Light'; font-size:20px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align:center; }


.dataVisualContentsSect{ display:block; /* width:1360px; */ padding-top: 200px; margin:0 auto; margin-bottom:180px; } 
.dataTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 71px; line-height: 44px; }
.dataTextBoxTop{ display:block; /* width: 761px; */ /* height:170px; */  line-height:30px; text-align:center; margin:0 auto; margin-bottom:48px; }
.dataTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; margin-bottom:34px; }
/* .dataTexts1 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; } */
.dataTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.dataTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
/* .dataTexts3 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:8px; margin-right:8px; } */
.dataTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }

.dataFlexBox{ display:flex; }
.dataImgBox .dataImg{ display:block; /* width:936px; */ height:380px; background:url(../../BusinessIntro/image/bn_333.png)no-repeat; background-size: 100%;
                      background-position:top center; border-radius:8px; }

.dataTextBox{ display:block;  box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.dataTextBox .dataTarget1{ display:block; width: 167px; height:40px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 48px 0px 40px; margin-top:52px; line-height:40px;
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8;  }
.dataTextBox .dataSubFlex1{ display:flex; margin-left: 38px; margin-top: 52px; }
.dataSubFlex1 .dataCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dataSubFlex1 .dataSubTitle1{ display:block; line-height: 30px; }
.dataSubFlex1 .dataSubTitle1 p{ font-family:'SUIT-Regular'; font-size:18px; font-weight: 400; color: #333333; }  
.dataSubFlex1 .dataSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.dataTextBox .dataSubFlex2{  display:flex; margin-left: 38px; margin-top: 20px; }
.dataSubFlex2 .dataCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dataSubFlex2 .dataSubTitle2{ display:block; line-height: 30px; }
.dataSubFlex2 .dataSubTitle2 p{ font-family:'SUIT-Regular'; font-size:18px; font-weight: 400; color: #333333; }
.dataSubFlex2 .dataSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.dataTextBox .dataSubFlex3{  display:flex; margin-left: 38px; margin-top: 20px; }
.dataSubFlex3 .dataCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dataSubFlex3 .dataSubTitle3{ display:block; line-height: 30px; }
.dataSubFlex3 .dataSubTitle3 p{ font-family:'SUIT-Regular'; font-size:18px; font-weight: 400; color: #333333;  }
.dataSubFlex3 .dataSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.dataBottomText{ display:block; /* width:657px; */  height:25px; font-family:'SUIT-Light'; font-size:20px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align:center; }


.systemLinkContentsSect{ display:block; /* width:1360px; */ padding-top: 200px; margin:0 auto; margin-bottom:180px; }
.systemTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 71px; line-height: 44px; }
.systemTextBoxTop{ display:block; line-height:30px; text-align:center; margin:0 auto; margin-bottom:48px; font-size: 20px; }
.systemTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; margin-bottom:34px; }
.systemTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.systemTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.systemTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }


.systemFlexBox{ display:flex; justify-content:center;}
.systemImgBox .systemImg{ display:block; /* width:936px; */ height:380px; background:url(../../BusinessIntro/image/bn_444.png)no-repeat; background-size: 100%;
                          background-position:top center; border-radius:8px; }

.systemTextBox{ display:block; /* margin-left:24px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.systemTextBox .systemTarget1{ display:block; width: 167px; height:40px; background: #F4F8FD; border-top-right-radius:20px;
                               border-bottom-right-radius:20px; padding:0px 48px 0px 40px; margin-top:52px; line-height:40px;
                               font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.systemTextBox .systemSubFlex1{ display:flex; margin-left: 38px; margin-top: 52px; }
.systemSubFlex1 .systemCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.systemSubFlex1 .systemSubTitle1{ display:block; /* width: 202px; height: 90px; */ line-height: 30px;}
.systemSubFlex1 .systemSubTitle1 p{ font-family:'SUIT-Regular'; font-size:18px; font-weight: 400; color: #333333; }  
.systemSubFlex1 .systemSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.systemTextBox .systemSubFlex2{  display:flex; margin-left: 38px; margin-top: 20px; }
.systemSubFlex2 .systemCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.systemSubFlex2 .systemSubTitle2{ display:block; /* width: 205px; height: 60px; */ line-height: 30px; }
.systemSubFlex2 .systemSubTitle2 p{ font-family:'SUIT-Regular'; font-size:18px; font-weight: 400; color: #333333; }
.systemSubFlex2 .systemSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.systemTextBox .systemSubFlex3{  display:flex; margin-left: 38px; margin-top: 20px; }
.systemSubFlex3 .systemCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.systemSubFlex3 .systemSubTitle3{ display:block; /* width: 247px; height: 90px; */ line-height: 30px; }
.systemSubFlex3 .systemSubTitle3 p{ font-family:'SUIT-Regular'; font-size:18px; font-weight: 400; color: #333333;  }
.systemSubFlex3 .systemSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.systemBottomText{ display:block; /* width:657px; */  height:25px; font-family:'SUIT-Light'; font-size:20px; color: #555555; 
                   margin: 0 auto; margin-top: 28px; text-align:center; }




/* 특허 */
.comPatBox{ display:block; width:100%; height:480px; text-align:center; padding:50px; background:url(../../CompanyIntro/image/menu_1_image.png)no-repeat;
            margin-bottom:176px; background-size:cover; }
.comPatConTItle2 { font-family: 'SUIT-SemiBold'; font-size: 34px;  display:block; width: 100%; text-align: center; margin-bottom:88px; padding-top:200px; }
.comPatCircle{ display:block; width: 12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom: 16px; }

/* .comPatTitle{ display:block; } 
.comPatTitle > span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.comPatTitle > span:nth-child(2){  display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8);  } */
.comPatContent{ display:block; /* margin-bottom:260px; */ }
.comPatConTItle{ font-family: 'SUIT-Medium'; font-size: 24px; display: block; width: 100%;  text-align: center; margin-bottom: 48px; }

.comPatentBox{ display: block; padding-bottom: 120px; }
.comPatentBox .ParFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPatentBox .ParFirst .ParFirstBox1{ display: block; width: 252px;  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_24.png)no-repeat; 
                                                    width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParFirst .ParFirstBox2{ display: block; width: 252px;  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_25.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; } 


.comPatentBox .ParFirst .ParFirstBox3{ display: block; width: 252px;  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFirst .ParFirstBox3 .ParFirstImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_26.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox3 .ParFirstText7{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox3 .ParFirstText8{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox3 .ParFirstText9{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParFirst .ParFirstBox4{ display: block; width: 252px;  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.comPatentBox .ParFirst .ParFirstBox4 .ParFirstImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_27.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox4 .ParFirstText10 {display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox4 .ParFirstText11{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox4 .ParFirstText12{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParFirst .ParFirstBox5{ display: block; width: 252px;  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParFirst .ParFirstBox5 .ParFirstImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_28.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox5 .ParFirstText13 {display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox5 .ParFirstText14{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox5 .ParFirstText15{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParSecond{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPatentBox .ParSecond .ParSecondBox1{ display: block; width: 252px;  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_29.png)no-repeat; 
                                                       width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParSecond .ParSecondBox2{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_30.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParSecond .ParSecondBox3{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParSecond .ParSecondBox3 .ParSecondImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_31.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox3 .ParSecondText7{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox3 .ParSecondText8{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox3 .ParSecondText9{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParSecond .ParSecondBox4{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParSecond .ParSecondBox4 .ParSecondImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_32.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox4 .ParSecondText10{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox4 .ParSecondText11{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox4 .ParSecondText12{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParSecond .ParSecondBox5{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.comPatentBox .ParSecond .ParSecondBox5 .ParSecondImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_33.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox5 .ParSecondText13{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox5 .ParSecondText14{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox5 .ParSecondText15{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParThird{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPatentBox .ParThird .ParThirdBox1{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_34.png)no-repeat; 
                                                    width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParThird .ParThirdBox2{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_35.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParThird .ParThirdBox3{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParThird .ParThirdBox3 .ParThirdImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_36.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox3 .ParThirdText7{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox3 .ParThirdText8{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox3 .ParThirdText9{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParThird .ParThirdBox4{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParThird .ParThirdBox4 .ParThirdImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_37.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox4 .ParThirdText10{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox4 .ParThirdText11{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox4 .ParThirdText12{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParThird .ParThirdBox5{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParThird .ParThirdBox5 .ParThirdImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_38.png)no-repeat; 
                                                     width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; } 
.comPatentBox .ParThird .ParThirdBox5 .ParThirdText13{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox5 .ParThirdText14{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox5 .ParThirdText15{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParFourth{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPatentBox .ParFourth .ParFourthBox1{ display: block; width: 252px; /* height:372px;  padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_39.png)no-repeat; 
                                                       width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParFourth .ParFourthBox2{ display: block; width: 252px; /* height:372px; padding: 20px 24px; */  }
.comPatentBox .ParFourth .ParFourthBox3{ display: block; width: 252px; /* height:372px; padding: 20px 24px; */ margin-right:24px; }
.comPatentBox .ParFourth .ParFourthBox4{ display: block; width: 252px; /* height:372px; padding: 20px 24px; */  }
.comPatentBox .ParFourth .ParFourthBox5{ display: block; width: 252px; /* height:372px; padding: 20px 24px; */ }




/* 인증 */
.comCertifiedTItle{  font-family: 'SUIT-Medium'; font-size: 24px; display:block; width: 100%; text-align: center; margin-bottom:68px; color: #333333; }
.comCertifiedBox{ display: block;  padding-bottom: 120px; }
.comCertifiedBox .CerFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:24px;}
.comCertifiedBox .CerFirst .CerFirstBox{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/GS1.png)no-repeat; background-size: 96%; background-position-x: 5px;
                                                      width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px; /* background-size: cover; */ border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666;  }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText2{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666;  }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText3{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666;  }

.comCertifiedBox .CerFirst .CerFirstBox2{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_41.png)no-repeat; 
                                                         width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comCertifiedBox .CerFirst .CerFirstBox3{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerFirst .CerFirstBox3 .CerFirstImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_42.png)no-repeat; 
                                                         width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox3 .CerFirstText7{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox3 .CerFirstText8{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox3 .CerFirstText9{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }

.comCertifiedBox .CerFirst .CerFirstBox4{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comCertifiedBox .CerFirst .CerFirstBox4 .CerFirstImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_43.png)no-repeat; 
                                                         width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox4 .CerFirstText10{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox4 .CerFirstText11{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox4 .CerFirstText12{  display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comCertifiedBox .CerFirst .CerFirstBox5{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comCertifiedBox .CerFirst .CerFirstBox5 .CerFirstImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_44.png)no-repeat; 
                                                         width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox5 .CerFirstText13{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox5 .CerFirstText14{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox5 .CerFirstText15{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comCertifiedBox .CerSecond{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comCertifiedBox .CerSecond .CerSecondBox{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox .CerSecondImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_45.png)no-repeat; 
                                                         width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox .CerSecondText{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox .CerSecondText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox .CerSecondText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }

.comCertifiedBox .CerSecond .CerSecondBox2{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_46.png)no-repeat; 
                                                           width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }

.comCertifiedBox .CerSecond .CerSecondBox3{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox3 .CerSecondImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_47.png)no-repeat; 
                                                           width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox3 .CerSecondText7{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox3 .CerSecondText8{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox3 .CerSecondText9{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }

.comCertifiedBox .CerSecond .CerSecondBox4{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox5{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */  border-radius: 8px; }



/* 수상 */
.comPrizeTItle{ font-family: 'SUIT-Medium'; font-size: 24px; display:block; width: 100%; text-align: center; margin-bottom:48px; }
.comPrizeBox{ display: block; }
.comPrizeBox .PrizeFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_52_2.png)no-repeat; 
                                                        width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText2{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText3{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666;}


.comPrizeBox .PrizeFirst .PrizeFirstBox2{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_48.png)no-repeat; 
                                                         width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comPrizeBox .PrizeFirst2{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3 .PrizeFirstImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_49.png)no-repeat; 
                                                         width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3 .PrizeFirstText7{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3 .PrizeFirstText8{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox3 .PrizeFirstText9{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666; }


.comPrizeBox .PrizeFirst2 .PrizeFirstBox4{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPrizeBox .PrizeFirst2 .PrizeFirstBox4 .PrizeFirstImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_50.png)no-repeat; 
                                                          width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst2 .PrizeFirstBox4 .PrizeFirstText10{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst2 .PrizeFirstBox4 .PrizeFirstText11{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst2 .PrizeFirstBox4 .PrizeFirstText12{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666;}


.comPrizeBox .PrizeFirst3{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ margin-right:24px; }
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5 .PrizeFirstImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_51.png)no-repeat; 
                                                          width:170px; height:220px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5 .PrizeFirstText13{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5 .PrizeFirstText14{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst3 .PrizeFirstBox5 .PrizeFirstText15{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; color: #666666;}


.comPrizeBox .PrizeFirst3 .PrizeFirstBox6{  display: block; width: 252px; }
.comPrizeBox .PrizeFirst3 .PrizeFirstBox6 .PrizeFirstImg6{ display:inline-block; /* background:url(../../BusinessIntro/image/Patent_48.png)no-repeat; */ 
                                                           width:170px; height:220px; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst3 .PrizeFirstBox6 .PrizeFirstText16{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; }
.comPrizeBox .PrizeFirst3 .PrizeFirstBox6 .PrizeFirstText17{ display:block; font-family: 'SUIT-Regular'; font-size:14px; text-align:center; line-height: 22px; }



.eIntroTitle{ display:block; width:100%; height:280px; text-align:center;  background:url(../../BusinessIntro/image/businessBG33.png)no-repeat; /* background-size:cover; */
              background-size: 120% 80%; }
.eIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 40px; font-weight: 600; padding-top:70px; padding-bottom: 16px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#ffffff; }
.eIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:18px; color: #F9F9F9;
                                   height: 28px; line-height: 28px; }


/* 사업 알아보기 */
.safetyInforContentsSect2{ display:block; width:100%; /* height:980px; */ padding-top: 240px; margin:0 auto; margin-bottom:180px;  } 
.safetyTextBox{ display:block; /* width: 1400px; */  height:100%;  margin:0 auto; }
.safetyManageTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 72px; }

.safetyManageContents{ display:block; }
.safetyMText1{ display:block; text-align:center; margin-bottom:48px; }
.safetyTextConts{ display:block; line-height: 30px; }
/* .safetyTextConts span{ display:inline-block; font-family: 'SUIT-Regular'; font-size: 22px; line-height:34px; color: #333333; } */
/* .safetyTextConts span:nth-child(1){ margin-bottom:34px; }
.safetyTextConts span:nth-child(3){ margin-bottom:34px; } */

/* .safetyTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; margin-bottom:34px; }
.safetyTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; margin-bottom:34px; }
.safetyTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.safetyTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; margin-bottom:34px; }
.safetyTexts5{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.safetyTexts6{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.safetyTexts7{  font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; } */

.safetyTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.safetyTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; margin-bottom:34px; }
.safetyTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.safetyTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.safetyTexts5{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.safetyTexts6{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; margin-bottom:34px; }
.safetyTexts7{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.safetyTexts8{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.safetyTexts9{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }

.safetyTexts1E{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; margin-bottom: 26px; }

.businessTextBlack{ font-family:'SUIT-Medium'; font-weight: 500; font-size:20px; color:#000000; margin-left:4px; margin-right: 4px; }
.businessTextBold2{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; /* margin-left:8px; */ }
.businessTextBold3{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.safetyMText2{ display:flex; }
.safetyTextConts2{ display:block; /* width: 668px; */ height:438px; /* margin-right:24px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.safetyTextConts2 .sTarget1{ display:inline-block; /* width: 165px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8;  }
.safetyTextConts2 .sSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; margin-right:16px; }
.sSubFlex1 .sCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.sSubFlex1 .sSubTitle1{ display:block; /* width: 336px; */ height: 71px; line-height: 38px; }
.sSubFlex1 .sSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
/* .sSubFlex1 .sSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.sSubCont1{ line-height:26px; margin-left:44px; margin-top:20px;  }
.sSubCont1 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; }

.safetyTextConts3{ display:block; /* width: 668px; */ height:438px; box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.safetyTextConts3 .sTarget2{ display:inline-block; /* width: 165px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.safetyTextConts3 .sSubFlex2{ display:flex; margin-left: 44px; margin-top: 52px; }
.sSubFlex2 .sCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.sSubFlex2 .sSubTitle2{ display:block; /* width: 336px; */ height: 71px; line-height: 38px; }
.sSubFlex2 .sSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
/* .sSubFlex2 .sSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.sSubCont2{ line-height:26px; margin-left:44px; margin-top:20px;  }
.sSubCont2 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; }


.majorCases{ text-align:center; margin-top:120px;  }
.majorCircle{ display:block; width:12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom:16px; }
.majorTitle{ display:block; font-family:'SUIT-Medium'; font-size:24px; color: #333333; margin-bottom:80px; }
.majorFlexPic{ display:flex; width:100%; justify-content:center; }

.majorFlexPic .case1{ display:block; width: 220px; height:368px; margin-right: 50px; }
.majorFlexPic .case1 .caseIcon1{ display:block; width:220px; height:220px; background: url(../../BusinessIntro/image/case1.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px;
                                 background-size:100%; background-position: center; }
.majorFlexPic .case1 .caseText1{ text-align:center; line-height:30px; }
.majorFlexPic .case1 .caseText1 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555;  }

.majorFlexPic .case2{ display:block; width: 220px; height:368px; margin-right: 50px; }
.majorFlexPic .case2 .caseIcon2{ display:block; width:220px; height:220px; background: url(../../BusinessIntro/image/case2.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                 background-position: center; background-size:100%; }
.majorFlexPic .case2 .caseText2{ text-align:center; line-height:30px; }
.majorFlexPic .case2 .caseText2 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555; }


.majorFlexPic .case3{ display:block; width: 220px; height:368px; /* margin-right: 50px; */ }
.majorFlexPic .case3 .caseIcon2{ display:block; width:220px; height:220px; background: url(../../BusinessIntro/image/case3.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                 background-position: center; background-size:100%; }
.majorFlexPic .case3 .caseText3{ text-align:center; line-height:30px; }
.majorFlexPic .case3 .caseText3 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555; }



/* 디지털 트윈 */
.digitalInforContentsSect2{ display:block; width:100%; padding-top: 200px; margin:0 auto; margin-bottom:180px; }
.digitalTextBox{ display:block; /* width: 1400px; */ margin:0 auto;  }
.digitalManageTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 80px;  }

.digitalManageContents{ display:block; }
.digitalMText1{ display:block; text-align:center; margin-bottom:48px; }
.digitalTextConts{ display:block; line-height:30px; }
/* .digitalTextConts span{ display:block; font-family: 'SUIT-Regular'; font-size: 22px; line-height:34px; color: #333333; }
.digitalTextConts span:nth-child(1){ margin-bottom:34px; }
.digitalTextConts span:nth-child(3){ margin-bottom:34px; }  */

.digitalTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; /* margin-bottom:34px; */ }
.digitalTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; margin-bottom:34px; }
.digitalTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; /* margin-bottom:34px; */ }
.digitalTexts4{ display: inline-block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.digitalTexts5{ display: inline-block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.digitalTexts6{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; margin-bottom:34px;  }
.digitalTexts7{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.digitalTexts8{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.digitalTexts9{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }
.digitalTexts10{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:20px; color:#333333; }


.digitalMText2{ display:flex; justify-content:center; padding: 0px 50px; }
.digitalTextConts2{ display:inline-block; /* margin-right:24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.digitalTextConts2 .dTarget1{ display:inline-block; /* width: 170px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.digitalTextConts2 .dSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; margin-right:16px; /* height:109px; */ }
.dSubFlex1 .dCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex1 .dSubTitle1{ display:block; width: 336px; /* height: 71px; */ line-height: 30px; }
.dSubFlex1 .dSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
/* .dSubFlex1 .dSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.dSubCont1{ line-height:26px; margin-left:44px; margin-top:28px; margin-bottom:60px; }
.dSubCont1 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; /* margin-left:14px; */ }
.dSubCont1 p:nth-child(2){ margin-left:14px; }
.dSubCont1 p:nth-child(4){ /* margin-left:14px; */ }

.digitalTextConts3{ display:inline-block; /* margin-right:24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.digitalTextConts3 .dTarget2{ display:inline-block; /* width: 170px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color:#4D8DE8; }
.digitalTextConts3 .dSubFlex2{ display:flex; margin-left: 44px; margin-top: 52px;  /* height:109px; */ /* margin-bottom: 18px; */  }
.dSubFlex2 .dCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex2 .dSubTitle2{ display:block; /* width: 336px; */ /* height: 71px; */ line-height: 30px; }
.dSubFlex2 .dSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
/* .dSubFlex2 .dSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.dSubCont2{ line-height:26px; margin-left:44px; margin-top:28px; margin-bottom:60px; }
.dSubCont2 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; }
.dSubCont2 p:nth-child(2){ margin-left:14px; }
.dSubCont2 p:nth-child(3){ margin-left:14px; }


.digitalTextConts4{ display:inline-block;  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.digitalTextConts4 .dTarget3{ display:inline-block; /* width: 170px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8;  }
.digitalTextConts4 .dSubFlex3{ display:flex; margin-left: 44px; margin-top: 52px; /* height:109px; */  }
.dSubFlex3 .dCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex3 .dSubTitle3{ display:block; width: 336px; /* height: 71px; */ line-height: 30px; }
.dSubFlex3 .dSubTitle3 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
/* .dSubFlex3 .dSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.dSubCont3{ line-height:26px; margin-left:44px; margin-top:28px;  margin-bottom:60px; margin-left:44px; }
.dSubCont3 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; }
.dSubCont3 p:nth-child(2){ /* margin-left:24px; */ }


.majorCasesD{ text-align:center; margin-top:120px;  }
.majorCircleD{ display:block; width:12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom:16px; }
.majorTitleD{ display:block; font-family:'SUIT-Medium'; font-size:24px; color: #333333; margin-bottom:80px; }
.majorFlexBoxD{ display:flex; width:100%; justify-content:center; }

.majorFlexBoxD .case3{ display:block; width: 220px; height:368px; margin-right: 50px; }
.majorFlexBoxD .case3 .caseIcon3{ display:block; width:220px; height:220px; background: url(../../BusinessIntro/image/case4.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexBoxD .case3 .caseText3{ text-align:center; line-height:30px; }
.majorFlexBoxD .case3 .caseText3 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555;  }

.majorFlexBoxD .case4{ display:block; width: 220px; height:368px; margin-right: 50px; }
.majorFlexBoxD .case4 .caseIcon4{ display:block; width:220px; height:220px; background: url(../../BusinessIntro/image/case5.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexBoxD .case4 .caseText4{ text-align:center; line-height:30px; }
.majorFlexBoxD .case4 .caseText4 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555; }


.majorFlexBoxD .case5{ display:block; width: 220px; height:368px; }
.majorFlexBoxD .case5 .caseIcon5{ display:block; width:220px; height:220px; background: url(../../BusinessIntro/image/case6.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexBoxD .case5 .caseText5{ text-align:center; line-height:30px; }
.majorFlexBoxD .case5 .caseText5 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555; }



/* 주요 실적 */
.majorPerformanceContentsS{ display:block; width:100%; padding-top: 200px; margin: 0 auto; margin-bottom:180px; }
.majorPerformanceTitleS{ display:block; /* width:1360px; */ height:43px; font-family: 'SUIT-SemiBold'; font-size:34px; text-align:center; margin-bottom: 79px; margin:0 auto; margin-bottom: 24px; }
.majorPerformanceSelect{ display:block; width:1360px; margin:0 auto; }

.majorListBox{ display:block; margin-top:96px; }
.majorBox{ display:block; /* width: 1360px; */ height:93px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin:0 auto; padding: 20px 60px;
           margin-bottom:16px; }
.majorBox .majorClass{ display:block; font-family:'SUIT-Medium'; font-size:16px; color: #C0C0C0; margin-bottom:8px; width: 138px; }
.majorBox .majorFlexBoxM{ /* display:flex; */ height:25px; }
.majorBox .majorListTitle{ display:inline-block;  width:870px; font-family:'SUIT-Regular'; font-size:16px; color: #333333; width:713px; height:25px; }
.majorBox .majorListYear{ display:block; width:80px; font-family:'SUIT-Light'; font-size:16px; color: #888888; /* margin-left: 315px; */  float:right; margin-right: 32px; }
.majorBox .majorListOrder{ display:block; font-family:'SUIT-Light'; font-size:16px; color: #888888; /* width:126px; */  width: calc(100% - 40px); /* margin-left:33px; */ float:right; }


/* 파트너사 */
.partnerContents{ display:block; width:100%; padding-top: 200px; margin:0 auto; margin-bottom:180px; }
.partnerTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 88px; }
.clientBox{ display:block; width:100%; height:480px; text-align:center; background:url(../../CustomerSupport/image/menu_4_image.png)no-repeat;
            margin-bottom:184px; background-size:cover;  }
.clientTitle{ display:block; }
.clientTitle span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.clientTitle span:nth-child(2){ display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8); }

.clientArea{ display:block; width:100%; text-align:center; }
.clientFirst{ display:flex; justify-content:center; margin-bottom:24px; }
.clientSecond{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientThird{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientFourth { display:flex; justify-content:center; }

.clientContents { display:block; width: 204px; height:204px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.clientContents span:nth-child(1){ display:block; width:204px; height:164px; }

.clientContentsR { display:block; width: 204px; height:204px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.clientContentsR span:nth-child(1){ display:block; width:204px; height:164px; }

.clientContentsB{ display:block; width: 204px; height:204px; margin-right:24px; }
.clientContentsB span:nth-child(1){ display:block; width:256px; height:208px; }

.clientName{ display:block; width:204px; height:40px; line-height:20px; background: #f0f0f0; font-family: 'SUIT-Regular'; font-size:16px; color: #aaaaaa;
             padding-top:10px; }

.clientFirst .clientLogo1{ display:block; background:url(../../BusinessIntro/image/partnerImg1.png)no-repeat; background-position:center; }
.clientFirst .clientLogo2{ display:block; background:url(../../BusinessIntro/image/partnerImg2.png)no-repeat; background-position:center; }
.clientFirst .clientLogo3{ display:block; background:url(../../BusinessIntro/image/partnerImg3.png)no-repeat; background-position:center; }
.clientFirst .clientLogo4{ display:block; background:url(../../BusinessIntro/image/partnerImg4.png)no-repeat; background-position:center; }
.clientFirst .clientLogo5{ display:block; background:url(../../BusinessIntro/image/partnerImg5.png)no-repeat; background-position:center; }
.clientFirst .clientLogo6{ display:block; background:url(../../BusinessIntro/image/partnerImg6.png)no-repeat; background-position:center; }

.clientSecond .clientLogo7{ display:block; background:url(../../BusinessIntro/image/partnerImg7.png)no-repeat; background-position:center; }
.clientSecond .clientLogo8{ display:block; background:url(../../BusinessIntro/image/partnerImg8.png)no-repeat; background-position:center; }
.clientSecond .clientLogo9{ display:block; background:url(../../BusinessIntro/image/partnerImg9.png)no-repeat; background-position:center; }
.clientSecond .clientLogo10{ display:block; background:url(../../BusinessIntro/image/partnerImg10.png)no-repeat; background-position:center; }
.clientSecond .clientLogo11{ display:block; background:url(../../BusinessIntro/image/partnerImg11.png)no-repeat; background-position:center; }
.clientSecond .clientLogo12{ display:block; background:url(../../BusinessIntro/image/partnerImg12.png)no-repeat; background-position:center; }

.clientThird .clientLogo13{ display:block; background:url(../../BusinessIntro/image/partnerImg13.png)no-repeat; background-position:center; }
.clientThird .clientLogo14{ display:block; background:url(../../BusinessIntro/image/partnerImg14.png)no-repeat; background-position:center; }
.clientThird .clientLogo15{ display:block; background:url(../../BusinessIntro/image/partnerImg15.png)no-repeat; background-position:center; }
.clientThird .clientLogo16{ display:block; background:url(../../BusinessIntro/image/partnerImg16.png)no-repeat; background-position:center; }
.clientThird .clientLogo17{ display:block; background:url(../../BusinessIntro/image/partnerImg17.png)no-repeat; background-position:center; }
.clientThird .clientLogo18{ display:block; background:url(../../BusinessIntro/image/partnerImg18.png)no-repeat; background-position:center; }

.clientFourth .clientLogo19{ display:block; background:url(../../BusinessIntro/image/partnerImg19.png)no-repeat; background-position:center; }
.clientFourth .clientLogo20{ display:block; background:url(../../BusinessIntro/image/partnerImg20.png)no-repeat; background-position:center; }
.clientFourth .clientLogo21{ display:block; background:url(../../BusinessIntro/image/partnerImg21.png)no-repeat; background-position:center; }
.clientFourth .clientLogo22{ display:block; background:url(../../BusinessIntro/image/partnerImg22.png)no-repeat; background-position:center; }
.clientFourth .clientLogo23{ display:block; background:url(../../BusinessIntro/image/partnerImg23.png)no-repeat; background-position:center; }



/* 보도자료 */
.newsTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; padding-top: 200px; }
.reportBox{ display:block; width:100%; height:480px; text-align:center;  background:url(../../CompanyNews/image/menu_3_image.png)no-repeat;
            margin-bottom:180px; background-size:cover;  }
.reportTitle{ display:block; }
.reportTitle span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.reportTitle span:nth-child(2){ display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8); }

.reportArea{ display:flex; width:100%; text-align:center; margin-top:72px; justify-content:center; }

/* .postingTopArea{ display:flex; width:1280px; }
.postingTopArea .postingCount{ display:inline-block; width: 146px; height:24px; line-height:24px; font-family: 'SUIT-Light'; font-size:20px; color:#707070; } 
.postingTopArea .postingCount p{ display:inline-block; font-family: 'SUIT-Medium'; font-size:20px; color:#DD3C25; }

.postingTopArea .postingRightBox{ display:flex; width:432px; }
.postingTopArea .postingSelectBox{ display:inline-block; width:140px; height: 44px; border: 0.5px solid #C0C0C0; border-radius: 24px;
                                   font-family: 'SUIT-Light';  font-size:19px; margin-right:12px; padding-left:24px; background:url('../../CompanyNews/image/다각형 2.png')no-repeat;
                                   background-size: 8px;  background-position-y: 20px; background-position-x: 110px; cursor:pointer; }
.postingTopArea .postingSelectBox .postingSelectOption{ display:inline-block; width:140px; height:44px; border:dashed 1px red; } 

.postingTopArea .postingSearch{ display:flex; width:280px; height:44px; border:0.5px solid #C0C0C0; border-radius: 24px;  }
.postingTopArea .postingInput{ display:inline-block; width:232px; height:40px; border:none; border-radius: 24px; padding-left: 30px; }
.postingTopArea .postingInput:focus{outline:none;}

.postingTopArea .postingSearch .searchIcon{ display: inline-block; width: 23px; height:40px; background:url('../../CompanyNews/image/search_icon.png')no-repeat; text-align: end;
                                            background-position-y:10px; } */

.postingBottomArea{ display:flex; flex-wrap: nowrap; /* width:860px; */ /* height:1208px; */ text-align:center; margin:0 auto; margin-bottom:24px; } 
.postingBottomArea2{ display:flex; width:1360px; height:600px; text-align:center; margin:0 auto; margin-bottom:50px; }


/* .postingSlide{ width: 1378px; height: 1232px; position: relative; overflow: hidden; } */
.postingBoxArea{ display:block; flex-direction:inherit; width: 800px; /* height: 1208px; */ height: 820px; flex-wrap: nowrap; margin:0 auto; }
/* .postingBoxArea2{ display:block; flex-direction:inherit; width: 1378px; height: 1232px; flex-wrap: nowrap; margin:0 auto; }
.postingBoxAreaBoard{ display:block; flex-direction:inherit; width: 1430px; height: 1232px; flex-wrap: nowrap; margin:0 auto; } */
.postingBox{ display:inline-block; float:left; width: 240px; height: 360px; margin-right:24px; margin-bottom:60px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06);
             border-radius: 8px; }
.postingImg > img{ display:inline-block; width: 240px; height:180px; border-top-left-radius:8px; border-top-right-radius:8px; }
.postingText{ display:block; text-align:left; height:170px; overflow:hidden; padding:20px 32px; } 
.postingTitle{ display:block; /* width: 372px; */ height: 42px; font-family: 'SUIT-Regular'; font-size:18px; line-height:22px; 
               overflow: hidden; text-overflow: ellipsis; margin-bottom:20px; color:#333333; }
.postingContents{ display:block; /* width:372px; */ height:70px; font-family: 'SUIT-Light'; font-size:14px; line-height:18px; overflow: hidden; text-overflow: ellipsis; margin-bottom:20px;
                  color: #555555; }
.postingDate{ display:block; width:372px; height:20px; font-family: 'SUIT-Light'; font-size:16px; line-height:20px; color:#aaaaaa; text-align:right; }


/* .postingBox22{ display:inline-block; float:left; width: 328px; height: 434px; margin-right:16px; margin-bottom:32px; box-shadow: 3px 3px 3px #0000001A; border: 2px solid #F0F0F0; }
.postingImg22{ display:inline-block; width: 328px; height:220px; overflow:hidden; }
.postingImg22 > img{ display:inline-block; width: 328px; height:220px; }
.postingText22{ display:block; padding: 20px; text-align:left; height:214px; overflow:hidden; line-height:26px; color:#505050; } 
.postingTitle22{ display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; height: 60px; font-family: 'SUIT-Regular'; font-size:22px; line-height:32px; 
                 overflow: hidden; text-overflow: ellipsis;  margin-bottom:24px; }
.postingContents22{ display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; height:84px; font-family: 'SUIT-Light'; font-size:16px; line-height:22px; overflow: hidden; text-overflow: ellipsis; margin-bottom:19px; }
.postingDate22{ display:block;  height:20px; font-family: 'SUIT-Light'; font-size:16px; line-height:26px; color:#BBBBBB; } */

.pagination { display: flex; justify-content: center; align-items: center; margin-bottom:240px; }
.pagination a { float: left; width:48px; height:48px; text-decoration: none; }
.arrowLeftFast{ display:inline-block; width:32px; height:32px; background:url('../../CompanyNews/image/arrowLeftFast2.png')no-repeat; background-position: center; cursor:pointer; }
.arrowLeft{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowLeft.png)no-repeat; background-position: center; cursor:pointer; }
.arrowRight{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowRight.png)no-repeat; background-position: center; cursor:pointer; }
.arrowRightFast{ display:inline-block; width:32px; height:32px; background:url('../../CompanyNews/image/arrowRightFast2.png')no-repeat; background-position: center; cursor:pointer; }

.btnActive{ width: 48px; height:48px; background: none; font-family: 'SUIT-Medium'; font-size:18px; color:#999999; }


/* 보도자료 Popup  */
.reportContents{ display:block; width:680px; height:844px; margin:0 auto; position: absolute;
                 top: 44%; left: 50%; transform: translate(-50%, -50%); text-align:left; }
.reportContentsTop{ display:block; /* width:760px; */ height:76px; margin-bottom:32px; }
.reportContentsTop .reportContentsTitle{ font-family: 'SUIT-Medium'; font-size:24px; line-height:38px; }

.reportContentsMiddle{ display:block; margin-bottom:32px; }
.reportContentsMiddle .reportImg{ display:block; margin:0 auto; background-position:center; background-size:800px 540px; width:436px; height:308px;
                                  margin-bottom:32px; }
.reportContentsMiddle .reportContsArea{ display:block; /* width:760px; */ height:300px; overflow-y:scroll; }
.reportContentsMiddle .reportConts{ display:block; font-family: 'SUIT-Regular'; font-size:18px; text-align:left; line-height: 30px; width:728px; }
.reportContentsMiddle .reportConts .reportTextMar{ margin-bottom:25px; }

.reportContentsBottom{ /* display:flex; */ width: 760px; height:36px; }
.reportContentsBottom .reportSources{ display:inline-block; font-family: 'SUIT-Light'; font-size:16px; line-height:20px; height:20px; color:#aaaaaa;
                                      text-decoration: underline; margin-right: 217px; }
.reportContentsBottom .reportDay{ font-family: 'SUIT-Regular'; font-size:16px; color:#aaaaaa; float:right; }

.reportBtnLeft{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeft.png)no-repeat; transform: translate(-80px, 400px); }
.reportBtnLeft:hover{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover1.png)no-repeat; transform: translate(-130px, 400px); }
/* .reportBtnLeft:active{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover2.png)no-repeat; transform: translate(-130px, 400px); } */

.reportBtnRight{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnRight.png)no-repeat; transform: translate(620px, -465px); }
.reportBtnRight:hover{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover1.png)no-repeat; transform: translate(794px, -465px) rotate(180deg); }
/* .reportBtnRight:active{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover2.png)no-repeat; transform: translate(-130px, 400px) rotate(180deg); } */

.reportBtnClose{ display:inline-block; width: 48px; height:48px; background:url(../../BusinessIntro/image/popupBtnClose.png)no-repeat; transform: translate(670px, -530px); }

.reportArrowTab{ display:block; height:60px; line-height:60px; text-align:center; width:1360px; margin:0 auto; margin-bottom:180px; }
.reportArrowTab .reportArrowAreaL{ display:inline-block; float:left; font-family: 'SUIT-Light'; font-size:19px; letter-spacing: 0px; cursor:pointer; }
.reportArrowTab .reArrowIcon{ display:inline-block; font-family: 'SUIT-Light'; font-size:19px; width: 13px; height:19px; margin-right:20px;
                              background:url('../../CompanyNews/image/reArrowLeft.png')no-repeat; background-position:center; }
.reportArrowTab .reportBtn{ display:inline-block; width:260px; height:60px; line-height:60px; background:#000000; border-radius:30px; color: #fff; 
                            font-family: 'SUIT-Medium'; font-size:26px; text-align:center; }
.reportArrowTab .reportBtn a{ color: #fff; text-decoration:none; }
.reportArrowTab .reportBtn a:hover{ color: #fff;}
.reportArrowTab .reportArrowAreaR{ display:inline-block;  float: right; font-family: 'SUIT-Light'; font-size:19px; letter-spacing: 0px; cursor:pointer; }
.reportArrowTab .reArrowIcon2{ display:inline-block; font-family: 'SUIT-Light'; font-size:19px; width: 13px; height:19px; margin-left:20px;
                               background:url('../../CompanyNews/image/reArrowRight.png')no-repeat; background-position:center; }

.searchBtn{ display:block; width: 150px; height:50px; border:dashed 1px red; }

.uNewsArrowArea{ display:flex; width:80px; height:50px; right: 0; top: -50px; margin-left: 1285px;}
.uNewsArrowL{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_L.png')no-repeat; background-position: center; }
.uNewsArrowL:hover{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_L_hover.png')no-repeat; background-position: center; }
.uNewsArrowR{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_R.png')no-repeat; background-position: center; }
.uNewsArrowR:hover{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_R_hover.png')no-repeat; background-position: center; }


.reportContsArea::-webkit-scrollbar { width: 8px; border-radius: 4px; cursor: pointer;}
.reportContsArea::-webkit-scrollbar-track { cursor: pointer;}
.reportContsArea::-webkit-scrollbar-thumb { border-radius:4px; background-color:#f0f0f0; cursor:pointer;}



}


/* 가로 tablet */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/


@media all and (min-width: 960px) and (max-width: 1280px) {

.arrowTopIcon{ display:inline-block; width:40px; height:38px; background:url('../../components/image/arrow_icon.png')no-repeat; padding:5px; }

.bIntroTitle{ display:block; width:100%; height:280px; text-align:center;  background:url(../../BusinessIntro/image/businessBG22.png)no-repeat; background-size:cover; }
.bIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 52px; font-weight: 600; padding-top:85.5px; padding-bottom: 16px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#ffffff; }
.bIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:22px; color: #F9F9F9; 
                                   height: 28px; line-height: 28px; }

/* 기술 알아보기 */
.spatialInforContentsSect{ display:block; /* width:1360px; */ padding-top: 240px; margin:0 auto; margin-bottom:180px; } 
.spatialTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 71px; line-height: 44px; }
.spatialTextBoxTop{ display:block; /* width: 749px; */ /* height:136px; */ line-height:34px; text-align:center; margin:0 auto; margin-bottom:48px; }
.spatialTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
/* .spatialTexts1 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:8px; } */
.spatialTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.spatialTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
/* .spatialTexts3 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8;  margin-left:8px; margin-right:8px; } */
.businessTextBold{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:4px; /* margin-right: 8px; */ }


.spatialFlexBox{ display:flex; }
.spatialImgBox .spatialInforImg{ display:block; width:936px; height:520px; background:url(../../BusinessIntro/image/디지털트윈_111.png)no-repeat; background-size: cover;
                                 border-radius:8px; }

.spatialTextBox{ display:block; width: 400px; height:520px; margin-left:24px; box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.spatialTextBox .spTarget1{ display:block; width: 167px; height:40px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 48px 0px 40px; margin-top:52px; line-height:40px;
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.spatialTextBox .spSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; }
.spSubFlex1 .spCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.spSubFlex1 .spSubTitle1{ display:block;  height: 60px; line-height: 30px; }
.spSubFlex1 .spSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }  
.spSubFlex1 .spSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.spatialTextBox .spSubFlex2{  display:flex; margin-left: 44px; margin-top: 28px; }
.spSubFlex2 .spCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.spSubFlex2 .spSubTitle2{ display:block;  /* height: 90px; */ line-height: 30px; }
.spSubFlex2 .spSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
.spSubFlex2 .spSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.spatialTextBox .spSubFlex3{  display:flex; margin-left: 44px; margin-top: 28px; }
.spSubFlex3 .spCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.spSubFlex3 .spSubTitle3{ display:block; height: 90px; line-height: 30px; }
.spSubFlex3 .spSubTitle3 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
.spSubFlex3 .spSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.spatialBottomText{ display:block; height:25px; font-family:'SUIT-Light'; font-size:20px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align: center; }

.eSopContentsSect{ display:block; /* width:1360px; */ padding-top: 200px; margin:0 auto; margin-bottom:180px; } 
.eSopTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 72px; line-height: 44px; }
.eSopTextBoxTop{ display:block; /* width: 763px; */ /* height:136px; */ line-height:34px; text-align:center; margin:0 auto; margin-bottom:48px; }
.eSopTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
/* .eSopTexts1 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8;  margin-left:8px; margin-right:8px; } */
.eSopTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.eSopTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
/* .eSopTexts3 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:8px; margin-right:8px; } */

.eSopFlexBox{ display:flex; }
.eSopImgBox .eSopImg{ display:block; width:936px; height:520px; background:url(../../BusinessIntro/image/bn_2.png)no-repeat; background-size: cover;
                      border-radius:8px; }

.eSopTextBox{ display:block; width: 400px; height:520px; margin-left:24px; box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.eSopTextBox .eSopTarget1{ display:block; width: 167px; height:40px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 48px 0px 40px; margin-top:52px; line-height:40px;
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.eSopTextBox .eSopSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; }
.eSopSubFlex1 .eSopCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.eSopSubFlex1 .eSopSubTitle1{ display:block; /* width: 260px; height: 60px; */ line-height: 30px; }
.eSopSubFlex1 .eSopSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }  
.eSopSubFlex1 .eSopSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.eSopTextBox .eSopSubFlex2{  display:flex; margin-left: 44px; margin-top: 28px; }
.eSopSubFlex2 .eSopCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.eSopSubFlex2 .eSopSubTitle2{ display:block; /* width: 228px; height: 60px; */ line-height: 30px; }
.eSopSubFlex2 .eSopSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
.eSopSubFlex2 .eSopSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.eSopTextBox .eSopSubFlex3{  display:flex; margin-left: 44px; margin-top: 28px; }
.eSopSubFlex3 .eSopCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.eSopSubFlex3 .eSopSubTitle3{ display:block; /* width: 247px; height: 90px; */ line-height: 30px; }
.eSopSubFlex3 .eSopSubTitle3 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
.eSopSubFlex3 .eSopSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.eSopBottomText{ display:block; /* width:657px; */  height:25px; font-family:'SUIT-Light'; font-size:20px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align:center; }


.dataVisualContentsSect{ display:block; /* width:1360px; */ padding-top: 200px; margin:0 auto; margin-bottom:180px; } 
.dataTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 71px; line-height: 44px; }
.dataTextBoxTop{ display:block; /* width: 761px; */ height:170px; line-height:34px; text-align:center; margin:0 auto; margin-bottom:48px; }
.dataTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
/* .dataTexts1 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; } */
.dataTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.dataTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
/* .dataTexts3 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:8px; margin-right:8px; } */
.dataTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }

.dataFlexBox{ display:flex; }
.dataImgBox .dataImg{ display:block; width:936px; height:520px; background:url(../../BusinessIntro/image/bn_333.png)no-repeat; background-size: cover;
                                 border-radius:8px; }

.dataTextBox{ display:block; width: 400px; height:520px; margin-left:24px; box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.dataTextBox .dataTarget1{ display:block; width: 167px; height:40px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 48px 0px 40px; margin-top:52px; line-height:40px;
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8;  }
.dataTextBox .dataSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; }
.dataSubFlex1 .dataCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dataSubFlex1 .dataSubTitle1{ display:block; /* width: 237px; height: 60px; */ line-height: 30px; }
.dataSubFlex1 .dataSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }  
.dataSubFlex1 .dataSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.dataTextBox .dataSubFlex2{  display:flex; margin-left: 44px; margin-top: 28px; }
.dataSubFlex2 .dataCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dataSubFlex2 .dataSubTitle2{ display:block; /* width: 264px; height: 60px; */ line-height: 30px; }
.dataSubFlex2 .dataSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
.dataSubFlex2 .dataSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.dataTextBox .dataSubFlex3{  display:flex; margin-left: 44px; margin-top: 28px; }
.dataSubFlex3 .dataCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dataSubFlex3 .dataSubTitle3{ display:block; /* width: 268px; height: 60px; */ line-height: 30px; }
.dataSubFlex3 .dataSubTitle3 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
.dataSubFlex3 .dataSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.dataBottomText{ display:block; /* width:657px; */  height:25px; font-family:'SUIT-Light'; font-size:20px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align:center; }


.systemLinkContentsSect{ display:block; /* width:1360px; */ padding-top: 200px; margin:0 auto; margin-bottom:180px; }
.systemTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 71px; line-height: 44px; }
.systemTextBoxTop{ display:block; /* width: 857px; */ /* height:136px; */ line-height:34px; text-align:center; margin:0 auto; margin-bottom:48px; }
.systemTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
/* .systemTexts1 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:8px; margin-right:8px; } */
.systemTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.systemTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
/* .systemTexts3 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; } */
.systemTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }


.systemFlexBox{ display:flex; justify-content:center;}
.systemImgBox .systemImg{ display:block; width:936px; height:520px; background:url(../../BusinessIntro/image/bn_444.png)no-repeat; background-size: cover;
                                 border-radius:8px; }

.systemTextBox{ display:block; width: 400px; height:520px; margin-left:24px; box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.systemTextBox .systemTarget1{ display:block; width: 167px; height:40px; background: #F4F8FD; border-top-right-radius:20px;
                               border-bottom-right-radius:20px; padding:0px 48px 0px 40px; margin-top:52px; line-height:40px;
                               font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.systemTextBox .systemSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; }
.systemSubFlex1 .systemCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.systemSubFlex1 .systemSubTitle1{ display:block; /* width: 202px; height: 90px; */ line-height: 30px;}
.systemSubFlex1 .systemSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }  
.systemSubFlex1 .systemSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.systemTextBox .systemSubFlex2{  display:flex; margin-left: 44px; margin-top: 28px; }
.systemSubFlex2 .systemCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.systemSubFlex2 .systemSubTitle2{ display:block; /* width: 205px; height: 60px; */ line-height: 30px; }
.systemSubFlex2 .systemSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
.systemSubFlex2 .systemSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.systemTextBox .systemSubFlex3{  display:flex; margin-left: 44px; margin-top: 28px; }
.systemSubFlex3 .systemCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.systemSubFlex3 .systemSubTitle3{ display:block; /* width: 247px; height: 90px; */ line-height: 30px; }
.systemSubFlex3 .systemSubTitle3 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
.systemSubFlex3 .systemSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.systemBottomText{ display:block; /* width:657px; */  height:25px; font-family:'SUIT-Light'; font-size:20px; color: #555555; 
                   margin: 0 auto; margin-top: 28px; text-align:center; }




/* 특허 */
.comPatBox{ display:block; width:100%; height:480px; text-align:center; padding:50px; background:url(../../CompanyIntro/image/menu_1_image.png)no-repeat;
            margin-bottom:176px; background-size:cover; }
.comPatConTItle2 { font-family: 'SUIT-SemiBold'; font-size: 34px;  display:block; width: 100%; text-align: center; margin-bottom:88px; padding-top:200px; }
.comPatCircle{ display:block; width: 12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom: 16px; }

/* .comPatTitle{ display:block; } 
.comPatTitle > span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.comPatTitle > span:nth-child(2){  display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8);  } */
.comPatContent{ display:block; margin-bottom:260px; }
.comPatConTItle{ font-family: 'SUIT-Medium'; font-size: 24px; display: block; width: 100%;  text-align: center; margin-bottom: 48px; }

.comPatentBox{ display: block; padding-bottom: 120px; }
.comPatentBox .ParFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPatentBox .ParFirst .ParFirstBox1{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_24.png)no-repeat; 
                                                    width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText2{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText3{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParFirst .ParFirstBox2{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_25.png)no-repeat; 
                                                     width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; } 


.comPatentBox .ParFirst .ParFirstBox3{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFirst .ParFirstBox3 .ParFirstImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_26.png)no-repeat; 
                                                     width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox3 .ParFirstText7{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox3 .ParFirstText8{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox3 .ParFirstText9{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParFirst .ParFirstBox4{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFirst .ParFirstBox4 .ParFirstImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_27.png)no-repeat; 
                                                     width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox4 .ParFirstText10 {display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox4 .ParFirstText11{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox4 .ParFirstText12{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParFirst .ParFirstBox5{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParFirst .ParFirstBox5 .ParFirstImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_28.png)no-repeat; 
                                                     width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox5 .ParFirstText13 {display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox5 .ParFirstText14{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox5 .ParFirstText15{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParSecond{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPatentBox .ParSecond .ParSecondBox1{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_29.png)no-repeat; 
                                                       width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText2{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText3{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParSecond .ParSecondBox2{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_30.png)no-repeat; 
                                                        width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText4{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText5{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText6{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParSecond .ParSecondBox3{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParSecond .ParSecondBox3 .ParSecondImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_31.png)no-repeat; 
                                                        width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox3 .ParSecondText7{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox3 .ParSecondText8{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox3 .ParSecondText9{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParSecond .ParSecondBox4{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParSecond .ParSecondBox4 .ParSecondImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_32.png)no-repeat; 
                                                        width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox4 .ParSecondText10{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox4 .ParSecondText11{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox4 .ParSecondText12{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParSecond .ParSecondBox5{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.comPatentBox .ParSecond .ParSecondBox5 .ParSecondImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_33.png)no-repeat; 
                                                        width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox5 .ParSecondText13{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox5 .ParSecondText14{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox5 .ParSecondText15{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParThird{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPatentBox .ParThird .ParThirdBox1{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_34.png)no-repeat; 
                                                    width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText2{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText3{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParThird .ParThirdBox2{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_35.png)no-repeat; 
                                                     width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText4{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText5{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText6{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParThird .ParThirdBox3{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParThird .ParThirdBox3 .ParThirdImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_36.png)no-repeat; 
                                                     width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox3 .ParThirdText7{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox3 .ParThirdText8{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox3 .ParThirdText9{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParThird .ParThirdBox4{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParThird .ParThirdBox4 .ParThirdImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_37.png)no-repeat; 
                                                     width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox4 .ParThirdText10{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox4 .ParThirdText11{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox4 .ParThirdText12{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParThird .ParThirdBox5{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParThird .ParThirdBox5 .ParThirdImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_38.png)no-repeat; 
                                                     width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; } 
.comPatentBox .ParThird .ParThirdBox5 .ParThirdText13{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox5 .ParThirdText14{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox5 .ParThirdText15{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParFourth{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPatentBox .ParFourth .ParFourthBox1{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_39.png)no-repeat; 
                                                       width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText2{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText3{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParFourth .ParFourthBox2{ display: block; width: 252px; height:372px; /* padding: 20px 24px; */ margin-right:24px; }
.comPatentBox .ParFourth .ParFourthBox3{ display: block; width: 252px; height:372px; /* padding: 20px 24px; */ margin-right:24px; }
.comPatentBox .ParFourth .ParFourthBox4{ display: block; width: 252px; height:372px; /* padding: 20px 24px; */ margin-right:24px; }
.comPatentBox .ParFourth .ParFourthBox5{ display: block; width: 252px; height:372px; /* padding: 20px 24px; */  }




/* 인증 */
.comCertifiedTItle{  font-family: 'SUIT-Medium'; font-size: 24px; display:block; width: 100%; text-align: center; margin-bottom:68px; color: #333333; }
.comCertifiedBox{ display: block;  padding-bottom: 120px; }
.comCertifiedBox .CerFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:24px;}
.comCertifiedBox .CerFirst .CerFirstBox{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/GS1.png)no-repeat; background-size: 96%; background-position-x: 5px;
                                                      width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px; /* background-size: cover; */ border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText{  display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666;  }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText2{  display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666;  }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText3{  display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666;  }

.comCertifiedBox .CerFirst .CerFirstBox2{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_41.png)no-repeat; 
                                                         width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comCertifiedBox .CerFirst .CerFirstBox3{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerFirst .CerFirstBox3 .CerFirstImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_42.png)no-repeat; 
                                                         width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox3 .CerFirstText7{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox3 .CerFirstText8{  display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox3 .CerFirstText9{  display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }

.comCertifiedBox .CerFirst .CerFirstBox4{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerFirst .CerFirstBox4 .CerFirstImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_43.png)no-repeat; 
                                                         width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox4 .CerFirstText10{  display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox4 .CerFirstText11{  display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox4 .CerFirstText12{  display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comCertifiedBox .CerFirst .CerFirstBox5{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comCertifiedBox .CerFirst .CerFirstBox5 .CerFirstImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_44.png)no-repeat; 
                                                         width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox5 .CerFirstText13{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox5 .CerFirstText14{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox5 .CerFirstText15{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comCertifiedBox .CerSecond{ display: flex; width: 100%; justify-content:center; }
.comCertifiedBox .CerSecond .CerSecondBox{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox .CerSecondImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_45.png)no-repeat; 
                                                         width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox .CerSecondText{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox .CerSecondText2{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox .CerSecondText3{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }

.comCertifiedBox .CerSecond .CerSecondBox2{ display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */  box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_46.png)no-repeat; 
                                                           width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText4{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText5{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText6{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }

.comCertifiedBox .CerSecond .CerSecondBox3{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox3 .CerSecondImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_47.png)no-repeat; 
                                                           width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox3 .CerSecondText7{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox3 .CerSecondText8{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox3 .CerSecondText9{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }

.comCertifiedBox .CerSecond .CerSecondBox4{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox5{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ border-radius: 8px; }



/* 수상 */
.comPrizeTItle{ font-family: 'SUIT-Medium'; font-size: 24px; display:block; width: 100%; text-align: center; margin-bottom:48px; }
.comPrizeBox{ display: block; }
.comPrizeBox .PrizeFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_52_2.png)no-repeat; 
                                                        width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText2{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText3{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666;}


.comPrizeBox .PrizeFirst .PrizeFirstBox2{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_48.png)no-repeat; 
                                                         width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comPrizeBox .PrizeFirst .PrizeFirstBox3{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox3 .PrizeFirstImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_49.png)no-repeat; 
                                                         width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox3 .PrizeFirstText7{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox3 .PrizeFirstText8{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox3 .PrizeFirstText9{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666; }


.comPrizeBox .PrizeFirst .PrizeFirstBox4{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox4 .PrizeFirstImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_50.png)no-repeat; 
                                                          width:204px; height:272px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox4 .PrizeFirstText10{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox4 .PrizeFirstText11{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox4 .PrizeFirstText12{ display:block; font-family: 'SUIT-Regular'; font-size:16px; text-align:center; line-height: 22px; color: #666666;}

.comPrizeBox .PrizeFirst .PrizeFirstBox5{  display: block; width: 252px; /* height:372px; */ /* padding: 20px 24px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox5 .PrizeFirstImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_51.png)no-repeat; 
                                                          width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox5 .PrizeFirstText13{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox5 .PrizeFirstText14{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox5 .PrizeFirstText15{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;}


/* .comPrizeBox .PrizeFirst .PrizeFirstBox6{  display: block; width: 252px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox6 .PrizeFirstImg6{ display:inline-block; 
                                                          width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox6 .PrizeFirstText16{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox6 .PrizeFirstText17{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox6 .PrizeFirstText18{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; } */




.eIntroTitle{ display:block; width:100%; height:280px; text-align:center;  background:url(../../BusinessIntro/image/businessBG33.png)no-repeat; background-size:cover; }
.eIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 52px; font-weight: 600; padding-top:85.5px; padding-bottom: 16px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#ffffff; }
.eIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:22px; color: #F9F9F9;
                                   height: 28px; line-height: 28px; }


/* 사업 알아보기 */
.safetyInforContentsSect2{ display:block; width:100%; /* height:980px; */ padding-top: 240px; margin:0 auto; margin-bottom:180px;  } 
.safetyTextBox{ display:block; /* width: 1400px; */ height:100%;  margin:0 auto; }
.safetyManageTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 72px; }

.safetyManageContents{ display:block; }
.safetyMText1{ display:block; text-align:center; margin-bottom:48px; }
.safetyTextConts{ display:block; line-height: 34px; }
/* .safetyTextConts span{ display:inline-block; font-family: 'SUIT-Regular'; font-size: 22px; line-height:34px; color: #333333; } */
/* .safetyTextConts span:nth-child(1){ margin-bottom:34px; }
.safetyTextConts span:nth-child(3){ margin-bottom:34px; } */

.safetyTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
.safetyTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.safetyTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.safetyTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
.safetyTexts5{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.safetyTexts6{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.safetyTexts7{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.safetyTexts8{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.safetyTexts9{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }

.businessTextBlack{ font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#000000; /* margin-left:8px; */ /* margin-right:8px; */ }
.businessTextBold2{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; /* margin-left:8px; */ }
.businessTextBold3{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.safetyMText2{ display:flex; }
.safetyTextConts2{ display:block; width: 668px; /* height:438px; */ margin-right:24px; box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.safetyTextConts2 .sTarget1{ display:inline-block; /* width: 165px; */ /* height:40px; */ background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8;  }
.safetyTextConts2 .sSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; margin-right:16px; }
.sSubFlex1 .sCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.sSubFlex1 .sSubTitle1{ display:block; /* width: 336px; */ /* height: 71px; */ line-height: 38px; }
.sSubFlex1 .sSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
/* .sSubFlex1 .sSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.sSubCont1{ line-height:26px; margin-left:44px; margin-top:20px;  }
.sSubCont1 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; }

.safetyTextConts3{ display:block; width: 668px; /* height:438px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.safetyTextConts3 .sTarget2{ display:inline-block; /* width: 165px; */ /* height:40px; */ background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.safetyTextConts3 .sSubFlex2{ display:flex; margin-left: 44px; margin-top: 52px; }
.sSubFlex2 .sCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.sSubFlex2 .sSubTitle2{ display:block; /* width: 336px; */ /* height: 71px; */ line-height: 38px; }
.sSubFlex2 .sSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
/* .sSubFlex2 .sSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.sSubCont2{ line-height:26px; margin-left:44px; margin-top:20px;  }
.sSubCont2 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; }


.majorCases{ text-align:center; margin-top:120px;  }
.majorCircle{ display:block; width:12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom:16px; }
.majorTitle{ display:block; font-family:'SUIT-Medium'; font-size:24px; color: #333333; margin-bottom:80px; }
.majorFlexPic{ display:flex; width:100%; justify-content:center; }

.majorFlexPic .case1{ display:block; width: 240px; height:368px; margin-right: 120px; }
.majorFlexPic .case1 .caseIcon1{ display:block; width:240px; height:240px; background: url(../../BusinessIntro/image/case1.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px;
                                 background-size:100%; background-position: center; }
.majorFlexPic .case1 .caseText1{ text-align:center; line-height:30px; }
.majorFlexPic .case1 .caseText1 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555;  }

.majorFlexPic .case2{ display:block; width: 240px; height:368px; margin-right: 120px; }
.majorFlexPic .case2 .caseIcon2{ display:block; width:240px; height:240px; background: url(../../BusinessIntro/image/case2.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                 background-position: center; background-size:100%; }
.majorFlexPic .case2 .caseText2{ text-align:center; line-height:30px; }
.majorFlexPic .case2 .caseText2 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555; }


.majorFlexPic .case3{ display:block; width: 240px; height:368px; }
.majorFlexPic .case3 .caseIcon2{ display:block; width:240px; height:240px; background: url(../../BusinessIntro/image/case3.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                 background-position: center; background-size:100%; }
.majorFlexPic .case3 .caseText3{ text-align:center; line-height:30px; }
.majorFlexPic .case3 .caseText3 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555; }



/* 디지털 트윈 */
.digitalInforContentsSect2{ display:block; width:100%; padding-top: 200px; margin:0 auto; margin-bottom:180px; }
.digitalTextBox{ display:block; /* width: 1400px; */ margin:0 auto;  }
.digitalManageTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 80px;  }

.digitalManageContents{ display:block; }
.digitalMText1{ display:block; text-align:center; margin-bottom:48px; }
.digitalTextConts{ display:block; line-height:34px; }
/* .digitalTextConts span{ display:block; font-family: 'SUIT-Regular'; font-size: 22px; line-height:34px; color: #333333; }
.digitalTextConts span:nth-child(1){ margin-bottom:34px; }
.digitalTextConts span:nth-child(3){ margin-bottom:34px; }  */

.digitalTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
.digitalTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
.digitalTexts4{ display: inline-block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts5{ display: inline-block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts6{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts7{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts8{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts9{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts10{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }


.digitalMText2{ display:flex; justify-content:center; }
.digitalTextConts2{ display:inline-block; width: 436px; /* height:429px; */ margin-right:24px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.digitalTextConts2 .dTarget1{ display:inline-block; /* width: 170px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.digitalTextConts2 .dSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; margin-right:16px; /* height:109px; */ }
.dSubFlex1 .dCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex1 .dSubTitle1{ display:block; width: 336px; /* height: 71px; */ line-height: 30px; }
.dSubFlex1 .dSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
/* .dSubFlex1 .dSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.dSubCont1{ line-height:26px; margin-left:44px; margin-top:28px; margin-bottom:60px; }
.dSubCont1 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; /* margin-left:14px; */ }
.dSubCont1 p:nth-child(2){ margin-left:14px; }
.dSubCont1 p:nth-child(4){ /* margin-left:14px; */ }

.digitalTextConts3{ display:inline-block; width: 436px; /* height:429px; */ margin-right:24px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.digitalTextConts3 .dTarget2{ display:inline-block; /* width: 170px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color:#4D8DE8; }
.digitalTextConts3 .dSubFlex2{ display:flex; margin-left: 44px; margin-top: 52px;  /* height:109px; */ /* margin-bottom: 18px; */  }
.dSubFlex2 .dCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex2 .dSubTitle2{ display:block; /* width: 336px; */ /* height: 71px; */ line-height: 30px; }
.dSubFlex2 .dSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
/* .dSubFlex2 .dSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.dSubCont2{ line-height:26px; margin-left:44px; margin-top:28px; margin-bottom:60px; }
.dSubCont2 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; }
.dSubCont2 p:nth-child(2){ margin-left:14px; }
.dSubCont2 p:nth-child(3){ margin-left:14px; }


.digitalTextConts4{ display:inline-block; width: 436px; /* height:429px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.digitalTextConts4 .dTarget3{ display:inline-block; /* width: 170px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8;  }
.digitalTextConts4 .dSubFlex3{ display:flex; margin-left: 44px; margin-top: 52px; /* height:109px; */  }
.dSubFlex3 .dCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex3 .dSubTitle3{ display:block; width: 336px; /* height: 71px; */ line-height: 30px; }
.dSubFlex3 .dSubTitle3 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
/* .dSubFlex3 .dSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.dSubCont3{ line-height:26px; margin-left:44px; margin-top:28px;  margin-bottom:60px; margin-left:44px; }
.dSubCont3 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; }
.dSubCont3 p:nth-child(2){ /* margin-left:24px; */ }


.majorCasesD{ text-align:center; margin-top:120px;  }
.majorCircleD{ display:block; width:12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom:16px; }
.majorTitleD{ display:block; font-family:'SUIT-Medium'; font-size:24px; color: #333333; margin-bottom:80px; }
.majorFlexBoxD{ display:flex; width:100%; justify-content:center; }

.majorFlexBoxD .case3{ display:block; width: 280px; height:368px; margin-right: 120px; }
.majorFlexBoxD .case3 .caseIcon3{ display:block; width:280px; height:280px; background: url(../../BusinessIntro/image/case4.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexBoxD .case3 .caseText3{ text-align:center; line-height:30px; }
.majorFlexBoxD .case3 .caseText3 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555;  }

.majorFlexBoxD .case4{ display:block; width: 280px; height:368px; margin-right: 120px; }
.majorFlexBoxD .case4 .caseIcon4{ display:block; width:280px; height:280px; background: url(../../BusinessIntro/image/case5.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexBoxD .case4 .caseText4{ text-align:center; line-height:30px; }
.majorFlexBoxD .case4 .caseText4 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555; }

.majorFlexBoxD .case5{ display:block; width: 280px; height:368px; }
.majorFlexBoxD .case5 .caseIcon5{ display:block; width:280px; height:280px; background: url(../../BusinessIntro/image/case6.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexBoxD .case5 .caseText5{ text-align:center; line-height:30px; }
.majorFlexBoxD .case5 .caseText5 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555; }



/* 주요 실적 */
.majorPerformanceContentsS{ display:block; width:100%; padding-top: 200px; margin: 0 auto; margin-bottom:180px; }
.majorPerformanceTitleS{ display:block; /* width:1360px; */ height:43px; font-family: 'SUIT-SemiBold'; font-size:34px; text-align:center; margin-bottom: 79px; margin:0 auto; margin-bottom: 24px; }
.majorPerformanceSelect{ display:block; width:1360px; margin:0 auto; }

.majorListBox{ display:block; margin-top:96px; }
.majorBox{ display:block; width: 1360px; /* height:93px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin:0 auto; padding: 20px 60px;
           margin-bottom:16px; }
.majorBox .majorClass{ display:block; font-family:'SUIT-Medium'; font-size:16px; color: #C0C0C0; margin-bottom:8px;  }
.majorBox .majorFlexBoxM{ /* display:flex; */ /* height:25px; */ }
.majorBox .majorListTitle{ display:inline-block;  width:870px; font-family:'SUIT-Regular'; font-size:20px; color: #333333; /* width:713px; */ height:25px; }
.majorBox .majorListYear{ display:block; width:52px; font-family:'SUIT-Light'; font-size:16px; color: #888888; width:51px; /* margin-left: 315px; */  float:right; margin-right: 32px; }
.majorBox .majorListOrder{ display:block; font-family:'SUIT-Light'; font-size:16px; color: #888888; width:126px; /* margin-left:33px; */ float:right; }


/* 파트너사 */
.partnerContents{ display:block; width:100%; padding-top: 200px; margin:0 auto; margin-bottom:180px; }
.partnerTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 88px; }
.clientBox{ display:block; width:100%; height:480px; text-align:center; background:url(../../CustomerSupport/image/menu_4_image.png)no-repeat;
            margin-bottom:184px; background-size:cover;  }
.clientTitle{ display:block; }
.clientTitle span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.clientTitle span:nth-child(2){ display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8); }

.clientArea{ display:block; width:100%; text-align:center; }
.clientFirst{ display:flex; justify-content:center; margin-bottom:24px; }
.clientSecond{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientThird{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientFourth { display:flex; justify-content:center; }

.clientContents { display:block; width: 204px; height:204px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.clientContents span:nth-child(1){ display:block; width:204px; height:164px; }

.clientContentsB{ display:block; width: 204px; height:204px; margin-right:24px; }
.clientContentsB span:nth-child(1){ display:block; /* width:256px; */ height:208px; }

.clientName{ display:block; width:204px; height:40px; line-height:20px; background: #f0f0f0; font-family: 'SUIT-Regular'; font-size:16px; color: #aaaaaa;
             padding-top:10px; }

.clientFirst .clientLogo1{ display:block; background:url(../../BusinessIntro/image/partnerImg1.png)no-repeat; background-position:center; }
.clientFirst .clientLogo2{ display:block; background:url(../../BusinessIntro/image/partnerImg2.png)no-repeat; background-position:center; }
.clientFirst .clientLogo3{ display:block; background:url(../../BusinessIntro/image/partnerImg3.png)no-repeat; background-position:center; }
.clientFirst .clientLogo4{ display:block; background:url(../../BusinessIntro/image/partnerImg4.png)no-repeat; background-position:center; }
.clientFirst .clientLogo5{ display:block; background:url(../../BusinessIntro/image/partnerImg5.png)no-repeat; background-position:center; }
.clientFirst .clientLogo6{ display:block; background:url(../../BusinessIntro/image/partnerImg6.png)no-repeat; background-position:center; }

.clientSecond .clientLogo7{ display:block; background:url(../../BusinessIntro/image/partnerImg7.png)no-repeat; background-position:center; }
.clientSecond .clientLogo8{ display:block; background:url(../../BusinessIntro/image/partnerImg8.png)no-repeat; background-position:center; }
.clientSecond .clientLogo9{ display:block; background:url(../../BusinessIntro/image/partnerImg9.png)no-repeat; background-position:center; }
.clientSecond .clientLogo10{ display:block; background:url(../../BusinessIntro/image/partnerImg10.png)no-repeat; background-position:center; }
.clientSecond .clientLogo11{ display:block; background:url(../../BusinessIntro/image/partnerImg11.png)no-repeat; background-position:center; }
.clientSecond .clientLogo12{ display:block; background:url(../../BusinessIntro/image/partnerImg12.png)no-repeat; background-position:center; }

.clientThird .clientLogo13{ display:block; background:url(../../BusinessIntro/image/partnerImg13.png)no-repeat; background-position:center; }
.clientThird .clientLogo14{ display:block; background:url(../../BusinessIntro/image/partnerImg14.png)no-repeat; background-position:center; }
.clientThird .clientLogo15{ display:block; background:url(../../BusinessIntro/image/partnerImg15.png)no-repeat; background-position:center; }
.clientThird .clientLogo16{ display:block; background:url(../../BusinessIntro/image/partnerImg16.png)no-repeat; background-position:center; }
.clientThird .clientLogo17{ display:block; background:url(../../BusinessIntro/image/partnerImg17.png)no-repeat; background-position:center; }
.clientThird .clientLogo18{ display:block; background:url(../../BusinessIntro/image/partnerImg18.png)no-repeat; background-position:center; }

.clientFourth .clientLogo19{ display:block; background:url(../../BusinessIntro/image/partnerImg19.png)no-repeat; background-position:center; }
.clientFourth .clientLogo20{ display:block; background:url(../../BusinessIntro/image/partnerImg20.png)no-repeat; background-position:center; }
.clientFourth .clientLogo21{ display:block; background:url(../../BusinessIntro/image/partnerImg21.png)no-repeat; background-position:center; }
.clientFourth .clientLogo22{ display:block; background:url(../../BusinessIntro/image/partnerImg22.png)no-repeat; background-position:center; }
.clientFourth .clientLogo23{ display:block; background:url(../../BusinessIntro/image/partnerImg23.png)no-repeat; background-position:center; }



/* 보도자료 */
.newsTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; padding-top: 200px; }
.reportBox{ display:block; width:100%; height:480px; text-align:center;  background:url(../../CompanyNews/image/menu_3_image.png)no-repeat;
            margin-bottom:180px; background-size:cover;  }
.reportTitle{ display:block; }
.reportTitle span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.reportTitle span:nth-child(2){ display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8); }

.reportArea{ display:flex; width:100%; text-align:center; margin-top:72px; justify-content:center; }

/* .postingTopArea{ display:flex; width:1280px; }
.postingTopArea .postingCount{ display:inline-block; width: 146px; height:24px; line-height:24px; font-family: 'SUIT-Light'; font-size:20px; color:#707070; } 
.postingTopArea .postingCount p{ display:inline-block; font-family: 'SUIT-Medium'; font-size:20px; color:#DD3C25; }

.postingTopArea .postingRightBox{ display:flex; width:432px; }
.postingTopArea .postingSelectBox{ display:inline-block; width:140px; height: 44px; border: 0.5px solid #C0C0C0; border-radius: 24px;
                                   font-family: 'SUIT-Light';  font-size:19px; margin-right:12px; padding-left:24px; background:url('../../CompanyNews/image/다각형 2.png')no-repeat;
                                   background-size: 8px;  background-position-y: 20px; background-position-x: 110px; cursor:pointer; }
.postingTopArea .postingSelectBox .postingSelectOption{ display:inline-block; width:140px; height:44px; border:dashed 1px red; } 

.postingTopArea .postingSearch{ display:flex; width:280px; height:44px; border:0.5px solid #C0C0C0; border-radius: 24px;  }
.postingTopArea .postingInput{ display:inline-block; width:232px; height:40px; border:none; border-radius: 24px; padding-left: 30px; }
.postingTopArea .postingInput:focus{outline:none;}

.postingTopArea .postingSearch .searchIcon{ display: inline-block; width: 23px; height:40px; background:url('../../CompanyNews/image/search_icon.png')no-repeat; text-align: end;
                                            background-position-y:10px; } */

.postingBottomArea{ display:flex; flex-wrap: nowrap;  width:1384px; height:1208px; text-align:center; margin:0 auto; margin-bottom:24px; } 
.postingBottomArea2{ display:flex; width:1360px; height:600px; text-align:center; margin:0 auto; margin-bottom:50px; }


/* .postingSlide{ width: 1378px; height: 1232px; position: relative; overflow: hidden; } */
.postingBoxArea{ display:block; flex-direction:inherit; width: 1360px; height: 1208px; flex-wrap: nowrap; margin:0 auto; }
/* .postingBoxArea2{ display:block; flex-direction:inherit; width: 1378px; height: 1232px; flex-wrap: nowrap; margin:0 auto; }
.postingBoxAreaBoard{ display:block; flex-direction:inherit; width: 1430px; height: 1232px; flex-wrap: nowrap; margin:0 auto; } */
.postingBox{ display:inline-block; float:left; width: 436px; height: 588px; margin-right:24px; margin-bottom:32px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06);
             border-radius: 8px; }
.postingImg > img{ display:inline-block; width: 436px; height:308px; border-top-left-radius:8px; border-top-right-radius:8px; }
.postingText{ display:block; text-align:left; height:280px; overflow:hidden; padding:20px 32px; } 
.postingTitle{ display:block; /* width: 372px; */ height: 68px; font-family: 'SUIT-Regular'; font-size:22px; line-height:34px; 
               overflow: hidden; text-overflow: ellipsis; margin-bottom:20px; color:#333333; }
.postingContents{ display:block; /* width:372px; */ height:104px; font-family: 'SUIT-Light'; font-size:18px; line-height:26px; overflow: hidden; text-overflow: ellipsis; margin-bottom:20px;
                  color: #555555; }
.postingDate{ display:block; /* width:372px; */ height:20px; font-family: 'SUIT-Light'; font-size:16px; line-height:20px; color:#aaaaaa; text-align:right; }


/* .postingBox22{ display:inline-block; float:left; width: 328px; height: 434px; margin-right:16px; margin-bottom:32px; box-shadow: 3px 3px 3px #0000001A; border: 2px solid #F0F0F0; }
.postingImg22{ display:inline-block; width: 328px; height:220px; overflow:hidden; }
.postingImg22 > img{ display:inline-block; width: 328px; height:220px; }
.postingText22{ display:block; padding: 20px; text-align:left; height:214px; overflow:hidden; line-height:26px; color:#505050; } 
.postingTitle22{ display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; height: 60px; font-family: 'SUIT-Regular'; font-size:22px; line-height:32px; 
                 overflow: hidden; text-overflow: ellipsis;  margin-bottom:24px; }
.postingContents22{ display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; height:84px; font-family: 'SUIT-Light'; font-size:16px; line-height:22px; overflow: hidden; text-overflow: ellipsis; margin-bottom:19px; }
.postingDate22{ display:block;  height:20px; font-family: 'SUIT-Light'; font-size:16px; line-height:26px; color:#BBBBBB; } */

.pagination { display: flex; justify-content: center; align-items: center; margin-bottom:240px; }
.pagination a { float: left; width:48px; height:48px; text-decoration: none; }
.arrowLeftFast{ display:inline-block; width:32px; height:32px; background:url('../../CompanyNews/image/arrowLeftFast2.png')no-repeat; background-position: center; cursor:pointer; }
.arrowLeft{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowLeft.png)no-repeat; background-position: center; cursor:pointer; }
.arrowRight{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowRight.png)no-repeat; background-position: center; cursor:pointer; }
.arrowRightFast{ display:inline-block; width:32px; height:32px; background:url('../../CompanyNews/image/arrowRightFast2.png')no-repeat; background-position: center; cursor:pointer; }

.btnActive{ width: 48px; height:48px; background: none; font-family: 'SUIT-Medium'; font-size:18px; color:#999999; }


/* 보도자료 Popup  */
.reportContents{ display:block; width:760px; /* height:844px; */ margin:0 auto; position: absolute;
                 top: 50%; left: 50%; transform: translate(-50%, -50%); text-align:left; }
.reportContentsTop{ display:block; width:760px; height:76px; margin-bottom:32px; }
.reportContentsTop .reportContentsTitle{ font-family: 'SUIT-Medium'; font-size:24px; line-height:38px; }

.reportContentsMiddle{ display:block; margin-bottom:32px; }
.reportContentsMiddle .reportImg{ display:block; margin:0 auto; background-position:center; background-size:800px 540px; width:436px; height:308px;
                                  margin-bottom:32px; }
.reportContentsMiddle .reportContsArea{ display:block; width:760px; height:300px; overflow-y:scroll; }
.reportContentsMiddle .reportConts{ display:block; font-family: 'SUIT-Regular'; font-size:18px; text-align:left; line-height: 30px; width:728px; }
.reportContentsMiddle .reportConts .reportTextMar{ margin-bottom:25px; }

.reportContentsBottom{ /* display:flex; */ width: 760px; height:36px; }
.reportContentsBottom .reportSources{ display:inline-block; font-family: 'SUIT-Light'; font-size:16px; line-height:20px; height:20px; color:#aaaaaa;
                                      text-decoration: underline; margin-right: 217px; }
.reportContentsBottom .reportDay{ font-family: 'SUIT-Regular'; font-size:16px; color:#aaaaaa; float:right; }

.reportBtnLeft{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeft.png)no-repeat; transform: translate(-130px, 400px); }
.reportBtnLeft:hover{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover1.png)no-repeat; transform: translate(-130px, 400px); }
/* .reportBtnLeft:active{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover2.png)no-repeat; transform: translate(-130px, 400px); } */

.reportBtnRight{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnRight.png)no-repeat; transform: translate(794px, -465px); }
.reportBtnRight:hover{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover1.png)no-repeat; transform: translate(794px, -465px) rotate(180deg); }
/* .reportBtnRight:active{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover2.png)no-repeat; transform: translate(-130px, 400px) rotate(180deg); } */

.reportBtnClose{ display:inline-block; width: 48px; height:48px; background:url(../../BusinessIntro/image/popupBtnClose.png)no-repeat; transform: translate(842px, -526px); }

.reportArrowTab{ display:block; height:60px; line-height:60px; text-align:center; width:1360px; margin:0 auto; margin-bottom:180px; }
.reportArrowTab .reportArrowAreaL{ display:inline-block; float:left; font-family: 'SUIT-Light'; font-size:19px; letter-spacing: 0px; cursor:pointer; }
.reportArrowTab .reArrowIcon{ display:inline-block; font-family: 'SUIT-Light'; font-size:19px; width: 13px; height:19px; margin-right:20px;
                              background:url('../../CompanyNews/image/reArrowLeft.png')no-repeat; background-position:center; }
.reportArrowTab .reportBtn{ display:inline-block; width:260px; height:60px; line-height:60px; background:#000000; border-radius:30px; color: #fff; 
                            font-family: 'SUIT-Medium'; font-size:26px; text-align:center; }
.reportArrowTab .reportBtn a{ color: #fff; text-decoration:none; }
.reportArrowTab .reportBtn a:hover{ color: #fff;}
.reportArrowTab .reportArrowAreaR{ display:inline-block;  float: right; font-family: 'SUIT-Light'; font-size:19px; letter-spacing: 0px; cursor:pointer; }
.reportArrowTab .reArrowIcon2{ display:inline-block; font-family: 'SUIT-Light'; font-size:19px; width: 13px; height:19px; margin-left:20px;
                               background:url('../../CompanyNews/image/reArrowRight.png')no-repeat; background-position:center; }

.searchBtn{ display:block; width: 150px; height:50px; border:dashed 1px red; }

.uNewsArrowArea{ display:flex; width:80px; height:50px; right: 0; top: -50px; margin-left: 1285px;}
.uNewsArrowL{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_L.png')no-repeat; background-position: center; }
.uNewsArrowL:hover{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_L_hover.png')no-repeat; background-position: center; }
.uNewsArrowR{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_R.png')no-repeat; background-position: center; }
.uNewsArrowR:hover{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_R_hover.png')no-repeat; background-position: center; }


.reportContsArea::-webkit-scrollbar { width: 8px; border-radius: 4px; cursor: pointer;}
.reportContsArea::-webkit-scrollbar-track { cursor: pointer;}
.reportContsArea::-webkit-scrollbar-thumb { border-radius:4px; background-color:#f0f0f0; cursor:pointer;}


}

/* FHD */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/


@media all and (min-width:1024px) {

.arrowTopIcon{ display:inline-block; width:40px; height:38px; background:url('../../components/image/arrow_icon.png')no-repeat; padding:5px; }

.bIntroTitle{ display:block; width:100%; height:280px; text-align:center;  background:url(../../BusinessIntro/image/businessBG22.png)no-repeat; background-size:cover; }
.bIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 52px; font-weight: 600; padding-top:85.5px; padding-bottom: 16px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#ffffff; }
.bIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:22px; color: #F9F9F9; 
                                   height: 28px; line-height: 28px; }

/* 기술 알아보기 */
.spatialInforContentsSect{ display:block; /* width:1360px; */ padding-top: 240px; margin:0 auto; margin-bottom:180px; } 
.spatialTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 71px; line-height: 44px; }
.spatialTextBoxTop{ display:block; /* width: 749px; */ /* height:136px; */ line-height:34px; text-align:center; margin:0 auto; margin-bottom:48px; }
.spatialTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
/* .spatialTexts1 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:8px; } */
.spatialTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.spatialTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
/* .spatialTexts3 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8;  margin-left:8px; margin-right:8px; } */
.businessTextBold{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:8px; /* margin-right: 8px; */  }
.businessTextBoldE{ font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:8px; margin-right: 8px; }

.spatialFlexBox{ display:flex; justify-content:center; }
.spatialImgBox .spatialInforImg{ display:block; width:700px; height:520px; background:url(../../BusinessIntro/image/디지털트윈_111.png)no-repeat; background-size: cover;
                                 border-radius:8px; }

.spatialTextBox{ display:block; width: 400px; height:520px; margin-left:24px; box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.spatialTextBox .spTarget1{ display:block; width: 167px; height:40px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 48px 0px 40px; margin-top:52px; line-height:40px;
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.spatialTextBox .spSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; }
.spSubFlex1 .spCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.spSubFlex1 .spSubTitle1{ display:block;  height: 60px; line-height: 30px; }
.spSubFlex1 .spSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }  
.spSubFlex1 .spSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.spatialTextBox .spSubFlex2{  display:flex; margin-left: 44px; margin-top: 28px; }
.spSubFlex2 .spCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.spSubFlex2 .spSubTitle2{ display:block;  /* height: 90px; */ line-height: 30px; }
.spSubFlex2 .spSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
.spSubFlex2 .spSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.spatialTextBox .spSubFlex3{  display:flex; margin-left: 44px; margin-top: 28px; }
.spSubFlex3 .spCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.spSubFlex3 .spSubTitle3{ display:block; height: 90px; line-height: 30px; }
.spSubFlex3 .spSubTitle3 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
.spSubFlex3 .spSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.spatialBottomText{ display:block; height:25px; font-family:'SUIT-Light'; font-size:20px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align: center; }

.eSopContentsSect{ display:block; /* width:1360px; */ padding-top: 200px; margin:0 auto; margin-bottom:180px; } 
.eSopTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 72px; line-height: 44px; }
.eSopTextBoxTop{ display:block; /* width: 763px; */ /* height:136px; */ line-height:34px; text-align:center; margin:0 auto; margin-bottom:48px; }
.eSopTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
/* .eSopTexts1 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8;  margin-left:8px; margin-right:8px; } */
.eSopTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.eSopTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
/* .eSopTexts3 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:8px; margin-right:8px; } */

.eSopFlexBox{ display:flex; justify-content:center; }
.eSopImgBox .eSopImg{ display:block; width:700px; height:520px; background:url(../../BusinessIntro/image/bn_2.png)no-repeat; background-size: cover;
                      border-radius:8px; }

.eSopTextBox{ display:block; width: 400px; height:520px; margin-left:24px; box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.eSopTextBox .eSopTarget1{ display:block; width: 167px; height:40px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 48px 0px 40px; margin-top:52px; line-height:40px;
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.eSopTextBox .eSopSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; }
.eSopSubFlex1 .eSopCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.eSopSubFlex1 .eSopSubTitle1{ display:block; /* width: 260px; height: 60px; */ line-height: 30px; }
.eSopSubFlex1 .eSopSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }  
.eSopSubFlex1 .eSopSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.eSopTextBox .eSopSubFlex2{  display:flex; margin-left: 44px; margin-top: 28px; }
.eSopSubFlex2 .eSopCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.eSopSubFlex2 .eSopSubTitle2{ display:block; /* width: 228px; height: 60px; */ line-height: 30px; }
.eSopSubFlex2 .eSopSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
.eSopSubFlex2 .eSopSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.eSopTextBox .eSopSubFlex3{  display:flex; margin-left: 44px; margin-top: 28px; }
.eSopSubFlex3 .eSopCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.eSopSubFlex3 .eSopSubTitle3{ display:block; /* width: 247px; height: 90px; */ line-height: 30px; }
.eSopSubFlex3 .eSopSubTitle3 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
.eSopSubFlex3 .eSopSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.eSopBottomText{ display:block; /* width:657px; */  height:25px; font-family:'SUIT-Light'; font-size:20px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align:center; }


.dataVisualContentsSect{ display:block; /* width:1360px; */ padding-top: 200px; margin:0 auto; margin-bottom:180px; } 
.dataTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 71px; line-height: 44px; }
.dataTextBoxTop{ display:block; /* width: 761px;  */ /* height:170px; */ line-height:34px; text-align:center; margin:0 auto; margin-bottom:48px; }
.dataTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
/* .dataTexts1 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; } */
.dataTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.dataTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
/* .dataTexts3 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:8px; margin-right:8px; } */
.dataTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }

.dataFlexBox{ display:flex; justify-content:center; }
.dataImgBox .dataImg{ display:block; width:700px; height:520px; background:url(../../BusinessIntro/image/bn_333.png)no-repeat; background-size: cover;
                                 border-radius:8px; }

.dataTextBox{ display:block; width: 400px; height:520px; margin-left:24px; box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.dataTextBox .dataTarget1{ display:block; width: 167px; height:40px; background: #F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 48px 0px 40px; margin-top:52px; line-height:40px;
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8;  }
.dataTextBox .dataSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; }
.dataSubFlex1 .dataCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dataSubFlex1 .dataSubTitle1{ display:block; /* width: 237px; height: 60px; */ line-height: 30px; }
.dataSubFlex1 .dataSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }  
.dataSubFlex1 .dataSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.dataTextBox .dataSubFlex2{  display:flex; margin-left: 44px; margin-top: 28px; }
.dataSubFlex2 .dataCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dataSubFlex2 .dataSubTitle2{ display:block; /* width: 264px; height: 60px; */ line-height: 30px; }
.dataSubFlex2 .dataSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
.dataSubFlex2 .dataSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.dataTextBox .dataSubFlex3{  display:flex; margin-left: 44px; margin-top: 28px; }
.dataSubFlex3 .dataCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dataSubFlex3 .dataSubTitle3{ display:block; /* width: 268px; height: 60px; */ line-height: 30px; }
.dataSubFlex3 .dataSubTitle3 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
.dataSubFlex3 .dataSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.dataBottomText{ display:block; /* width:657px; */  height:25px; font-family:'SUIT-Light'; font-size:20px; color: #555555; 
                    margin: 0 auto; margin-top: 28px; text-align:center; }


.systemLinkContentsSect{ display:block; /* width:1360px; */  padding-top: 200px; margin:0 auto; margin-bottom:180px; }
.systemTitle2{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 71px; line-height: 44px; }
.systemTextBoxTop{ display:block; /* width: 857px; */ /* height:136px; */ line-height:34px; text-align:center; margin:0 auto; margin-bottom:48px; }
.systemTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
/* .systemTexts1 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; margin-left:8px; margin-right:8px; } */
.systemTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.systemTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
/* .systemTexts3 span{ display: inline-block; font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#4D8DE8; } */
.systemTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }


.systemFlexBox{ display:flex; justify-content:center;}
.systemImgBox .systemImg{ display:block; width:700px; height:520px; background:url(../../BusinessIntro/image/bn_444.png)no-repeat; background-size: cover;
                                 border-radius:8px; }

.systemTextBox{ display:block; width: 400px; height:520px; margin-left:24px; box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.systemTextBox .systemTarget1{ display:block; width: 167px; height:40px; background: #F4F8FD; border-top-right-radius:20px;
                               border-bottom-right-radius:20px; padding:0px 48px 0px 40px; margin-top:52px; line-height:40px;
                               font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.systemTextBox .systemSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; }
.systemSubFlex1 .systemCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.systemSubFlex1 .systemSubTitle1{ display:block; /* width: 202px; height: 90px; */ line-height: 30px;}
.systemSubFlex1 .systemSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }  
.systemSubFlex1 .systemSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.systemTextBox .systemSubFlex2{  display:flex; margin-left: 44px; margin-top: 28px; }
.systemSubFlex2 .systemCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.systemSubFlex2 .systemSubTitle2{ display:block; /* width: 205px; height: 60px; */ line-height: 30px; }
.systemSubFlex2 .systemSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
.systemSubFlex2 .systemSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97;  }

.systemTextBox .systemSubFlex3{  display:flex; margin-left: 44px; margin-top: 28px; }
.systemSubFlex3 .systemCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.systemSubFlex3 .systemSubTitle3{ display:block; /* width: 247px; height: 90px; */ line-height: 30px; }
.systemSubFlex3 .systemSubTitle3 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
.systemSubFlex3 .systemSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.systemBottomText{ display:block; /* width:657px; */  height:25px; font-family:'SUIT-Light'; font-size:20px; color: #555555; 
                   margin: 0 auto; margin-top: 28px; text-align:center; }




/* 특허 */
.comPatBox{ display:block; width:100%; height:480px; text-align:center; padding:50px; background:url(../../CompanyIntro/image/menu_1_image.png)no-repeat;
            margin-bottom:176px; background-size:cover; }
.comPatConTItle2 { font-family: 'SUIT-SemiBold'; font-size: 34px;  display:block; width: 100%; text-align: center; margin-bottom:88px; padding-top:200px; }
.comPatCircle{ display:block; width: 12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom: 16px; }

/* .comPatTitle{ display:block; } 
.comPatTitle > span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.comPatTitle > span:nth-child(2){  display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8);  } */
.comPatContent{ display:block; margin-bottom:260px; }
.comPatConTItle{ font-family: 'SUIT-Medium'; font-size: 24px; display: block; width: 100%;  text-align: center; margin-bottom: 48px; }

.comPatentBox{ display: block; padding-bottom: 120px; }
.comPatentBox .ParFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPatentBox .ParFirst .ParFirstBox1{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_24.png)no-repeat; 
                                                    width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText2{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox1 .ParFirstText3{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParFirst .ParFirstBox2{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_25.png)no-repeat; 
                                                     width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox2 .ParFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; } 


.comPatentBox .ParFirst .ParFirstBox3{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFirst .ParFirstBox3 .ParFirstImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_26.png)no-repeat; 
                                                     width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox3 .ParFirstText7{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox3 .ParFirstText8{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox3 .ParFirstText9{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParFirst .ParFirstBox4{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFirst .ParFirstBox4 .ParFirstImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_27.png)no-repeat; 
                                                     width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox4 .ParFirstText10 {display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox4 .ParFirstText11{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox4 .ParFirstText12{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParFirst .ParFirstBox5{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParFirst .ParFirstBox5 .ParFirstImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_28.png)no-repeat; 
                                                     width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFirst .ParFirstBox5 .ParFirstText13 {display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox5 .ParFirstText14{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFirst .ParFirstBox5 .ParFirstText15{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParSecond{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPatentBox .ParSecond .ParSecondBox1{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_29.png)no-repeat; 
                                                       width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText2{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox1 .ParSecondText3{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParSecond .ParSecondBox2{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_30.png)no-repeat; 
                                                        width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText4{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText5{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox2 .ParSecondText6{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParSecond .ParSecondBox3{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParSecond .ParSecondBox3 .ParSecondImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_31.png)no-repeat; 
                                                        width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox3 .ParSecondText7{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox3 .ParSecondText8{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox3 .ParSecondText9{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParSecond .ParSecondBox4{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParSecond .ParSecondBox4 .ParSecondImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_32.png)no-repeat; 
                                                        width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox4 .ParSecondText10{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox4 .ParSecondText11{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox4 .ParSecondText12{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParSecond .ParSecondBox5{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.comPatentBox .ParSecond .ParSecondBox5 .ParSecondImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_33.png)no-repeat; 
                                                        width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParSecond .ParSecondBox5 .ParSecondText13{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox5 .ParSecondText14{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParSecond .ParSecondBox5 .ParSecondText15{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParThird{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPatentBox .ParThird .ParThirdBox1{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_34.png)no-repeat; 
                                                    width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText2{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox1 .ParThirdText3{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParThird .ParThirdBox2{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_35.png)no-repeat; 
                                                     width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText4{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText5{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox2 .ParThirdText6{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParThird .ParThirdBox3{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParThird .ParThirdBox3 .ParThirdImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_36.png)no-repeat; 
                                                     width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox3 .ParThirdText7{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox3 .ParThirdText8{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox3 .ParThirdText9{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParThird .ParThirdBox4{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParThird .ParThirdBox4 .ParThirdImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_37.png)no-repeat; 
                                                     width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParThird .ParThirdBox4 .ParThirdText10{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox4 .ParThirdText11{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox4 .ParThirdText12{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }

.comPatentBox .ParThird .ParThirdBox5{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPatentBox .ParThird .ParThirdBox5 .ParThirdImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_38.png)no-repeat; 
                                                     width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; } 
.comPatentBox .ParThird .ParThirdBox5 .ParThirdText13{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox5 .ParThirdText14{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParThird .ParThirdBox5 .ParThirdText15{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParFourth{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPatentBox .ParFourth .ParFourthBox1{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_39.png)no-repeat; 
                                                       width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText2{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPatentBox .ParFourth .ParFourthBox1 .ParFourthText3{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comPatentBox .ParFourth .ParFourthBox2{ display: block; width: 210px; height:372px; padding: 20px 16px; margin-right:24px; }
.comPatentBox .ParFourth .ParFourthBox3{ display: block; width: 210px; height:372px; padding: 20px 16px; margin-right:24px; }
.comPatentBox .ParFourth .ParFourthBox4{ display: block; width: 210px; height:372px; padding: 20px 16px; margin-right:24px; }
.comPatentBox .ParFourth .ParFourthBox5{ display: block; width: 210px; height:372px; padding: 20px 16px; }




/* 인증 */
.comCertifiedTItle{  font-family: 'SUIT-Medium'; font-size: 24px; display:block; width: 100%; text-align: center; margin-bottom:68px; color: #333333; }
.comCertifiedBox{ display: block;  padding-bottom: 120px; }
.comCertifiedBox .CerFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:24px;}
.comCertifiedBox .CerFirst .CerFirstBox{  display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/GS1.png)no-repeat; background-size: 96%; background-position-x: 5px;
                                                      width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px; /* background-size: cover; */ border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText{  display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;  }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText2{  display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;  }
.comCertifiedBox .CerFirst .CerFirstBox .CerFirstText3{  display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;  }

.comCertifiedBox .CerFirst .CerFirstBox2{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_41.png)no-repeat; 
                                                         width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox2 .CerFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comCertifiedBox .CerFirst .CerFirstBox3{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerFirst .CerFirstBox3 .CerFirstImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_42.png)no-repeat; 
                                                         width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox3 .CerFirstText7{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox3 .CerFirstText8{  display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox3 .CerFirstText9{  display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }

.comCertifiedBox .CerFirst .CerFirstBox4{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerFirst .CerFirstBox4 .CerFirstImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_43.png)no-repeat; 
                                                         width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox4 .CerFirstText10{  display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox4 .CerFirstText11{  display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox4 .CerFirstText12{  display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comCertifiedBox .CerFirst .CerFirstBox5{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comCertifiedBox .CerFirst .CerFirstBox5 .CerFirstImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_44.png)no-repeat; 
                                                         width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerFirst .CerFirstBox5 .CerFirstText13{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox5 .CerFirstText14{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerFirst .CerFirstBox5 .CerFirstText15{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comCertifiedBox .CerSecond{ display: flex; width: 100%; justify-content:center; }
.comCertifiedBox .CerSecond .CerSecondBox{  display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox .CerSecondImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_45.png)no-repeat; 
                                                         width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox .CerSecondText{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox .CerSecondText2{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox .CerSecondText3{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }

.comCertifiedBox .CerSecond .CerSecondBox2{ display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_46.png)no-repeat; 
                                                           width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText4{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText5{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox2 .CerSecondText6{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }

.comCertifiedBox .CerSecond .CerSecondBox3{  display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox3 .CerSecondImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_47.png)no-repeat; 
                                                           width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comCertifiedBox .CerSecond .CerSecondBox3 .CerSecondText7{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox3 .CerSecondText8{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comCertifiedBox .CerSecond .CerSecondBox3 .CerSecondText9{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }

.comCertifiedBox .CerSecond .CerSecondBox4{  display: block; width: 210px; /* height:372px; */ padding: 20px 16px; border-radius: 8px; margin-right:24px; }
.comCertifiedBox .CerSecond .CerSecondBox5{  display: block; width: 210px; /* height:372px; */ padding: 20px 16px; border-radius: 8px; }



/* 수상 */
.comPrizeTItle{ font-family: 'SUIT-Medium'; font-size: 24px; display:block; width: 100%; text-align: center; margin-bottom:48px; }
.comPrizeBox{ display: block; }
.comPrizeBox .PrizeFirst{ display: flex; width: 100%; justify-content:center; margin-bottom:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox{  display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstImg{ display:inline-block; background:url(../../BusinessIntro/image/Patent_52_2.png)no-repeat; 
                                                        width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText2{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox .PrizeFirstText3{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;}


.comPrizeBox .PrizeFirst .PrizeFirstBox2{  display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstImg2{ display:inline-block; background:url(../../BusinessIntro/image/Patent_48.png)no-repeat; 
                                                         width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText4{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText5{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox2 .PrizeFirstText6{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comPrizeBox .PrizeFirst .PrizeFirstBox3{  display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox3 .PrizeFirstImg3{ display:inline-block; background:url(../../BusinessIntro/image/Patent_49.png)no-repeat; 
                                                         width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox3 .PrizeFirstText7{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox3 .PrizeFirstText8{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }
.comPrizeBox .PrizeFirst .PrizeFirstBox3 .PrizeFirstText9{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666; }


.comPrizeBox .PrizeFirst .PrizeFirstBox4{  display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox4 .PrizeFirstImg4{ display:inline-block; background:url(../../BusinessIntro/image/Patent_50.png)no-repeat; 
                                                          width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox4 .PrizeFirstText10{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox4 .PrizeFirstText11{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox4 .PrizeFirstText12{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;}

.comPrizeBox .PrizeFirst .PrizeFirstBox5{  display: block; width: 210px; /* height:372px; */ padding: 20px 16px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.comPrizeBox .PrizeFirst .PrizeFirstBox5 .PrizeFirstImg5{ display:inline-block; background:url(../../BusinessIntro/image/Patent_51.png)no-repeat; 
                                                          width:180px; height:240px; border: 2px solid #DDDDDD; margin-bottom:16px;  background-size: cover; border-radius:8px; } 
.comPrizeBox .PrizeFirst .PrizeFirstBox5 .PrizeFirstText13{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox5 .PrizeFirstText14{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;}
.comPrizeBox .PrizeFirst .PrizeFirstBox5 .PrizeFirstText15{ display:block; font-family: 'SUIT-Regular'; font-size:15px; text-align:center; line-height: 22px; color: #666666;}


.eIntroTitle{ display:block; width:100%; height:280px; text-align:center;  background:url(../../BusinessIntro/image/businessBG33.png)no-repeat; background-size:cover; }
.eIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 52px; font-weight: 600; padding-top:85.5px; padding-bottom: 16px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#ffffff; }
.eIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:22px; color: #F9F9F9;
                                   height: 28px; line-height: 28px; }


/* 사업 알아보기 */
.safetyInforContentsSect2{ display:block; width:100%; /* height:980px; */ padding-top: 240px; margin:0 auto; margin-bottom:180px;  } 
.safetyTextBox{ display:block; /* width: 1400px; */ height:100%;  margin:0 auto; }
.safetyManageTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 72px; }

.safetyManageContents{ display:block; }
.safetyMText1{ display:block; text-align:center; margin-bottom:48px; padding: 0px 80px; }
.safetyTextConts{ display:block; line-height: 34px; }
/* .safetyTextConts span{ display:inline-block; font-family: 'SUIT-Regular'; font-size: 22px; line-height:34px; color: #333333; } */
/* .safetyTextConts span:nth-child(1){ margin-bottom:34px; }
.safetyTextConts span:nth-child(3){ margin-bottom:34px; } */

.safetyTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
.safetyTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.safetyTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.safetyTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
.safetyTexts5{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.safetyTexts6{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.safetyTexts7{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.safetyTexts8{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.safetyTexts9{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }

.businessTextBlack{ font-family:'SUIT-Medium'; font-weight: 500; font-size:22px; color:#000000; margin-left:4px;  margin-right:4px;  }
.businessTextBold2{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; /* margin-left:8px; */ }
.businessTextBold3{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; }

.safetyMText2{ display:flex; justify-content:center; }
.safetyTextConts2{ display:block; width: 540px; /* height:438px; */ margin-right:24px; box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.safetyTextConts2 .sTarget1{ display:inline-block; /* width: 165px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8;  }
.safetyTextConts2 .sSubFlex1{ display:flex; margin-left: 44px; margin-top: 52px; margin-right:16px; }
.sSubFlex1 .sCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.sSubFlex1 .sSubTitle1{ display:block; /* width: 336px; */ /* height: 71px; */ line-height: 38px; }
.sSubFlex1 .sSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
/* .sSubFlex1 .sSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.sSubCont1{ line-height:26px; margin-left:44px; margin-top:20px;  }
.sSubCont1 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; }

.safetyTextConts3{ display:block; width: 540px; /* height:438px; */ box-shadow: 0px 0px 12px rgb(2 26 79 / 6%); border-radius: 8px; }
.safetyTextConts3 .sTarget2{ display:inline-block; /* width: 165px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.safetyTextConts3 .sSubFlex2{ display:flex; margin-left: 44px; margin-top: 52px; }
.sSubFlex2 .sCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.sSubFlex2 .sSubTitle2{ display:block; /* width: 336px; */ /* height: 71px; */ line-height: 38px; }
.sSubFlex2 .sSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
/* .sSubFlex2 .sSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.sSubCont2{ line-height:26px; margin-left:44px; margin-top:20px;  }
.sSubCont2 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; }


.majorCases{ text-align:center; margin-top:120px;  }
.majorCircle{ display:block; width:12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom:16px; }
.majorTitle{ display:block; font-family:'SUIT-Medium'; font-size:24px; color: #333333; margin-bottom:80px; }
.majorFlexPic{ display:flex; width:100%; justify-content:center; }

.majorFlexPic .case1{ display:block; width: 280px; height:368px; margin-right: 120px; }
.majorFlexPic .case1 .caseIcon1{ display:block; width:280px; height:280px; background: url(../../BusinessIntro/image/case1.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px;
                                 background-size:100%; background-position: center; }
.majorFlexPic .case1 .caseText1{ text-align:center; line-height:30px; }
.majorFlexPic .case1 .caseText1 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555;  }

.majorFlexPic .case2{ display:block; width: 280px; height:368px; margin-right: 120px; }
.majorFlexPic .case2 .caseIcon2{ display:block; width:280px; height:280px; background: url(../../BusinessIntro/image/case2.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                 background-position: center; background-size:100%; }
.majorFlexPic .case2 .caseText2{ text-align:center; line-height:30px; }
.majorFlexPic .case2 .caseText2 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555; }

.majorFlexPic .case3{ display:block; width: 280px; height:368px; }
.majorFlexPic .case3 .caseIcon2{ display:block; width:280px; height:280px; background: url(../../BusinessIntro/image/case3.png)no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                 background-position: center; background-size:100%; }
.majorFlexPic .case3 .caseText3{ text-align:center; line-height:30px; }
.majorFlexPic .case3 .caseText3 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555; }



/* 디지털 트윈 */
.digitalInforContentsSect2{ display:block; width:100%; padding-top: 200px; margin:0 auto; margin-bottom:180px; }
.digitalTextBox{ display:block; /* width: 1400px; */ margin:0 auto;  }
.digitalManageTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 80px;  }

.digitalManageContents{ display:block; }
.digitalMText1{ display:block; text-align:center; margin-bottom:48px; }
.digitalTextConts{ display:block; line-height:34px; }
/* .digitalTextConts span{ display:block; font-family: 'SUIT-Regular'; font-size: 22px; line-height:34px; color: #333333; }
.digitalTextConts span:nth-child(1){ margin-bottom:34px; }
.digitalTextConts span:nth-child(3){ margin-bottom:34px; }  */

.digitalTexts1{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
.digitalTexts2{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts3{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
.digitalTexts4{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts5{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts6{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts7{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts8{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts9{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts10{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }


.digitalTexts1E{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts2E{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }
.digitalTexts3E{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; }
.digitalTexts4E{ display: block; font-family:'SUIT-Regular'; font-weight: 400; font-size:22px; color:#333333; margin-bottom:34px; }


.digitalMText2{ display:flex; justify-content:center; }
.digitalTextConts2{ display:inline-block; width: 400px; /* height:429px; */ margin-right:24px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.digitalTextConts2 .dTarget1{ display:inline-block; /* width: 170px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 36px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8; }
.digitalTextConts2 .dSubFlex1{ display:flex; margin-left: 36px; margin-top: 52px; margin-right:16px; /* height:109px; */ }
.dSubFlex1 .dCheckIcon{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex1 .dSubTitle1{ display:block; width: 336px; /* height: 71px; */ line-height: 30px; }
.dSubFlex1 .dSubTitle1 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333; }
/* .dSubFlex1 .dSubTitle1 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.dSubCont1{ line-height:26px; margin-left:36px; margin-top:28px; margin-bottom:60px; }
.dSubCont1 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; /* margin-left:14px; */ }
.dSubCont1 p:nth-child(2){ margin-left:14px; }
.dSubCont1 p:nth-child(4){ /* margin-left:14px; */ }

.digitalTextConts3{ display:inline-block; width: 400px; /* height:429px; */ margin-right:24px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; }
.digitalTextConts3 .dTarget2{ display:inline-block; /* width: 170px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 36px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color:#4D8DE8; }
.digitalTextConts3 .dSubFlex2{ display:flex; margin-left: 36px; margin-top: 52px;  /* height:109px; */ /* margin-bottom: 18px; */  }
.dSubFlex2 .dCheckIcon2{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex2 .dSubTitle2{ display:block; /* width: 336px; */ /* height: 71px; */ line-height: 30px; }
.dSubFlex2 .dSubTitle2 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
/* .dSubFlex2 .dSubTitle2 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.dSubCont2{ line-height:26px; margin-left:36px; margin-top:28px; margin-bottom:60px; }
.dSubCont2 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; }
.dSubCont2 p:nth-child(2){ margin-left:14px; }
.dSubCont2 p:nth-child(3){ margin-left:14px; }


.digitalTextConts4{ display:inline-block; width: 400px; /* height:429px; */ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px;  }
.digitalTextConts4 .dTarget3{ display:inline-block; /* width: 170px; */ height:40px; background-color:#F4F8FD; border-top-right-radius:20px;
                             border-bottom-right-radius:20px; padding:0px 52px 0px 40px; margin-top:52px; line-height:40px; 
                             font-family:'SUIT-Regular'; font-size:18px; color: #4D8DE8;  }
.digitalTextConts4 .dSubFlex3{ display:flex; margin-left: 44px; margin-top: 52px; /* height:109px; */  }
.dSubFlex3 .dCheckIcon3{ display:inline-block; width:28px; height:28px; background:url(../../BusinessIntro/image/check.png)no-repeat; margin-right:16px; }
.dSubFlex3 .dSubTitle3{ display:block; width: 336px; /* height: 71px; */ line-height: 30px; }
.dSubFlex3 .dSubTitle3 p{ font-family:'SUIT-Regular'; font-size:20px; font-weight: 400; color: #333333;  }
/* .dSubFlex3 .dSubTitle3 p span{ font-family:'SUIT-SemiBold'; font-size:24px; font-weight: 600; color: #134A97; } */
.dSubCont3{ line-height:26px; margin-left:36px; margin-top:28px;  margin-bottom:60px; margin-left:44px; }
.dSubCont3 p{ display:block; font-family:'SUIT-Regular'; font-size:18px; color: #888888; }
.dSubCont3 p:nth-child(2){ /* margin-left:24px; */ }


.majorCasesD{ text-align:center; margin-top:120px;  }
.majorCircleD{ display:block; width:12px; height:12px; background:#dddddd; border-radius:50%; text-align:center; margin:0 auto; margin-bottom:16px; }
.majorTitleD{ display:block; font-family:'SUIT-Medium'; font-size:24px; color: #333333; margin-bottom:80px; }
.majorFlexBoxD{ display:flex; width:100%; justify-content:center; }

.majorFlexBoxD .case3{ display:block; width: 280px; height:368px; margin-right: 120px; }
.majorFlexBoxD .case3 .caseIcon3{ display:block; width:280px; height:280px; background: url(../../BusinessIntro/image/case4.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                   background-position: center; background-size:100%; }
.majorFlexBoxD .case3 .caseText3{ text-align:center; line-height:30px; }
.majorFlexBoxD .case3 .caseText3 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555;  }

.majorFlexBoxD .case4{ display:block; width: 280px; height:368px; margin-right: 120px; }
.majorFlexBoxD .case4 .caseIcon4{ display:block; width:280px; height:280px; background: url(../../BusinessIntro/image/case5.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexBoxD .case4 .caseText4{ text-align:center; line-height:30px; }
.majorFlexBoxD .case4 .caseText4 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555; }


.majorFlexBoxD .case5{ display:block; width: 280px; height:368px; }
.majorFlexBoxD .case5 .caseIcon5{ display:block; width:280px; height:280px; background: url(../../BusinessIntro/image/case6.png) no-repeat #dddddd; border-radius:50%; margin-bottom:28px; 
                                  background-position: center; background-size:100%; }
.majorFlexBoxD .case5 .caseText5{ text-align:center; line-height:30px; }
.majorFlexBoxD .case5 .caseText5 p{ display:block; font-family:'SUIT-Regular'; font-size: 20px; font-weight: 400; color: #555555; }



/* 주요 실적 */
.majorPerformanceContentsS{ display:block; width:100%; padding-top: 200px; margin: 0 auto; margin-bottom:180px; }
.majorPerformanceTitleS{ display:block; /* width:1360px; */ height:43px; font-family: 'SUIT-SemiBold'; font-size:34px; text-align:center; margin-bottom: 79px; margin:0 auto; margin-bottom: 24px; }
.majorPerformanceSelect{ display:block; width:1360px; margin:0 auto; }

.majorListBox{ display:block; margin-top:96px; }
.majorBox{ display:block; width: 1100px; /* height:93px;*/ box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin:0 auto; padding: 20px 60px;
           margin-bottom:16px; }
.majorBox .majorClass{ display:block; font-family:'SUIT-Medium'; font-size:16px; color: #C0C0C0; margin-bottom:8px;  }
.majorBox .majorFlexBoxM{ /* display:flex; */ /* height:25px; */ }
.majorBox .majorListTitle{ display:inline-block;  width:870px; font-family:'SUIT-Regular'; font-size:20px; color: #333333; /* width:713px; */ height:25px; }
.majorBox .majorListYear{ display:block; width:52px; font-family:'SUIT-Light'; font-size:16px; color: #888888; width:51px; /* margin-left: 315px; */  float:right; margin-right: 32px; }
.majorBox .majorListOrder{ display:block; font-family:'SUIT-Light'; font-size:16px; color: #888888; width:160px; /* margin-left:33px; */ float:right; }


/* 파트너사 */
.partnerContents{ display:block; width:100%; padding-top: 200px; margin:0 auto; margin-bottom:180px; }
.partnerTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; margin-bottom: 88px; }
.clientBox{ display:block; width:100%; height:480px; text-align:center; background:url(../../CustomerSupport/image/menu_4_image.png)no-repeat;
            margin-bottom:184px; background-size:cover;  }
.clientTitle{ display:block; }
.clientTitle span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.clientTitle span:nth-child(2){ display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8); }

.clientArea{ display:block; width:100%; text-align:center; }
.clientFirst{ display:flex; justify-content:center; margin-bottom:24px; }
.clientSecond{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientThird{ display:flex; justify-content:center; margin-bottom:24px;  }
.clientFourth { display:flex; justify-content:center; }

.clientContents { display:block; width: 160px; height:160px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06); border-radius: 8px; margin-right:24px; }
.clientContents span:nth-child(1){ display:block; width:160px; height:164px; }

.clientContentsB{ display:block; width: 160px; height:180px; margin-right:24px; }
.clientContentsB span:nth-child(1){ display:block; /* width:256px; */ height:208px; }

.clientName{ display:block; width:160px; height:40px; line-height:20px; background: #f0f0f0; font-family: 'SUIT-Regular'; font-size:16px; color: #aaaaaa;
             padding:10px; overflow:hidden; text-overflow: ellipsis; white-space: nowrap; }

.clientFirst .clientLogo1{ display:block; background:url(../../BusinessIntro/image/partnerImg1.png)no-repeat; background-position:center; }
.clientFirst .clientLogo2{ display:block; background:url(../../BusinessIntro/image/partnerImg2.png)no-repeat; background-position:center; }
.clientFirst .clientLogo3{ display:block; background:url(../../BusinessIntro/image/partnerImg3.png)no-repeat; background-position:center; }
.clientFirst .clientLogo4{ display:block; background:url(../../BusinessIntro/image/partnerImg4.png)no-repeat; background-position:center; }
.clientFirst .clientLogo5{ display:block; background:url(../../BusinessIntro/image/partnerImg5.png)no-repeat; background-position:center; }
.clientFirst .clientLogo6{ display:block; background:url(../../BusinessIntro/image/partnerImg6.png)no-repeat; background-position:center; }

.clientSecond .clientLogo7{ display:block; background:url(../../BusinessIntro/image/partnerImg7.png)no-repeat; background-position:center; }
.clientSecond .clientLogo8{ display:block; background:url(../../BusinessIntro/image/partnerImg8.png)no-repeat; background-position:center; }
.clientSecond .clientLogo9{ display:block; background:url(../../BusinessIntro/image/partnerImg9.png)no-repeat; background-position:center; }
.clientSecond .clientLogo10{ display:block; background:url(../../BusinessIntro/image/partnerImg10.png)no-repeat; background-position:center; }
.clientSecond .clientLogo11{ display:block; background:url(../../BusinessIntro/image/partnerImg11.png)no-repeat; background-position:center; }
.clientSecond .clientLogo12{ display:block; background:url(../../BusinessIntro/image/partnerImg12.png)no-repeat; background-position:center; }

.clientThird .clientLogo13{ display:block; background:url(../../BusinessIntro/image/partnerImg13.png)no-repeat; background-position:center; }
.clientThird .clientLogo14{ display:block; background:url(../../BusinessIntro/image/partnerImg14.png)no-repeat; background-position:center; }
.clientThird .clientLogo15{ display:block; background:url(../../BusinessIntro/image/partnerImg15.png)no-repeat; background-position:center; }
.clientThird .clientLogo16{ display:block; background:url(../../BusinessIntro/image/partnerImg16.png)no-repeat; background-position:center; }
.clientThird .clientLogo17{ display:block; background:url(../../BusinessIntro/image/partnerImg17.png)no-repeat; background-position:center; }
.clientThird .clientLogo18{ display:block; background:url(../../BusinessIntro/image/partnerImg18.png)no-repeat; background-position:center; }

.clientFourth .clientLogo19{ display:block; background:url(../../BusinessIntro/image/partnerImg19.png)no-repeat; background-position:center; }
.clientFourth .clientLogo20{ display:block; background:url(../../BusinessIntro/image/partnerImg20.png)no-repeat; background-position:center; }
.clientFourth .clientLogo21{ display:block; background:url(../../BusinessIntro/image/partnerImg21.png)no-repeat; background-position:center; }
.clientFourth .clientLogo22{ display:block; background:url(../../BusinessIntro/image/partnerImg22.png)no-repeat; background-position:center; }
.clientFourth .clientLogo23{ display:block; background:url(../../BusinessIntro/image/partnerImg23.png)no-repeat; background-position:center; }



/* 보도자료 */
.newsTitle{ display: block; width: 100%; font-family: 'SUIT-SemiBold'; font-size: 34px; text-align: center; padding-top: 200px; }
.reportBox{ display:block; width:100%; height:480px; text-align:center;  background:url(../../CompanyNews/image/menu_3_image.png)no-repeat;
            margin-bottom:180px; background-size:cover;  }
.reportTitle{ display:block; }
.reportTitle span:nth-child(1){ display:block; width:100%; font-size: 74px; padding-top:165px; padding-bottom: 28px; font-family: 'SUIT-SemiBold'; color:#fff;  }
.reportTitle span:nth-child(2){ display:block; width:100%; font-family: 'SUIT-Light'; font-size:24px; color: rgba(255,255,255,0.8); }

.reportArea{ display:flex; width:100%; text-align:center; margin-top:72px; justify-content:center; }

/* .postingTopArea{ display:flex; width:1280px; }
.postingTopArea .postingCount{ display:inline-block; width: 146px; height:24px; line-height:24px; font-family: 'SUIT-Light'; font-size:20px; color:#707070; } 
.postingTopArea .postingCount p{ display:inline-block; font-family: 'SUIT-Medium'; font-size:20px; color:#DD3C25; }

.postingTopArea .postingRightBox{ display:flex; width:432px; }
.postingTopArea .postingSelectBox{ display:inline-block; width:140px; height: 44px; border: 0.5px solid #C0C0C0; border-radius: 24px;
                                   font-family: 'SUIT-Light';  font-size:19px; margin-right:12px; padding-left:24px; background:url('../../CompanyNews/image/다각형 2.png')no-repeat;
                                   background-size: 8px;  background-position-y: 20px; background-position-x: 110px; cursor:pointer; }
.postingTopArea .postingSelectBox .postingSelectOption{ display:inline-block; width:140px; height:44px; border:dashed 1px red; } 

.postingTopArea .postingSearch{ display:flex; width:280px; height:44px; border:0.5px solid #C0C0C0; border-radius: 24px;  }
.postingTopArea .postingInput{ display:inline-block; width:232px; height:40px; border:none; border-radius: 24px; padding-left: 30px; }
.postingTopArea .postingInput:focus{outline:none;}

.postingTopArea .postingSearch .searchIcon{ display: inline-block; width: 23px; height:40px; background:url('../../CompanyNews/image/search_icon.png')no-repeat; text-align: end;
                                            background-position-y:10px; } */

.postingBottomArea{ display:flex; flex-wrap: nowrap;  width:1180px; height:1208px; text-align:center; margin:0 auto; margin-bottom:24px; } 
.postingBottomArea2{ display:flex; width:1360px; height:600px; text-align:center; margin:0 auto; margin-bottom:50px; }


/* .postingSlide{ width: 1378px; height: 1232px; position: relative; overflow: hidden; } */
.postingBoxArea{ display:block; flex-direction:inherit; width: 1360px; height: 1208px; flex-wrap: nowrap; margin:0 auto; }
/* .postingBoxArea2{ display:block; flex-direction:inherit; width: 1378px; height: 1232px; flex-wrap: nowrap; margin:0 auto; }
.postingBoxAreaBoard{ display:block; flex-direction:inherit; width: 1430px; height: 1232px; flex-wrap: nowrap; margin:0 auto; } */
.postingBox{ display:inline-block; float:left; width: 360px; height: 560px; margin-right:24px; margin-bottom:32px; box-shadow: 0px 0px 12px rgba(2, 26, 79, 0.06);
             border-radius: 8px; }
.postingImg > img{ display:inline-block; width: 360px; height:280px; border-top-left-radius:8px; border-top-right-radius:8px; }
.postingText{ display:block; text-align:left; height:280px; overflow:hidden; padding:20px 32px; } 
.postingTitle{ display:block; /* width: 372px; */ height: 68px; font-family: 'SUIT-Regular'; font-size:22px; line-height:34px; 
               overflow: hidden; text-overflow: ellipsis; margin-bottom:20px; color:#333333; }
.postingContents{ display:block; /* width:372px; */ height:104px; font-family: 'SUIT-Light'; font-size:18px; line-height:26px; overflow: hidden; text-overflow: ellipsis; margin-bottom:20px;
                  color: #555555; }
.postingDate{ display:block; /* width:372px; */ height:20px; font-family: 'SUIT-Light'; font-size:16px; line-height:20px; color:#aaaaaa; text-align:right; }


/* .postingBox22{ display:inline-block; float:left; width: 328px; height: 434px; margin-right:16px; margin-bottom:32px; box-shadow: 3px 3px 3px #0000001A; border: 2px solid #F0F0F0; }
.postingImg22{ display:inline-block; width: 328px; height:220px; overflow:hidden; }
.postingImg22 > img{ display:inline-block; width: 328px; height:220px; }
.postingText22{ display:block; padding: 20px; text-align:left; height:214px; overflow:hidden; line-height:26px; color:#505050; } 
.postingTitle22{ display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; height: 60px; font-family: 'SUIT-Regular'; font-size:22px; line-height:32px; 
                 overflow: hidden; text-overflow: ellipsis;  margin-bottom:24px; }
.postingContents22{ display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; height:84px; font-family: 'SUIT-Light'; font-size:16px; line-height:22px; overflow: hidden; text-overflow: ellipsis; margin-bottom:19px; }
.postingDate22{ display:block;  height:20px; font-family: 'SUIT-Light'; font-size:16px; line-height:26px; color:#BBBBBB; } */

.pagination { display: flex; justify-content: center; align-items: center; margin-bottom:240px; }
.pagination a { float: left; width:48px; height:48px; text-decoration: none; }
.arrowLeftFast{ display:inline-block; width:32px; height:32px; background:url('../../CompanyNews/image/arrowLeftFast2.png')no-repeat; background-position: center; cursor:pointer; }
.arrowLeft{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowLeft.png)no-repeat; background-position: center; cursor:pointer; }
.arrowRight{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/newsArrowRight.png)no-repeat; background-position: center; cursor:pointer; }
.arrowRightFast{ display:inline-block; width:32px; height:32px; background:url('../../CompanyNews/image/arrowRightFast2.png')no-repeat; background-position: center; cursor:pointer; }

.btnActive{ width: 48px; height:48px; background: none; font-family: 'SUIT-Medium'; font-size:18px; color:#999999; }


/* 보도자료 Popup  */
.reportContents{ display:block; width:760px; /* height:844px; */ margin:0 auto; position: absolute;
                 top: 50%; left: 50%; transform: translate(-50%, -50%); text-align:left; }
.reportContentsTop{ display:block; width:760px; height:76px; margin-bottom:32px; }
.reportContentsTop .reportContentsTitle{ font-family: 'SUIT-Medium'; font-size:24px; line-height:38px; }

.reportContentsMiddle{ display:block; margin-bottom:32px; }
.reportContentsMiddle .reportImg{ display:block; margin:0 auto; background-position:center; background-size:800px 540px; width:436px; height:308px;
                                  margin-bottom:32px; }
.reportContentsMiddle .reportContsArea{ display:block; width:760px; height:300px; overflow-y:scroll; }
.reportContentsMiddle .reportConts{ display:block; font-family: 'SUIT-Regular'; font-size:18px; text-align:left; line-height: 30px; width:728px; }
.reportContentsMiddle .reportConts .reportTextMar{ margin-bottom:25px; }

.reportContentsBottom{ /* display:flex; */ width: 760px; height:36px; }
.reportContentsBottom .reportSources{ display:inline-block; font-family: 'SUIT-Light'; font-size:16px; line-height:20px; height:20px; color:#aaaaaa;
                                      text-decoration: underline; margin-right: 217px; }
.reportContentsBottom .reportDay{ font-family: 'SUIT-Regular'; font-size:16px; color:#aaaaaa; float:right; }

.reportBtnLeft{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeft.png)no-repeat; transform: translate(-130px, 400px); }
.reportBtnLeft:hover{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover1.png)no-repeat; transform: translate(-130px, 400px); }
/* .reportBtnLeft:active{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover2.png)no-repeat; transform: translate(-130px, 400px); } */

.reportBtnRight{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnRight.png)no-repeat; transform: translate(794px, -465px); }
.reportBtnRight:hover{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover1.png)no-repeat; transform: translate(794px, -465px) rotate(180deg); }
/* .reportBtnRight:active{ display:inline-block; width:48px; height:48px; background:url(../../BusinessIntro/image/popupBtnLeftHover2.png)no-repeat; transform: translate(-130px, 400px) rotate(180deg); } */

.reportBtnClose{ display:inline-block; width: 48px; height:48px; background:url(../../BusinessIntro/image/popupBtnClose.png)no-repeat; transform: translate(842px, -526px); }

.reportArrowTab{ display:block; height:60px; line-height:60px; text-align:center; width:1360px; margin:0 auto; margin-bottom:180px; }
.reportArrowTab .reportArrowAreaL{ display:inline-block; float:left; font-family: 'SUIT-Light'; font-size:19px; letter-spacing: 0px; cursor:pointer; }
.reportArrowTab .reArrowIcon{ display:inline-block; font-family: 'SUIT-Light'; font-size:19px; width: 13px; height:19px; margin-right:20px;
                              background:url('../../CompanyNews/image/reArrowLeft.png')no-repeat; background-position:center; }
.reportArrowTab .reportBtn{ display:inline-block; width:260px; height:60px; line-height:60px; background:#000000; border-radius:30px; color: #fff; 
                            font-family: 'SUIT-Medium'; font-size:26px; text-align:center; }
.reportArrowTab .reportBtn a{ color: #fff; text-decoration:none; }
.reportArrowTab .reportBtn a:hover{ color: #fff;}
.reportArrowTab .reportArrowAreaR{ display:inline-block;  float: right; font-family: 'SUIT-Light'; font-size:19px; letter-spacing: 0px; cursor:pointer; }
.reportArrowTab .reArrowIcon2{ display:inline-block; font-family: 'SUIT-Light'; font-size:19px; width: 13px; height:19px; margin-left:20px;
                               background:url('../../CompanyNews/image/reArrowRight.png')no-repeat; background-position:center; }

.searchBtn{ display:block; width: 150px; height:50px; border:dashed 1px red; }

.uNewsArrowArea{ display:flex; width:80px; height:50px; right: 0; top: -50px; margin-left: 1285px;}
.uNewsArrowL{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_L.png')no-repeat; background-position: center; }
.uNewsArrowL:hover{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_L_hover.png')no-repeat; background-position: center; }
.uNewsArrowR{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_R.png')no-repeat; background-position: center; }
.uNewsArrowR:hover{ display:block; width:40px; height:40px; background:url('../../components/image/_icon_R_hover.png')no-repeat; background-position: center; }


.reportContsArea::-webkit-scrollbar { width: 8px; border-radius: 4px; cursor: pointer;}
.reportContsArea::-webkit-scrollbar-track { cursor: pointer;}
.reportContsArea::-webkit-scrollbar-thumb { border-radius:4px; background-color:#f0f0f0; cursor:pointer;}


}