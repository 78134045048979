


/* 모바일 NAV */

@media all and (max-width: 767px) {

.rollingbanner { position: absolute; left: 60px; top: 610px; width: 1369px; height: 80px; font-size: .875rem;
                letter-spacing: -1px; padding: 7px 15px; box-sizing: border-box; z-index: 1; text-align: left; }
.rollingbanner > .title{ font-weight: bold; float: left; padding-right: 10px; }
.rollingbanner > .wrap{ position: relative; width: auto; height: 100%; box-sizing: border-box; overflow: hidden; }        
.rolling ul{ list-style: none; }
.rollingbanner li{ position: absolute; top: -72px; left: 0; }
.rollingbanner li.prev{ top: 72px; transition: top 0.1s ease;  display:none; }
.rollingbanner li.current{ top: 0; transition: top 0.5s ease; }
.rollingbanner li.next{ top: -72px; }
.rollingbanner a{ display: block; display: -webkit-box; text-decoration: none; -webkit-line-clamp: 1; -webkit-box-orient:vertical;
                  overflow: hidden; color: #ffffff; font-family: 'SUIT-Medium'; font-size: 14px; line-height: 24px; }

/* 슬라이드 테스트 */
.slider_container { position: relative; width:100%; height: 100%; display:block; /* background-color: #000000; */  }
.slider { transition: 0.9s; }


/* 0811 */
/* 모바일 메뉴바 */
.mobile-top-bar { background-color:#fff; /* position:fixed; */ /* position:absolute; */ top:0; left:0; width:100%; height:80px; z-index:1;}
.mobile-top-bar > .logo { text-align:center; color:#000000; font-weight:bold; font-size:1.5rem;}
.ico { position:absolute; top:24px; right:28px; width:26px; height:18px; transform:translateY(40%); cursor:pointer;}
.ico > div { position:absolute; top:0; left:0; width:100%; height:11%; background-color:#000000;}
.ico > div:nth-child(1), .ico > div:nth-child(3) { transition:top .15s .15s, transform .15s;}
.ico > div:nth-child(2) { top:40%; transition:visibility 0s .15s;}
.ico > div:nth-child(3) { top:80%;}

.left-side-bar-box { width: 240px; position: fixed; top: 0; right: -240px; height: 100%;  z-index: 999; color: #fff; transition: all 0.3s;
                     box-shadow: 0px 4px 24px rgba(2, 26, 79, 0.08); }
.left-side-bar-box.active { right: 0; }
.overlay { position: fixed;  width: 100vw; height: 100vh; background: rgba(0, 0, 0, 0.7);  z-index: 998; display: none;}


.menuContent { font-family: 'SUIT-Light'; display: block; padding-left: 32px; padding-top: 60px; }
.menuLogoM{  width: 134px; height:56px; background:url(../image/U&Elogo.png) no-repeat; background-size:64%; 
             background-position-y: center; margin: 12px 24px; position:absolute; left:0; }

.menuM { position: absolute; top: 0; /* right: 0; */ height: 100%; min-width: 240px; transition: 0.5s ease;  background-color: #fff; z-index:1; 
         border-radius:40px 0px 0px 40px; }

.menuClose { display:inline-block; width: 40px; height:60px;  background:url(../../components/image/closeM.png)no-repeat;  
              background-size: 18px; background-position: left; float:right;  }
.menuContent .companyTab{ display:block; font-size:16px; font-weight: 600; color: #333333; margin-bottom: 18px;  }
.menuTab1 li{ display:block; height:24px; }
.menuTab1 li:last-child{ margin-bottom:16px; }
.menuTab1 li a{ width:100%; font-size: 12px; font-weight: 400; padding:0; color: #999999; }

.menuContent .businessTab{ display:block; font-size:16px; font-weight: 600; color: #333333; margin-bottom: 18px;  }
.menuTab2 li{ display:block; height:24px; }
.menuTab2 li:last-child{ margin-bottom:16px; }
.menuTab2 li a{ width:100%; font-size: 12px; font-weight: 400; padding:0; color: #999999; }

.menuContent .newsTab{ display:block; font-size:16px; font-weight: 600; color: #333333; margin-bottom: 18px;  }
.menuTab3 li{ display:block; height:24px;}
.menuTab3 li:last-child{ margin-bottom:16px; }
.menuTab3 li a{ width:100%; font-size: 12px; font-weight: 400; padding:0; color: #999999; }

.menuContent .productTab{ display:block; font-size:16px; font-weight: 600; color: #333333; margin-bottom: 18px;  }
.menuTab4 li{ display:block; height:24px; }
.menuTab4 li:last-child{ margin-bottom:16px; }
.menuTab4 li a{ width:100%; font-size: 12px; font-weight: 400; padding:0; color: #999999; }

.menuContent .customerTab{ display:block; font-size:16px; font-weight: 600; color: #333333; margin-bottom: 18px;  }
.menuTab5 li{ display:block; height:24px; }
.menuTab5 li:last-child{ margin-bottom:16px; }
.menuTab5 li a{ width:100%; font-size: 12px; font-weight: 400; padding:0; color: #999999; }

.menuDirect{ float:right; padding-right: 30px; font-size: 12px; text-decoration: underline; color: #999999; }
.menuDirect a{ color: #999999;  }



/* 문의하기 selectBox */
.InquirySelectBoxM * { box-sizing: border-box; }
.InquirySelectBoxM  { position: relative; width:440px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                     background:url('../../CompanyNews/image/다각형 2.png')no-repeat; background-position-y: 17.37px;
                     background-position-x: 400px; background-size:10px; border-radius:4px; padding: 12px 20px 13px; }
.InquirySelectBoxM:focus{ border: 1px solid #4D8DE8; }
.InquirySelectBoxM:checked{ border: 1px solid #4D8DE8; }

.InquirySelectBoxM .label4M { display: flex; align-items: center; border: 0 none; outline: 0 none; line-height:22.5px;
                              background: transparent; cursor: pointer; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; color:#C0C0C0; }
.InquirySelectBoxM .InquiryOptionListM { position: absolute; top: 50px; left: -1px; width: 240px; height:280px; list-style-type: none; 
                                         padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); 
                                         font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar { width: 4px;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar { width: 4px;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-track { background: transparent; }
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-thumb { background: #f0f0f0;  }
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-thumb:hover { background: #f0f0f0;}

.InquirySelectBoxM.active .InquiryOptionListM { max-height: 500px; background:#fff; }
.InquirySelectBoxM  .InquiryOptionItemM { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.InquirySelectBoxM  .InquiryOptionItemM:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.InquirySelectBoxM  .InquiryOptionItemM:last-child { border-bottom: 0 none;}



/* 문의하기 selectBox */
.InquiryHomeSelect * { box-sizing: border-box; }
.InquiryHomeSelect  { position: relative; width:340px; height: 40px; border: 1px solid #DDDDDD; cursor: pointer; 
                     background:url(../../components/image/selectBox_Arr.png)no-repeat; background-position-y: 15px;
                     background-position-x: 300px; background-size:15px; border-radius:4px; padding: 12px 20px 13px;
                     font-family:'SUIT-Regular'; font-size:14px; color: #333333; }
.InquiryHomeSelect:focus{ border: 1px solid #4D8DE8; }
.InquiryHomeSelect:checked{ border: 1px solid #4D8DE8; }


.InquiryHomeSelect .InquiryHomeOptionItem { position: absolute; top: 50px; left: -1px; width: 240px; height:280px; list-style-type: none; 
                                         padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); 
                                         font-family: 'SUIT-Light'; font-weight:400; font-size:14px; color: #999999;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar { width: 4px;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar { width: 4px;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-track { background: transparent; }
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-thumb { background: #f0f0f0;  }
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-thumb:hover { background: #f0f0f0;}

.InquiryHomeSelect.active .InquiryHomeOptionItem { max-height: 500px; background:#fff; }
.InquiryHomeSelect .InquiryHomeOptionItem { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:14px; line-height:22.5px;  }
.InquiryHomeSelect .InquiryHomeOptionItem:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:14px; color: #4D8DE8; }
.InquiryHomeSelect .InquiryHomeOptionItem:last-child { border-bottom: 0 none;}


select::-ms-expand { display: none; }
.select {  -o-appearance: none;  -webkit-appearance: none;  -moz-appearance: none; appearance: none;}
input { -webkit-appearance: none; -moz-appearance: none; appearance: none; }
input:focus { outline: none; }
select:focus { outline: none; }
textarea:focus { outline: none; }


/* 문의하기 input */
input[type="checkbox"]{ display: none; }
input[type="checkbox"] + label{ display: inline-block; width: 22px; height: 22px; border:1px solid #DDDDDD; position: relative; border-radius:4px; }
input[id="check1"]:checked + label::after{ /* content:'✔'; */ content:url(../../components/image/personalCheck.png); width: 22px; height: 22px; text-align: center; 
                                           position: absolute; left: 0; top:0; border-radius:4px; border:1px solid #4D8DE8; background-color:#F4F8FD; padding-top:2px; }

/* 문의하기 popup */
.layer-popupM { display: none; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 100;  width: 742px; height: 340px;
	           background: #dddddd; box-shadow: 0px 0px 8px #00000014; border-radius: 8px;}
.layer-popupM.show { display: block; }
.inputTextM { color:#666666; font-family: 'SUIT-Regular'; font-size: 11px; font-weight:400; display: inline-block;  height: 20px; line-height: 20px; /* margin-right:40px; */ }
.inputEffectM { animation: inputEffect-effect 1s linear infinite; color: #DD3C25;}
#frame_divM { width: 100px; height: 100px; position: relative;}


/* 주요 실적 selectBox */
.majorSelectBox * { box-sizing: border-box; }
.majorSelectBox  { position: relative; width:164px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                   background:url(../../components/image/selectBox_Arr.png)no-repeat;  background-position-x: 124px; background-position-y: 17.37px;
                   border-radius:4px; padding: 12px 20px 13px; float:right; }
.majorSelectBox .label5M { display: flex; align-items: center; border: 0 none; outline: 0 none; line-height:22.5px;
                              background: transparent; cursor: pointer; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; color:#333333; }
.majorSelectBox .majorOptionList { position: absolute; top: 50px; left: -1px; width: 164px; height:168px; list-style-type: none; 
                                   padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08);
                                   font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}

.majorSelectBox.active .majorOptionList { max-height: 500px; background:#fff; }
.majorSelectBox .majorOptionItem { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.majorSelectBox .majorOptionItem:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.majorSelectBox .majorOptionItem:last-child { border-bottom: 0 none;}


/* modalBox test */
.modal { background: rgba(0, 0, 0, 0.1); }
.modal-container { position: absolute; top: 26%; left: 12%; width: 300px; height: 400px; padding: 36px 60px; text-align: center; background-color: #ffffff;
                   border-radius: 8px; /* transform: translateX(-50%) translateY(-50%); */  }


.custom-select { position: relative; width: 440px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; background: url(../../components/image/selectBox_Arr.png)no-repeat;
                 background-position-y: 17.37px; background-position-x: 400px; background-size: 10px; border-radius: 4px; padding: 12px 20px 13px; }
.custom-select select { display: none; } 
.select-selected:after { position: absolute; content: ""; top: 14px; right: 10px; width: 0; height: 0; border: 6px solid transparent; border-color: #fff transparent transparent transparent; }
.select-selected.select-arrow-active:after { border-color: transparent transparent #fff transparent; top: 7px; }
.select-items div { padding: 8px 16px; height:56px; cursor: pointer; user-select: none; }
.select-selected { font-family: SUIT-Regular; font-size: 18px; }
.select-items { position: absolute; top: 100%; left: 0; right: 0; z-index: 99; width: 240px; height: 280px; list-style-type: none; padding: 0;
                z-index: 2; border-radius: 8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); font-family: 'SUIT-Light';
                font-weight: 400; font-size: 18px; display: block; color: #999999; overflow-y:scroll; }
.select-hide { display: none;  }
.select-items div:hover, .same-as-selected { background-color: rgba(0, 0, 0, 0.1); }

}




/* 가로 mobile */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/

@media all and (min-width: 640px) and (max-width: 959px) {

 .rollingbanner { position: absolute; left: 60px; top: 610px; width: 1369px; height: 80px; font-size: .875rem;
                letter-spacing: -1px; padding: 7px 15px; box-sizing: border-box; z-index: 1; text-align: left; }
.rollingbanner > .title{ font-weight: bold; float: left; padding-right: 10px; }
.rollingbanner > .wrap{ position: relative; width: auto; height: 100%; box-sizing: border-box; overflow: hidden; }        
.rolling ul{ list-style: none; }
.rollingbanner li{ position: absolute; top: -72px; left: 0; }
.rollingbanner li.prev{ top: 72px; transition: top 0.1s ease;  display:none; }
.rollingbanner li.current{ top: 0; transition: top 0.5s ease; }
.rollingbanner li.next{ top: -72px; }
.rollingbanner a{ display: block; display: -webkit-box; text-decoration: none; -webkit-line-clamp: 1; -webkit-box-orient:vertical;
                  overflow: hidden; color: #ffffff; font-family: 'SUIT-Medium'; font-size: 14px; line-height: 24px; }


/* 슬라이드 테스트 */
.slider_container { position: relative; width:100%; height: 100%; display:block; /* background-color: #000000; */  }
.slider { transition: 0.9s; /* width: 100vw; height:100vw; */ }


/* 0811 */
/* 모바일 메뉴바 */
.mobile-top-bar { background-color:#fff; /* position:fixed; */ position:absolute; top:0; left:0; width:100%; height:80px; z-index:1;}
.mobile-top-bar > .logo { text-align:center; color:#000000; font-weight:bold; font-size:1.5rem;}
.ico { position:absolute; top:24px; right:30px; width:30px; height:20px; transform:translateY(40%); cursor:pointer;}
.ico > div { position:absolute; top:0; left:0; width:100%; height:11%; background-color:#000000;}
.ico > div:nth-child(1), .ico > div:nth-child(3) { transition:top .15s .15s, transform .15s;}
.ico > div:nth-child(2) { top:40%; transition:visibility 0s .15s;}
.ico > div:nth-child(3) { top:80%;}

.left-side-bar-box { width: 300px; position: fixed; top: 0; right: -300px; height: 100%;  z-index: 999; color: #fff; transition: all 0.3s;
                     box-shadow: 0px 4px 24px rgba(2, 26, 79, 0.08); }
.left-side-bar-box.active { right: 0; }
.overlay { position: fixed;  width: 100vw; height: 100vh; background: rgba(0, 0, 0, 0.7);  z-index: 998; display: none;}


.menuContent { font-family: 'SUIT-Light'; display: block; padding-left: 50px; padding-top: 80px; }
.menuLogoM{  width: 134px; height:56px; background:url(../image/U&Elogo.png) no-repeat; background-size:64%; background-position-y: center; margin: 12px 24px; position:absolute; left:0; }

.menuM { position: absolute; top: 0; /* right: 0; */ height: 100%; min-width: 300px; transition: 0.5s ease;  background-color: #fff; z-index:1; 
         border-radius:40px 0px 0px 40px; }
/* .menuM li{ float:left; width:230px; height: 40px; line-height:40px; position:relative; font-size: 14px; padding-left:30px; }
.menuM li:nth-child(1){ margin-top: 100px; }
.menuM li:nth-child(5){ margin-right: 57px; } 
.menuM li a{ width:180px; display:block; color: #000; text-decoration:none; font-weight: 800; position:relative; font-family: 'SUIT-Medium'; font-size:27px; } 
.menuM li a:hover{ border-bottom:solid 3px red; } */ 

.menuContent .companyTab{ display:block; font-size:20px; font-weight: 600; color: #333333; margin-bottom: 18px;  }
.menuTab1 li{ display:block; height:30px; }
.menuTab1 li:last-child{ margin-bottom:16px; }
.menuTab1 li a{ width:100%; font-size: 16px; font-weight: 400; padding:0; color: #999999; }

.menuContent .businessTab{ display:block; font-size:20px; font-weight: 600; color: #333333; margin-bottom: 18px;  }
.menuTab2 li{ display:block; height:30px; }
.menuTab2 li:last-child{ margin-bottom:16px; }
.menuTab2 li a{ width:100%; font-size: 16px; font-weight: 400; padding:0; color: #999999; }

.menuContent .newsTab{ display:block; font-size:20px; font-weight: 600; color: #333333; margin-bottom: 18px;  }
.menuTab3 li{ display:block; height:30px;}
.menuTab3 li:last-child{ margin-bottom:16px; }
.menuTab3 li a{ width:100%; font-size: 16px; font-weight: 400; padding:0; color: #999999; }

.menuContent .productTab{ display:block; font-size:20px; font-weight: 600; color: #333333; margin-bottom: 18px;  }
.menuTab4 li{ display:block; height:30px; }
.menuTab4 li:last-child{ margin-bottom:16px; }
.menuTab4 li a{ width:100%; font-size: 16px; font-weight: 400; padding:0; color: #999999; }

.menuContent .customerTab{ display:block; font-size:20px; font-weight: 600; color: #333333; margin-bottom: 18px;  }
.menuTab5 li{ display:block; height:30px; }
.menuTab5 li:last-child{ margin-bottom:16px; }
.menuTab5 li a{ width:100%; font-size: 16px; font-weight: 400; padding:0; color: #999999; }

.menuDirect{ float:right;  padding-top: 20px; padding-right: 30px; }


/* 문의하기 selectBox */
.InquirySelectBoxM * { box-sizing: border-box; }
.InquirySelectBoxM  { position: relative; width:440px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                     background:url('../../CompanyNews/image/다각형 2.png')no-repeat; background-position-y: 17.37px;
                     background-position-x: 400px; background-size:10px; border-radius:4px; padding: 12px 20px 13px; }
.InquirySelectBoxM:focus{ border: 1px solid #4D8DE8; }
.InquirySelectBoxM:checked{ border: 1px solid #4D8DE8; }

.InquirySelectBoxM .label4M { display: flex; align-items: center; border: 0 none; outline: 0 none; line-height:22.5px;
                              background: transparent; cursor: pointer; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; color:#C0C0C0; }
.InquirySelectBoxM .InquiryOptionListM { position: absolute; top: 50px; left: -1px; width: 240px; height:280px; list-style-type: none; 
                                         padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); 
                                         font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar { width: 4px;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar { width: 4px;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-track { background: transparent; }
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-thumb { background: #f0f0f0;  }
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-thumb:hover { background: #f0f0f0;}

.InquirySelectBoxM.active .InquiryOptionListM { max-height: 500px; background:#fff; }
.InquirySelectBoxM  .InquiryOptionItemM { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.InquirySelectBoxM  .InquiryOptionItemM:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.InquirySelectBoxM  .InquiryOptionItemM:last-child { border-bottom: 0 none;}



/* 문의하기 selectBox */
.InquiryHomeSelect * { box-sizing: border-box; }
.InquiryHomeSelect  { position: relative; width:340px; height: 40px; border: 1px solid #DDDDDD; cursor: pointer; 
                     background:url(../../components/image/selectBox_Arr.png)no-repeat; background-position-y: 15px;
                     background-position-x: 300px; background-size:15px; border-radius:4px; padding: 12px 20px 13px;
                     font-family:'SUIT-Regular'; font-size:14px; color: #333333; }
.InquiryHomeSelect:focus{ border: 1px solid #4D8DE8; }
.InquiryHomeSelect:checked{ border: 1px solid #4D8DE8; }


.InquiryHomeSelect .InquiryHomeOptionItem { position: absolute; top: 50px; left: -1px; width: 240px; height:280px; list-style-type: none; 
                                         padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); 
                                         font-family: 'SUIT-Light'; font-weight:400; font-size:14px; color: #999999;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar { width: 4px;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar { width: 4px;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-track { background: transparent; }
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-thumb { background: #f0f0f0;  }
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-thumb:hover { background: #f0f0f0;}

.InquiryHomeSelect.active .InquiryHomeOptionItem { max-height: 500px; background:#fff; }
.InquiryHomeSelect .InquiryHomeOptionItem { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:14px; line-height:22.5px;  }
.InquiryHomeSelect .InquiryHomeOptionItem:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:14px; color: #4D8DE8; }
.InquiryHomeSelect .InquiryHomeOptionItem:last-child { border-bottom: 0 none;}


select::-ms-expand { display: none; }
.select {  -o-appearance: none;  -webkit-appearance: none;  -moz-appearance: none; appearance: none;}
input { -webkit-appearance: none; -moz-appearance: none; appearance: none; }
input:focus { outline: none; }
select:focus { outline: none; }
textarea:focus { outline: none; }

/* 문의하기 input */
input[type="checkbox"]{ display: none; }
input[type="checkbox"] + label{ display: inline-block; width: 22px; height: 22px; border:1px solid #DDDDDD; position: relative; border-radius:4px; }
input[id="check1"]:checked + label::after{ /* content:'✔'; */ content:url(../../components/image/personalCheck.png); width: 22px; height: 22px; text-align: center; 
                                           position: absolute; left: 0; top:0; border-radius:4px; border:1px solid #4D8DE8; background-color:#F4F8FD; padding-top:2px; }

/* 문의하기 popup */
.layer-popupM { display: none; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 100;  width: 742px; height: 340px;
	           background: #dddddd; box-shadow: 0px 0px 8px #00000014; border-radius: 8px;}
.layer-popupM.show { display: block; }
.inputTextM { color:#666666; font-family: 'SUIT-Regular'; font-size: 11px; font-weight:400; display: inline-block;  height: 20px; line-height: 20px; margin-right:40px; }
.inputEffectM { animation: inputEffect-effect 1s linear infinite; color: #DD3C25;}
#frame_divM { width: 100px; height: 100px; position: relative;}


/* 주요 실적 selectBox */
.majorSelectBox * { box-sizing: border-box; }
.majorSelectBox  { position: relative; width:164px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                   background:url(../../components/image/selectBox_Arr.png)no-repeat;  background-position-x: 124px; background-position-y: 17.37px;
                   border-radius:4px; padding: 12px 20px 13px; float:right; }
.majorSelectBox .label5M { display: flex; align-items: center; border: 0 none; outline: 0 none; line-height:22.5px;
                              background: transparent; cursor: pointer; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; color:#333333; }
.majorSelectBox .majorOptionList { position: absolute; top: 50px; left: -1px; width: 164px; height:168px; list-style-type: none; 
                                   padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08);
                                   font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}

.majorSelectBox.active .majorOptionList { max-height: 500px; background:#fff; }
.majorSelectBox .majorOptionItem { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.majorSelectBox .majorOptionItem:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.majorSelectBox .majorOptionItem:last-child { border-bottom: 0 none;}


/* modalBox test */
.modal { background: rgba(0, 0, 0, 0.1); }
.modal-container { position: absolute; /* top: 26%; */ top: 0%; left: 30%; width: 300px; height: 400px; padding: 36px 60px; text-align: center; background-color: #ffffff;
                   border-radius: 8px; /* transform: translateX(-50%) translateY(-50%); */  }


.custom-select { position: relative; width: 440px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; background: url(../../components/image/selectBox_Arr.png)no-repeat;
                 background-position-y: 17.37px; background-position-x: 400px; background-size: 10px; border-radius: 4px; padding: 12px 20px 13px; }
.custom-select select { display: none; } 
.select-selected:after { position: absolute; content: ""; top: 14px; right: 10px; width: 0; height: 0; border: 6px solid transparent; border-color: #fff transparent transparent transparent; }
.select-selected.select-arrow-active:after { border-color: transparent transparent #fff transparent; top: 7px; }
.select-items div { padding: 8px 16px; height:56px; cursor: pointer; user-select: none; }
.select-selected { font-family: SUIT-Regular; font-size: 18px; }
.select-items { position: absolute; top: 100%; left: 0; right: 0; z-index: 99; width: 240px; height: 280px; list-style-type: none; padding: 0;
                z-index: 2; border-radius: 8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); font-family: 'SUIT-Light';
                font-weight: 400; font-size: 18px; display: block; color: #999999; overflow-y:scroll; }
.select-hide { display: none;  }
.select-items div:hover, .same-as-selected { background-color: rgba(0, 0, 0, 0.1); }

}


/* tablet */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/

@media all and (min-width:768px) and (max-width:1023px) {

.home{ border:dashed 1px blue; }

/* 메뉴바 하위항목 */
.menu2 #tab1{ background:none; }
.menu2 #tab1.active{ display:block; } 
.menu2 #tab2{ background:none; }
.menu2 #tab2.active{  display:block; } 
.menu2 #tab3{ background:none; }
.menu2 #tab3.active{ display:block; } 
.menu2 #tab4{ background:none; }
.menu2 #tab4.active{ display:block; } 
.menu2 #tab5{ background:none; }
.menu2 #tab5.active{ display:block; }


/* 슬라이드 테스트 */
.slider_container { position: relative; height: 100%; overflow: hidden; display:flex;}
.slider { transition: 0.9s; width: 100vw; height:100vh; }
.slider > div{ display:block; width: 100vw; height:100vh; text-align:center; }
.indicator { position: absolute; display: inline-flex; cursor: pointer; left: 48%; bottom: 6%;}
.dots { background-color: #fff; height: 12px; width: 12px; border-radius: 50%; transition: 0.8s; margin-left: 24px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
                    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
                    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;}
.dots:hover { background-color: #d7d7d7; }



/* 0705 tab버튼 active */
.menuu2 { /* float: right; */  margin-left:296px; height:100px;  }
.menuu2 li{ float:left; width:124px; height: 100px; text-align:left; padding:36px 0; align-items: center; margin-right:100px; }
.menuu2 li:nth-child(4){ margin: 0; } 
.menuu2 li:hover{ border-bottom:solid 4px #0D346C; }
.menuu2 li a{ width:70px; height:60px; color: #333333; text-decoration:none; font-weight:400; font-family: 'SUIT-Regular'; font-size:20px;
              line-height:30px; text-align: center; } 
.menuu2 li .on a{ font-weight: bold; color: yellow; }
.menuu2 li.selected{ display:block; /* border-bottom: solid 4px #0D346C; */ } 
.menuu2 li.selected a{ color: #0D346C; } 

.menu22{ /* display:flex; */ width: 100%; height:248px; background: #fff; font-size:13px; justify-content: flex-end; padding: 28px 0; box-shadow: 0px 4px 12px rgb(2 26 79 / 8%); } 
.menu22 ul { /* display:inline-block; width:160px; */ display:flex; flex-direction:column;  }
.menu22 ul:nth-child(5){ margin-right:57px; }
.menu22 ul li{ float:left; text-align:left; position:relative; width: 224px; height:32px; margin-bottom: 8px; }
.menu22 ul li a{ display:inline-block; color: #999999; letter-spacing:0; text-decoration:none;  position:relative; 
                line-height:25px; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; height:32px; width:224px; }
.menu22 ul li a:hover{ color: #0d346c; font-family: 'SUIT-Regular'; font-size:18px; } 

/* 1103 test */
.menu22:active{ display:block; }


/* 문의하기 selectBox */
.InquirySelectBoxM * { box-sizing: border-box; }
.InquirySelectBoxM  { position: relative; width:440px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                     background:url('../../CompanyNews/image/다각형 2.png')no-repeat; background-position-y: 17.37px;
                     background-position-x: 400px; background-size:10px; border-radius:4px; padding: 12px 20px 13px; }
.InquirySelectBoxM:focus{ border: 1px solid #4D8DE8; }
.InquirySelectBoxM:checked{ border: 1px solid #4D8DE8; }

.InquirySelectBoxM .label4M { display: flex; align-items: center; border: 0 none; outline: 0 none; line-height:22.5px;
                              background: transparent; cursor: pointer; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; color:#C0C0C0; }
.InquirySelectBoxM .InquiryOptionListM { position: absolute; top: 50px; left: -1px; width: 240px; height:280px; list-style-type: none; 
                                         padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); 
                                         font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar { width: 4px;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar { width: 4px;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-track { background: transparent; }
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-thumb { background: #f0f0f0;  }
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-thumb:hover { background: #f0f0f0;}

.InquirySelectBoxM.active .InquiryOptionListM { max-height: 500px; background:#fff; }
.InquirySelectBoxM  .InquiryOptionItemM { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.InquirySelectBoxM  .InquiryOptionItemM:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.InquirySelectBoxM  .InquiryOptionItemM:last-child { border-bottom: 0 none;}



/* 문의하기 selectBox */
.InquiryHomeSelect * { box-sizing: border-box; }
.InquiryHomeSelect  { position: relative; width:580px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                     background:url(../../components/image/selectBox_Arr.png)no-repeat; background-position-y: 17.37px;
                     background-position-x: 520px; border-radius:4px; padding: 12px 20px 13px;
                     font-family:'SUIT-Regular'; font-size:18px; color: #333333; }
.InquiryHomeSelect:focus{ border: 1px solid #4D8DE8; }
.InquiryHomeSelect:checked{ border: 1px solid #4D8DE8; }


.InquiryHomeSelect .InquiryHomeOptionItem { position: absolute; top: 50px; left: -1px; width: 240px; height:280px; list-style-type: none; 
                                         padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); 
                                         font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar { width: 4px;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar { width: 4px;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-track { background: transparent; }
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-thumb { background: #f0f0f0;  }
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-thumb:hover { background: #f0f0f0;}

.InquiryHomeSelect.active .InquiryHomeOptionItem { max-height: 500px; background:#fff; }
.InquiryHomeSelect .InquiryHomeOptionItem { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.InquiryHomeSelect .InquiryHomeOptionItem:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.InquiryHomeSelect .InquiryHomeOptionItem:last-child { border-bottom: 0 none;}


select::-ms-expand { display: none; }
.select {  -o-appearance: none;  -webkit-appearance: none;  -moz-appearance: none; appearance: none;}
input { -webkit-appearance: none; -moz-appearance: none; appearance: none; }
input:focus { outline: none; }
select:focus { outline: none; }
textarea:focus { outline: none; }

/* 문의하기 input */
input[type="checkbox"]{ display: none; }
input[type="checkbox"] + label{ display: inline-block; width: 28px; height: 28px; border:1px solid #DDDDDD; position: relative; border-radius:4px; }
input[id="check1"]:checked + label::after{ /* content:'✔'; */ content:url(../../components/image/personalCheck.png); width: 28px; height: 28px; text-align: center; 
                                           position: absolute; left: 0; top:0; border-radius:4px; border:1px solid #4D8DE8; background-color:#F4F8FD; padding-top:2px; }

/* 문의하기 popup */
.layer-popupM { display: none; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 100;  width: 742px; height: 340px;
	           background: #dddddd; box-shadow: 0px 0px 8px #00000014; border-radius: 8px;}
.layer-popupM.show { display: block; }
.inputTextM { color:#666666; font-family: 'SUIT-Regular'; font-size: 16px; font-weight:400; display: inline-block;  height: 20px; line-height: 20px; margin-right:40px; }
.inputEffectM { animation: inputEffect-effect 1s linear infinite; color: #DD3C25;}
#frame_divM { width: 100px; height: 100px; position: relative;}

/* 주요 실적 selectBox */
.majorSelectBox * { box-sizing: border-box; }
.majorSelectBox  { position: relative; width:164px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                   background:url(../../components/image/selectBox_Arr.png)no-repeat;  background-position-x: 124px; background-position-y: 17.37px;
                   border-radius:4px; padding: 12px 20px 13px; float:right; }
.majorSelectBox .label5M { display: flex; align-items: center; border: 0 none; outline: 0 none; line-height:22.5px;
                              background: transparent; cursor: pointer; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; color:#333333; }
.majorSelectBox .majorOptionList { position: absolute; top: 50px; left: -1px; width: 164px; height:168px; list-style-type: none; 
                                   padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08);
                                   font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}

.majorSelectBox.active .majorOptionList { max-height: 500px; background:#fff; }
.majorSelectBox .majorOptionItem { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.majorSelectBox .majorOptionItem:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.majorSelectBox .majorOptionItem:last-child { border-bottom: 0 none;}


/* modalBox test */
.modal { background: rgba(0, 0, 0, 0.1); }
.modal-container { position: absolute; top: 50%; left: 50%; width: 680px; height: 880px; padding: 36px 60px; text-align: center; background-color: #ffffff;
                   border-radius: 8px; transform: translateX(-50%) translateY(-50%); }


.custom-select { position: relative; width: 440px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; background: url(../../components/image/selectBox_Arr.png)no-repeat;
                 background-position-y: 17.37px; background-position-x: 400px; background-size: 10px; border-radius: 4px; padding: 12px 20px 13px; }
.custom-select select { display: none; }
.select-selected:after { position: absolute; content: ""; top: 14px; right: 10px; width: 0; height: 0; border: 6px solid transparent; border-color: #fff transparent transparent transparent; }
.select-selected.select-arrow-active:after { border-color: transparent transparent #fff transparent; top: 7px; }
.select-items div { padding: 8px 16px; height:56px; cursor: pointer; user-select: none; }
.select-selected { font-family: SUIT-Regular; font-size: 18px; }
.select-items { position: absolute; top: 100%; left: 0; right: 0; z-index: 99; width: 240px; height: 280px; list-style-type: none; padding: 0;
                z-index: 2; border-radius: 8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); font-family: 'SUIT-Light';
                font-weight: 400; font-size: 18px; display: block; color: #999999; overflow-y:scroll; }
.select-hide { display: none; }
.select-items div:hover, .same-as-selected { background-color: rgba(0, 0, 0, 0.1); }



.slideshow-container { max-width: 1440px; position: relative; margin: auto; margin-top: 120px; }
.fade { -webkit-animation-name: fade; -webkit-animation-duration: 1.5s; animation-name: fade; animation-duration: 1.5s; }

@-webkit-keyframes fade { from {opacity: .4}  to {opacity: 1}}
@keyframes fade { from {opacity: .4}  to {opacity: 1} }

.prev, .next { cursor: pointer; position: absolute; top: 50%; width: auto; padding: 16px; margin-top: -22px;
               color: white; font-weight: bold; font-size: 18px; transition: 0.6s ease; border-radius: 0 3px 3px 0; }
.next { right: 0; border-radius: 3px 0 0 3px; }
.prev:hover, .next:hover { background-color: rgba(0,0,0,0.8); }

.rollingbanner { position: absolute; left: 130px; top: 610px; width: 1369px; height: 80px; font-size: .875rem; letter-spacing: -1px;
                 padding: 7px 15px;  box-sizing: border-box; z-index: 1; text-align: left; }
.rollingbanner > .title{ font-weight: bold; float: left; padding-right: 10px; }
.rollingbanner > .wrap{ position: relative; width: auto; height: 100%; box-sizing: border-box; overflow: hidden; }       
.rolling ul{ list-style: none; }
.rollingbanner li{ position: absolute; top: -72px; left: 0; }
.rollingbanner li.prev{ top: 72px; transition: top 0.1s ease; display:none; }
.rollingbanner li.current{ top: 0; transition: top 0.5s ease; }
.rollingbanner li.next{ top: -72px; }
.rollingbanner a{ display: block; display: -webkit-box; text-decoration: none; -webkit-line-clamp: 1; -webkit-box-orient:vertical; 
                  overflow: hidden; color: #ffffff; font-family: 'SUIT-Medium'; font-size: 22px; line-height: 34px; }



}


/* 가로 tablet */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/

@media all and (min-width: 960px) and (max-width: 1280px) {

    .home{ border:dashed 1px blue; }

/* 메뉴바 하위항목 */
.menu2 #tab1{ background:none; }
.menu2 #tab1.active{ display:block; } 
.menu2 #tab2{ background:none; }
.menu2 #tab2.active{  display:block; } 
.menu2 #tab3{ background:none; }
.menu2 #tab3.active{ display:block; } 
.menu2 #tab4{ background:none; }
.menu2 #tab4.active{ display:block; } 
.menu2 #tab5{ background:none; }
.menu2 #tab5.active{ display:block; }


/* 슬라이드 테스트 */
.slider_container { position: relative; height: 100%; overflow: hidden; display:flex;}
.slider { transition: 0.9s; width: 100vw; height:100vw; }
.slider > div{ display:block; width: 100vw; height:100%; text-align:center; }
.indicator { position: absolute; display: inline-flex; cursor: pointer; left: 48%; bottom: 6%;}
.dots { background-color: #fff; height: 12px; width: 12px; border-radius: 50%; transition: 0.8s; margin-left: 24px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
                    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
                    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;}
.dots:hover { background-color: #d7d7d7; }


/* 0705 tab버튼 active */
.menuu2 { /* float: right; */  margin-left:296px; height:100px;  }
.menuu2 li{ float:left; width:124px; height: 100px; text-align:left; padding:36px 0; align-items: center; margin-right:100px; }
.menuu2 li:nth-child(4){ margin: 0; } 
.menuu2 li:hover{ border-bottom:solid 4px #0D346C; }
.menuu2 li a{ width:70px; height:60px; color: #333333; text-decoration:none; font-weight:400; font-family: 'SUIT-Regular'; font-size:20px;
              line-height:30px; text-align: center; } 
.menuu2 li .on a{ font-weight: bold; color: yellow; }
.menuu2 li.selected{ display:block; /* border-bottom: solid 4px #0D346C; */ } 
.menuu2 li.selected a{ color: #0D346C; } 

.menu22{ /* display:flex; */ width: 100%; height:248px; background: #fff; font-size:13px; justify-content: flex-end; padding: 28px 0; box-shadow: 0px 4px 12px rgb(2 26 79 / 8%);
          display:none; } 
.menu22 ul { /* display:inline-block; width:160px; */ display:flex; flex-direction:column;  }
.menu22 ul:nth-child(5){ margin-right:57px; }
.menu22 ul li{ float:left; text-align:left; position:relative; width: 224px; height:32px; margin-bottom: 8px; }
.menu22 ul li a{ display:inline-block; color: #999999; letter-spacing:0; text-decoration:none;  position:relative; 
                line-height:25px; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; height:32px; width:224px; }
.menu22 ul li a:hover{ color: #0d346c; font-family: 'SUIT-Regular'; font-size:18px; } 


/* 문의하기 selectBox */
.InquirySelectBoxM * { box-sizing: border-box; }
.InquirySelectBoxM  { position: relative; width:440px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                     background:url('../../CompanyNews/image/다각형 2.png')no-repeat; background-position-y: 17.37px;
                     background-position-x: 400px; background-size:10px; border-radius:4px; padding: 12px 20px 13px; }
.InquirySelectBoxM:focus{ border: 1px solid #4D8DE8; }
.InquirySelectBoxM:checked{ border: 1px solid #4D8DE8; }

.InquirySelectBoxM .label4M { display: flex; align-items: center; border: 0 none; outline: 0 none; line-height:22.5px;
                              background: transparent; cursor: pointer; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; color:#C0C0C0; }
.InquirySelectBoxM .InquiryOptionListM { position: absolute; top: 50px; left: -1px; width: 240px; height:280px; list-style-type: none; 
                                         padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); 
                                         font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar { width: 4px;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar { width: 4px;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-track { background: transparent; }
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-thumb { background: #f0f0f0;  }
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-thumb:hover { background: #f0f0f0;}

.InquirySelectBoxM.active .InquiryOptionListM { max-height: 500px; background:#fff; }
.InquirySelectBoxM  .InquiryOptionItemM { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.InquirySelectBoxM  .InquiryOptionItemM:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.InquirySelectBoxM  .InquiryOptionItemM:last-child { border-bottom: 0 none;}



/* 문의하기 selectBox */
.InquiryHomeSelect * { box-sizing: border-box; }
.InquiryHomeSelect  { position: relative; width:440px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                     background:url(../../components/image/selectBox_Arr.png)no-repeat; background-position-y: 17.37px;
                     background-position-x: 400px; border-radius:4px; padding: 12px 20px 13px;
                     font-family:'SUIT-Regular'; font-size:18px; color: #333333; }
.InquiryHomeSelect:focus{ border: 1px solid #4D8DE8; }
.InquiryHomeSelect:checked{ border: 1px solid #4D8DE8; }


.InquiryHomeSelect .InquiryHomeOptionItem { position: absolute; top: 50px; left: -1px; width: 240px; height:280px; list-style-type: none; 
                                         padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); 
                                         font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar { width: 4px;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar { width: 4px;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-track { background: transparent; }
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-thumb { background: #f0f0f0;  }
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-thumb:hover { background: #f0f0f0;}

.InquiryHomeSelect.active .InquiryHomeOptionItem { max-height: 500px; background:#fff; }
.InquiryHomeSelect .InquiryHomeOptionItem { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.InquiryHomeSelect .InquiryHomeOptionItem:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.InquiryHomeSelect .InquiryHomeOptionItem:last-child { border-bottom: 0 none;}


select::-ms-expand { display: none; }
.select {  -o-appearance: none;  -webkit-appearance: none;  -moz-appearance: none; appearance: none;}
input { -webkit-appearance: none; -moz-appearance: none; appearance: none; }
input:focus { outline: none; }
select:focus { outline: none; }
textarea:focus { outline: none; }

/* 문의하기 input */
input[type="checkbox"]{ display: none; }
input[type="checkbox"] + label{ display: inline-block; width: 28px; height: 28px; border:1px solid #DDDDDD; position: relative; border-radius:4px; }
input[id="check1"]:checked + label::after{ /* content:'✔'; */ content:url(../../components/image/personalCheck.png); width: 28px; height: 28px; text-align: center; 
                                           position: absolute; left: 0; top:0; border-radius:4px; border:1px solid #4D8DE8; background-color:#F4F8FD; padding-top:2px; }

/* 문의하기 popup */
.layer-popupM { display: none; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 100;  width: 742px; height: 340px;
	           background: #dddddd; box-shadow: 0px 0px 8px #00000014; border-radius: 8px;}
.layer-popupM.show { display: block; }
.inputTextM { color:#666666; font-family: 'SUIT-Regular'; font-size: 16px; font-weight:400; display: inline-block;  height: 20px; line-height: 20px; margin-right:40px; }
.inputEffectM { animation: inputEffect-effect 1s linear infinite; color: #DD3C25;}
#frame_divM { width: 100px; height: 100px; position: relative;}

/* 주요 실적 selectBox */
.majorSelectBox * { box-sizing: border-box; }
.majorSelectBox  { position: relative; width:164px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                   background:url(../../components/image/selectBox_Arr.png)no-repeat;  background-position-x: 124px; background-position-y: 17.37px;
                   border-radius:4px; padding: 12px 20px 13px; float:right; }
.majorSelectBox .label5M { display: flex; align-items: center; border: 0 none; outline: 0 none; line-height:22.5px;
                              background: transparent; cursor: pointer; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; color:#333333; }
.majorSelectBox .majorOptionList { position: absolute; top: 50px; left: -1px; width: 164px; height:168px; list-style-type: none; 
                                   padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08);
                                   font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}

.majorSelectBox.active .majorOptionList { max-height: 500px; background:#fff; }
.majorSelectBox .majorOptionItem { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.majorSelectBox .majorOptionItem:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.majorSelectBox .majorOptionItem:last-child { border-bottom: 0 none;}


/* modalBox test */
.modal { background: rgba(0, 0, 0, 0.1); }
.modal-container { position: absolute; top: 50%; left: 50%; width: 880px; height: 880px; padding: 36px 60px; text-align: center; background-color: #ffffff;
                   border-radius: 8px; transform: translateX(-50%) translateY(-50%); }


.custom-select { position: relative; width: 440px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; background: url(../../components/image/selectBox_Arr.png)no-repeat;
                 background-position-y: 17.37px; background-position-x: 400px; background-size: 10px; border-radius: 4px; padding: 12px 20px 13px; }
.custom-select select { display: none; }
.select-selected:after { position: absolute; content: ""; top: 14px; right: 10px; width: 0; height: 0; border: 6px solid transparent; border-color: #fff transparent transparent transparent; }
.select-selected.select-arrow-active:after { border-color: transparent transparent #fff transparent; top: 7px; }
.select-items div { padding: 8px 16px; height:56px; cursor: pointer; user-select: none; }
.select-selected { font-family: SUIT-Regular; font-size: 18px; }
.select-items { position: absolute; top: 100%; left: 0; right: 0; z-index: 99; width: 240px; height: 280px; list-style-type: none; padding: 0;
                z-index: 2; border-radius: 8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); font-family: 'SUIT-Light';
                font-weight: 400; font-size: 18px; display: block; color: #999999; overflow-y:scroll; }
.select-hide { display: none; }
.select-items div:hover, .same-as-selected { background-color: rgba(0, 0, 0, 0.1); }



.slideshow-container { max-width: 1440px; position: relative; margin: auto; margin-top: 120px; }
.fade { -webkit-animation-name: fade; -webkit-animation-duration: 1.5s; animation-name: fade; animation-duration: 1.5s; }

@-webkit-keyframes fade { from {opacity: .4}  to {opacity: 1}}
@keyframes fade { from {opacity: .4}  to {opacity: 1} }

.prev, .next { cursor: pointer; position: absolute; top: 50%; width: auto; padding: 16px; margin-top: -22px;
               color: white; font-weight: bold; font-size: 18px; transition: 0.6s ease; border-radius: 0 3px 3px 0; }
.next { right: 0; border-radius: 3px 0 0 3px; }
.prev:hover, .next:hover { background-color: rgba(0,0,0,0.8); }

.rollingbanner { position: absolute; left: 130px; top: 610px; width: 1369px; height: 80px; font-size: .875rem; letter-spacing: -1px;
                 padding: 7px 15px;  box-sizing: border-box; z-index: 1; text-align: left; }
.rollingbanner > .title{ font-weight: bold; float: left; padding-right: 10px; }
.rollingbanner > .wrap{ position: relative; width: auto; height: 100%; box-sizing: border-box; overflow: hidden; }       
.rolling ul{ list-style: none; }
.rollingbanner li{ position: absolute; top: -72px; left: 0; }
.rollingbanner li.prev{ top: 72px; transition: top 0.1s ease; display:none; }
.rollingbanner li.current{ top: 0; transition: top 0.5s ease; }
.rollingbanner li.next{ top: -72px; }
.rollingbanner a{ display: block; display: -webkit-box; text-decoration: none; -webkit-line-clamp: 1; -webkit-box-orient:vertical; 
                  overflow: hidden; color: #ffffff; font-family: 'SUIT-Medium'; font-size: 22px; line-height: 34px; }

}

@media all and (min-width:1024px) {

.home{ border:dashed 1px blue; }

/* 메뉴바 하위항목 */
.menu2 #tab1{ background:none; }
.menu2 #tab1.active{ display:block; } 
.menu2 #tab2{ background:none; }
.menu2 #tab2.active{  display:block; } 
.menu2 #tab3{ background:none; }
.menu2 #tab3.active{ display:block; } 
.menu2 #tab4{ background:none; }
.menu2 #tab4.active{ display:block; } 
.menu2 #tab5{ background:none; }
.menu2 #tab5.active{ display:block; }


/* 슬라이드 테스트 */
.slider_container { position: relative; height: 100%; overflow: hidden; display:flex;}
.slider { transition: 0.9s; width: 100vw; height:100vw; }
.slider > div{ display:block; width: 100vw; height:100%; text-align:center; }
.indicator { position: absolute; display: inline-flex; cursor: pointer; left: 48%; bottom: 6%;}
.dots { background-color: #fff; height: 12px; width: 12px; border-radius: 50%; transition: 0.8s; margin-left: 24px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
                    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
                    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;}
.dots:hover { background-color: #d7d7d7; }


/* 0705 tab버튼 active */
.menuu2 { /* float: right; */  margin-left:220px; height:100px;  }
.menuu2 li{ float:left; width:124px; height: 100px; text-align:left; padding:36px 0; align-items: center; margin-right:100px; }
.menuu2 li:nth-child(5){ margin: 0; } 
.menuu2 li:hover{ border-bottom:solid 4px #0D346C; }
.menuu2 li a{ width:70px; height:60px; color: #333333; text-decoration:none; font-weight:400; font-family: 'SUIT-Regular'; font-size:20px;
              line-height:30px; text-align: center; } 
.menuu2 li .on a{ font-weight: bold; color: yellow; }
.menuu2 li.selected{ display:block; } 
.menuu2 li.selected a{ color: #0D346C; } 

.menu22{ /* display:flex; */ width: 100%; height:248px; background: #fff; font-size:13px; justify-content: flex-end; padding: 28px 0; box-shadow: 0px 4px 12px rgb(2 26 79 / 8%);  } 
.menu22 ul { display:flex; flex-direction:column;  }
.menu22 ul:nth-child(5){ margin-right:57px; }
.menu22 ul li{ float:left; text-align:left; position:relative; width: 224px; height:32px; margin-bottom: 8px; }
.menu22 ul li a{ display:inline-block; color: #999999; letter-spacing:0; text-decoration:none;  position:relative; 
                line-height:25px; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; height:32px; width:224px; }
.menu22 ul li a:hover{ color: #0d346c; font-family: 'SUIT-Regular'; font-size:18px; } 

/* 1103 test */
.menu22:active{ display:block; }


/* 문의하기 selectBox */
.InquirySelectBoxM * { box-sizing: border-box; }
.InquirySelectBoxM  { position: relative; width:440px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                     background:url('../../CompanyNews/image/다각형 2.png')no-repeat; background-position-y: 17.37px;
                     background-position-x: 400px; background-size:10px; border-radius:4px; padding: 12px 20px 13px; }
.InquirySelectBoxM:focus{ border: 1px solid #4D8DE8; }
.InquirySelectBoxM:checked{ border: 1px solid #4D8DE8; }

.InquirySelectBoxM .label4M { display: flex; align-items: center; border: 0 none; outline: 0 none; line-height:22.5px;
                              background: transparent; cursor: pointer; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; color:#C0C0C0; }
.InquirySelectBoxM .InquiryOptionListM { position: absolute; top: 50px; left: -1px; width: 240px; height:280px; list-style-type: none; 
                                         padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); 
                                         font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar { width: 4px;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar { width: 4px;}
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-track { background: transparent; }
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-thumb { background: #f0f0f0;  }
.InquirySelectBoxM .InquiryOptionListM::-webkit-scrollbar-thumb:hover { background: #f0f0f0;}

.InquirySelectBoxM.active .InquiryOptionListM { max-height: 500px; background:#fff; }
.InquirySelectBoxM  .InquiryOptionItemM { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.InquirySelectBoxM  .InquiryOptionItemM:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.InquirySelectBoxM  .InquiryOptionItemM:last-child { border-bottom: 0 none;}


/* 문의하기 selectBox */
.InquiryHomeSelect * { box-sizing: border-box; }
.InquiryHomeSelect  { position: relative; width:340px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                     background:url(../../components/image/selectBox_Arr.png)no-repeat; background-position-y: 17.37px;
                     background-position-x: 290px; border-radius:4px; padding: 12px 20px 13px;
                     font-family:'SUIT-Regular'; font-size:18px; color: #333333; }
.InquiryHomeSelect:focus{ border: 1px solid #4D8DE8; }
.InquiryHomeSelect:checked{ border: 1px solid #4D8DE8; }


.InquiryHomeSelect .InquiryHomeOptionItem { position: absolute; top: 50px; left: -1px; width: 240px; height:280px; list-style-type: none; 
                                         padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); 
                                         font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar { width: 4px;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar { width: 4px;}
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-track { background: transparent; }
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-thumb { background: #f0f0f0;  }
.InquiryHomeSelect .InquiryHomeOptionItem::-webkit-scrollbar-thumb:hover { background: #f0f0f0;}

.InquiryHomeSelect.active .InquiryHomeOptionItem { max-height: 500px; background:#fff; }
.InquiryHomeSelect .InquiryHomeOptionItem { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.InquiryHomeSelect .InquiryHomeOptionItem:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.InquiryHomeSelect .InquiryHomeOptionItem:last-child { border-bottom: 0 none;}


select::-ms-expand { display: none; }
.select {  -o-appearance: none;  -webkit-appearance: none;  -moz-appearance: none; appearance: none;}
input { -webkit-appearance: none; -moz-appearance: none; appearance: none; }
input:focus { outline: none; }
select:focus { outline: none; }
textarea:focus { outline: none; }

/* 문의하기 input */
input[type="checkbox"]{ display: none; }
input[type="checkbox"] + label{ display: inline-block; width: 28px; height: 28px; border:1px solid #DDDDDD; position: relative; border-radius:4px; }
input[id="check1"]:checked + label::after{ /* content:'✔'; */ content:url(../../components/image/personalCheck.png); width: 28px; height: 28px; text-align: center; 
                                           position: absolute; left: 0; top:0; border-radius:4px; border:1px solid #4D8DE8; background-color:#F4F8FD; padding-top:2px; }

/* 문의하기 popup */
.layer-popupM { display: none; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 100;  width: 742px; height: 340px;
	           background: #dddddd; box-shadow: 0px 0px 8px #00000014; border-radius: 8px;}
.layer-popupM.show { display: block; }
.inputTextM { color:#666666; font-family: 'SUIT-Regular'; font-size: 16px; font-weight:400; display: inline-block;  height: 20px; line-height: 20px; margin-right:40px; }
.inputEffectM { animation: inputEffect-effect 1s linear infinite; color: #DD3C25;}
#frame_divM { width: 100px; height: 100px; position: relative;}

/* 주요 실적 selectBox */
.majorSelectBox * { box-sizing: border-box; }
.majorSelectBox  { position: relative; width:164px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; 
                   background:url(../../components/image/selectBox_Arr.png)no-repeat;  background-position-x: 124px; background-position-y: 17.37px;
                   border-radius:4px; padding: 12px 20px 13px; float:right; }
.majorSelectBox .label5M { display: flex; align-items: center; border: 0 none; outline: 0 none; line-height:22.5px;
                              background: transparent; cursor: pointer; font-family: 'SUIT-Regular'; font-weight:400; font-size:18px; color:#333333; }
.majorSelectBox .majorOptionList { position: absolute; top: 50px; left: -1px; width: 164px; height:168px; list-style-type: none; 
                                   padding: 0; overflow: hidden; max-height: 0;  z-index:2; border-radius:8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08);
                                   font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #999999;}

.majorSelectBox.active .majorOptionList { max-height: 500px; background:#fff; }
.majorSelectBox .majorOptionItem { padding: 16.5px 32px; transition: .1s; text-align: left; font-family: 'SUIT-Light';  font-size:18px; line-height:22.5px;  }
.majorSelectBox .majorOptionItem:hover { background: #F4F8FD; font-family: 'SUIT-Light'; font-weight:400; font-size:18px; color: #4D8DE8; }
.majorSelectBox .majorOptionItem:last-child { border-bottom: 0 none;}


/* modalBox test */
.modal { background: rgba(0, 0, 0, 0.1); }
.modal-container { position: absolute; top: 50%; left: 50%; width: 880px; height: 880px; padding: 36px 60px; text-align: center; background-color: #ffffff;
                   border-radius: 8px; transform: translateX(-50%) translateY(-50%); }


.custom-select { position: relative; width: 440px; height: 48px; border: 1px solid #DDDDDD; cursor: pointer; background: url(../../components/image/selectBox_Arr.png)no-repeat;
                 background-position-y: 17.37px; background-position-x: 400px; background-size: 10px; border-radius: 4px; padding: 12px 20px 13px; }
.custom-select select { display: none; }
.select-selected:after { position: absolute; content: ""; top: 14px; right: 10px; width: 0; height: 0; border: 6px solid transparent; border-color: #fff transparent transparent transparent; }
.select-selected.select-arrow-active:after { border-color: transparent transparent #fff transparent; top: 7px; }
.select-items div { padding: 8px 16px; height:56px; cursor: pointer; user-select: none; }
.select-selected { font-family: SUIT-Regular; font-size: 18px; }
.select-items { position: absolute; top: 100%; left: 0; right: 0; z-index: 99; width: 240px; height: 280px; list-style-type: none; padding: 0;
                z-index: 2; border-radius: 8px; box-shadow: 0px 4px 12px rgba(2, 26, 79, 0.08); font-family: 'SUIT-Light';
                font-weight: 400; font-size: 18px; display: block; color: #999999; overflow-y:scroll; }
.select-hide { display: none; }
.select-items div:hover, .same-as-selected { background-color: rgba(0, 0, 0, 0.1); }



.slideshow-container { max-width: 1440px; position: relative; margin: auto; margin-top: 120px; }
.fade { -webkit-animation-name: fade; -webkit-animation-duration: 1.5s; animation-name: fade; animation-duration: 1.5s; }

@-webkit-keyframes fade { from {opacity: .4}  to {opacity: 1}}
@keyframes fade { from {opacity: .4}  to {opacity: 1} }

.prev, .next { cursor: pointer; position: absolute; top: 50%; width: auto; padding: 16px; margin-top: -22px;
               color: white; font-weight: bold; font-size: 18px; transition: 0.6s ease; border-radius: 0 3px 3px 0; }
.next { right: 0; border-radius: 3px 0 0 3px; }
.prev:hover, .next:hover { background-color: rgba(0,0,0,0.8); }

.rollingbanner { position: absolute; left: 130px; /* top: 610px; */ top: 540px;  width: 1369px; height: 80px; font-size: .875rem; letter-spacing: -1px;
                 padding: 7px 15px;  box-sizing: border-box; z-index: 1; text-align: left; }
.rollingbanner > .title{ font-weight: bold; float: left; padding-right: 10px; }
.rollingbanner > .wrap{ position: relative; width: auto; height: 100%; box-sizing: border-box; overflow: hidden; }       
.rolling ul{ list-style: none; }
.rollingbanner li{ position: absolute; top: -72px; left: 0; }
.rollingbanner li.prev{ top: 72px; transition: top 0.1s ease; display:none; }
.rollingbanner li.current{ top: 0; transition: top 0.5s ease; }
.rollingbanner li.next{ top: -72px; }
.rollingbanner a{ display: block; display: -webkit-box; text-decoration: none; -webkit-line-clamp: 1; -webkit-box-orient:vertical; 
                  overflow: hidden; color: #ffffff; font-family: 'SUIT-Medium'; font-size: 22px; line-height: 34px; }

}


