
/* mobile */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
@media all and (max-width:767px) {
.contentBox{ display:block; /* margin-top:100px; */ } 

/* .productIntroTitle{ display:block; width:100%; height:100vh; text-align:center; background:url(../../ProductIntro/image/protecto_back.png)no-repeat; 
                background-size: 120% 60%; }
.productIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 18px; font-weight: 600; padding-top:150px; padding-bottom: 6px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#000000; }
.productIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:10px; color:#000000; 
                                    height: 28px; line-height: 28px; } */

.productIntroBox{ display: block; width: 100vw; height: 100vh; margin-top: 70px; text-align: center;
                   font-size: 20px; color: #333333; }
.productIntroBox > p { font-size: 14px; font-style: normal; font-weight: 400; color: #333; line-height: 172%; letter-spacing: -2px; }
.loadingImage{ display: block; height: 210px; background: url(../../ProductIntro/image/pageLoading_image.svg)no-repeat;
               background-position: center; margin-bottom: 25px; }
.comingText{ display: block; font-size: 24px; font-weight:800; color: rgba(80,142,229,0.2); letter-spacing: -1.02px; margin-bottom: 12px;}
.pageText{ display: inline-flex; font-size: 24px; font-weight:800; color: #000000; letter-spacing: -3.5px; margin: 14px 0 28px 0;
            width: 220px; height: 22px; background: rgba(80, 142, 229, 0.10); line-height: -0px; padding-left: 10px; }
.pageText > p{ font-size: 24px; font-weight:600; color: #508EE5; margin-left: 6px; }

.productFile{ display: inline-block; height:36px; border: 1px solid #666; border-radius: 48px; font-family: 'SUIT-SemiBold'; font-size:14px; font-weight: 400;
              letter-spacing: -0.54px; padding: 3px 16px; color: #666666; text-decoration: none; background:#fff; /* line-height: 169%; */ margin-top: 36px; }
.productFile:hover{display: inline-block; height:36px; border: 1px solid #666; border-radius: 48px; font-family: 'SUIT-SemiBold'; font-size:18px; padding: 3px 16px;
              color: #666666; text-decoration: none; background:#fff; /* line-height: 169%; */ margin-top: 52px; }
.productFileImg{ display:inline-block; width: 20px; height:20px; background:url(../../components/image/download_icon.png) no-repeat; 
                 background-position-y: 6px; background-size: 70%; margin-left: 10px; }
.productFileImg:hover{ display:inline-block; width: 20px; height:20px; background:url(../../components/image/download_icon.png) no-repeat; 
                        background-position-y: 6px; background-size: 70%; margin-left: 10px; }

}


/* 가로 mobile */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/

@media all and (min-width: 640px) and (max-width: 959px) {

.contentBox{ display:block; padding-top:80px; } 

/* .productIntroTitle{ display:block; width:100%; height:100vh; text-align:center; background:url(../../ProductIntro/image/protecto_back.png)no-repeat; background-size:120% 60%; }
.productIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 20px; font-weight: 600; padding-top:50px; padding-bottom: 16px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#000000; }
.productIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:12px; color:#000000;
                                    height: 28px; line-height: 28px; } */

.productIntroBox{ display: block; width: 100vw; height: 100vh; margin-top: 50px; text-align: center;
                   font-size: 20px; color: #333333; }
.productIntroBox > p { font-size: 16px; font-style: normal; font-weight: 400; color: #333; line-height: 172%; letter-spacing: -2px; }
.loadingImage{ display: block; height: 210px; background: url(../../ProductIntro/image/pageLoading_image.svg)no-repeat;
               background-position: center; margin-bottom: 25px; }
.comingText{ display: block; font-size: 24px; font-weight:800; color: rgba(80,142,229,0.2); letter-spacing: -1.02px; margin-bottom: 12px;}
.pageText{ display: inline-flex; font-size: 24px; font-weight:800; color: #000000; letter-spacing: -3.5px; margin: 14px 0 28px 0;
            width: 220px; height: 22px; background: rgba(80, 142, 229, 0.10); line-height: -0px; padding-left: 10px; }
.pageText > p{ font-size: 24px; font-weight:600; color: #508EE5; margin-left: 6px; }

.productFile{ display: inline-block; height:36px; border: 1px solid #666; border-radius: 48px; font-family: 'SUIT-SemiBold'; font-size:14px; font-weight: 400;
              letter-spacing: -0.54px; padding: 3px 16px; color: #666666; text-decoration: none; background:#fff; line-height: 169%; margin-top: 40px; }
.productFile:hover{display: inline-block; height:36px; border: 1px solid #666; border-radius: 48px; font-family: 'SUIT-SemiBold'; font-size:14px; padding: 3px 16px;
                    color: #666666; text-decoration: none; background:#fff; line-height: 169%; margin-top: 52px; }
.productFileImg{ display:inline-block; width: 20px; height:20px; background:url(../../components/image/download_icon.png) no-repeat; 
                 background-position-y: 6px; background-size: 70%; margin-left: 10px; }
.productFileImg:hover{ display:inline-block; width: 20px; height:20px; background:url(../../components/image/download_icon.png) no-repeat; 
                        background-position-y: 6px; background-size: 70%; margin-left: 10px; }

}

/* tablet */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/

@media all and (min-width:768px) and (max-width:1023px) {
.contentBox{ display:block;  /* margin-top:100px; */  padding-top: 80px; } 

/* .productIntroTitle{ display:block; width:100%; height:100vh; text-align:center; background:url(../../ProductIntro/image/protecto_back.png)no-repeat;
                background-size:120% 80%; }
.productIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 36px; font-weight: 600; padding-top:200px; padding-bottom: 16px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#000000; }
.productIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:14px; color:#000000; 
                                    height: 28px; line-height: 28px; } */

.productIntroBox{ display: block; width: 100vw; height: 100vh; margin-top: 200px; text-align: center;
                   font-size: 20px; color: #333333; }
.productIntroBox > p { font-size: 20px; font-style: normal; font-weight: 400; color: #333; line-height: 172%; letter-spacing: -2px; }
.loadingImage{ display: block; height: 210px; background: url(../../ProductIntro/image/pageLoading_image.svg)no-repeat;
               background-position: center; margin-bottom: 25px; }
.comingText{ display: block; font-size: 34px; font-weight:800; color: rgba(80,142,229,0.2); letter-spacing: -1.02px; margin-bottom: 20px;}
.pageText{ display: inline-flex; font-size: 34px; font-weight:800; color: #000000; letter-spacing: -3.5px; margin: 14px 0 28px 0;
             width: 310px; height: 22px; background: rgba(80, 142, 229, 0.10); line-height: -0px; padding-left: 10px; }
.pageText > p{ font-size: 34px; font-weight:600; color: #508EE5; margin-left: 6px; }

.productFile{ display: inline-block; height:48px; border: 1px solid #666; border-radius: 48px; font-family: 'SUIT-SemiBold'; font-size:18px; font-weight: 400;
              letter-spacing: -0.54px; padding: 10px 40px; color: #666666; text-decoration: none; background:#fff; line-height: 169%; margin-top: 52px; }
.productFile:hover{display: inline-block; height:48px; border: 1px solid #666; border-radius: 48px; font-family: 'SUIT-SemiBold'; font-size:18px; padding: 10px 40px;
              color: #666666; text-decoration: none; background:#fff; line-height: 169%; margin-top: 52px; }
.productFileImg{ display:inline-block; width: 20px; height:20px; background:url(../../components/image/download_icon.png) no-repeat; 
                 background-position-y: 3px; background-size: 80%; margin-left: 13px; }
.productFileImg:hover{ display:inline-block; width: 20px; height:20px; background:url(../../components/image/download_icon.png) no-repeat; 
                        background-position-y: 3px; background-size: 80%; margin-left: 13px; }

}


/* 가로 tablet */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/

@media all and (min-width: 960px) and (max-width: 1280px) {

.contentBox{ display:block; margin-top:100px; } 

/* .productIntroTitle{ display:block; width:100%; height:100vh; text-align:center; background:url(../../ProductIntro/image/protecto_back.png)no-repeat; background-size:cover; }
.productIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 40px; font-weight: 600; padding-top:85.5px; padding-bottom: 16px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#000000;}
.productIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:18px; color:#000000;
                                    height: 28px; line-height: 28px; } */

.productIntroBox{ display: block; width: 100vw; height: 100vh; margin-top: 256px; text-align: center;
                   font-size: 20px; color: #333333; }
.productIntroBox > p { font-size: 20px; font-style: normal; font-weight: 400; color: #333; line-height: 172%; letter-spacing: -2px; }
.loadingImage{ display: block; height: 210px; background: url(../../ProductIntro/image/pageLoading_image.svg)no-repeat;
               background-position: center; margin-bottom: 25px; }
.comingText{ display: block; font-size: 34px; font-weight:800; color: rgba(80,142,229,0.2); letter-spacing: -1.02px; margin-bottom: 20px;}
.pageText{ display: inline-flex; font-size: 34px; font-weight:800; color: #000000; letter-spacing: -3.5px; margin: 14px 0 28px 0;
            width: 310px; height: 22px; background: rgba(80, 142, 229, 0.10); line-height: -0px; padding-left: 10px; }
.pageText > p{ font-size: 34px; font-weight:600; color: #508EE5; margin-left: 6px; }

.productFile{ display: inline-block; height:48px; border: 1px solid #666; border-radius: 48px; font-family: 'SUIT-SemiBold'; font-size:18px; font-weight: 400;
              letter-spacing: -0.54px; padding: 10px 40px; color: #666666; text-decoration: none; background:#fff; line-height: 169%; margin-top: 52px; }
.productFile:hover{display: inline-block; height:48px; border: 1px solid #666; border-radius: 48px; font-family: 'SUIT-SemiBold'; font-size:18px; padding: 10px 40px;
              color: #666666; text-decoration: none; background:#fff; line-height: 169%; margin-top: 52px; }
.productFileImg{ display:inline-block; width: 20px; height:20px; background:url(../../components/image/download_icon.png) no-repeat; 
                 background-position-y: 3px; background-size: 80%; margin-left: 13px; }
.productFileImg:hover{ display:inline-block; width: 20px; height:20px; background:url(../../components/image/download_icon.png) no-repeat; 
                        background-position-y: 3px; background-size: 80%; margin-left: 13px; }

}



/* FHD */
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/
/******************************************************************************************************************************************************************************/


@media all and (min-width:1024px) {
.contentBox{ display:block; margin-top:100px; } 

/* .productIntroTitle{ display:block; width:100%; height:100vh; text-align:center; background:url(../../ProductIntro/image/protecto_back.png)no-repeat; background-size:cover; }
.productIntroTitle > span:nth-child(1){ display:block; width:100%; font-size: 48px; font-weight: 600; padding-top:340px; padding-bottom: 16px; font-family: 'SUIT-SemiBold';
                                    font-style: normal; color:#000000; }
.productIntroTitle > span:nth-child(2){ display:block; width:100%; font-weight: 400; font-family: 'SUIT-Regular'; font-size:22px; color: #000000; 
                                    height: 28px; line-height: 28px; }  */

.productIntroBox{ display: block; width: 100vw; height: 100vh; margin-top: 256px; text-align: center;
                   font-size: 20px; color: #333333; }
.productIntroBox > p { font-size: 20px; font-style: normal; font-weight: 400; color: #333; line-height: 172%; letter-spacing: -2px; }
.loadingImage{ display: block; height: 210px; background: url(../../ProductIntro/image/pageLoading_image.svg)no-repeat;
               background-position: center; margin-bottom: 25px; }
.comingText{ display: block; font-size: 34px; font-weight:800; color: rgba(80,142,229,0.2); letter-spacing: -1.02px; margin-bottom: 20px;}
.pageText{ display: inline-flex; font-size: 34px; font-weight:800; color: #000000; letter-spacing: -3.5px; margin: 14px 0 28px 0;
           width: 310px; height: 22px; background: rgba(80, 142, 229, 0.10); line-height: -0px; padding-left: 10px; }
.pageText > p{ font-size: 34px; font-weight:600; color: #508EE5; margin-left: 6px; }

.productFile{ display: inline-block; height:48px; border: 1px solid #666; border-radius: 48px; font-family: 'SUIT-SemiBold'; font-size:18px; font-weight: 400;
              letter-spacing: -0.54px; padding: 8px 40px; color: #666666; text-decoration: none; background:#fff; line-height: 169%; margin-top: 52px; }
.productFile:hover{display: inline-block; height:48px; border: 1px solid #666; border-radius: 48px; font-family: 'SUIT-SemiBold'; font-size:18px; padding: 8px 40px;
              color: #666666; text-decoration: none; background:#fff; line-height: 169%; margin-top: 52px; }
.productFileImg{ display:inline-block; width: 20px; height:20px; background:url(../../components/image/download_icon.png) no-repeat; 
                 background-position-y: 3px; background-size: 80%; margin-left: 13px; }
.productFileImg:hover{ display:inline-block; width: 20px; height:20px; background:url(../../components/image/download_icon.png) no-repeat; 
                        background-position-y: 3px; background-size: 80%; margin-left: 13px; }
}


